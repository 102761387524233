.modalHeader {
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
  padding-bottom: 20px;
}

.modalHeader p {
  margin: 0;
  color: #ffffff
}

.modalHeader p:first-child {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}

/*.modalHeader p:last-child {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
}*/

.chartContainer {
  height: auto;
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
  padding: 10px 0 0 0;
}

.chartContainer span {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.166667px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-left: 1rem;
  padding-bottom: 1rem;
}

.chartContainer h1 {
  color: #ffffff;
  text-align: center;
  padding-top: 10rem;
  font-size: 14px;
}

.modalFooter {
  padding: 10px 0 0 0;
}

.modalFooter span {
  color: #FFFFFF;
  font-weight: 300;
  font-size: 12px;
}