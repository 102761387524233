.datahealthContainer {
  margin-top: -60px;
  min-height: 100vh;
  position: relative;
}

.datahealthRoot {
  /* min-width: 100vw; */
  padding-top: 60px;
  box-sizing: border-box;
  min-height: 100vh;
}

.datahealthPanel {
  display: flex;
  width: 100%;
}

.datahealthPanelContainer {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.55) 0%, #000000 100%);
  margin: 70px 10px 10px 210px;
  max-width: 100vw;
}

.datahealthPanelContainer h2 {
  opacity: 0.8;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.34px;
  line-height: 22px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: 0;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
  width: 100%;
  box-shadow: inset 0 -1px 0 0 rgb(255 255 255 / 10%);
  padding-bottom: 8px;
}
