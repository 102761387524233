.cardThirdValueList {
  margin-top: 10.5px;
  display: flex;
  gap: 20px;
  width: 100vw;
  overflow: scroll;
}

.waterfallChartOverview {
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
}

.waterFallChartContainer {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  /* border: 2px solid #29292990; */
  border-radius: 8px;
  padding: 15.5px 0;
  /* padding: 15.5px 9.5px 21.5px 21.5px; */
  background: linear-gradient( 180deg, rgba(6, 25, 25, 0.209) 0%, rgba(0, 0, 0, 0.55) 100%);
  border: 2px solid rgba(70, 70, 70, 0.4);
}

.topWaterfallChartDiv {
  display: flex;
  justify-content: space-between;
}

.topLeftWaterfallDiv {
  display: flex;
}

.topLeftWaterfallDiv div {
  margin-top: 5px;
  margin-left: 22.5px;
  height: 26px;
  width: 115px;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}

.topLeftWaterfallDiv span {
  height: 15px;
  width: 100px;
  color: #00a19c;
  font-family: "Museo Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 15px;
  margin-left: 7px;
  margin-top: 10px;
  cursor: pointer;
}

.unit{
  padding-left: 10px;
}

.topRightWaterfallDiv {
  display: flex;
  margin-top: 9px;
  margin-left: 20px;
}

.topRightWaterfallDiv span {
  margin-top: 10.5px;
  margin-right: 10.5px;
  height: 14px;
  width: auto;
  opacity: 0.8;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: -0.17px;
  line-height: 14px;
}

.boxlegendRed {
  margin-top: 10.5px;
  margin-right: 8px;
  height: 13px;
  width: 13px;
  opacity: 0.7;
  border-radius: 2px;
  background-color: #E75552;
}

.boxlegendGreen {
  margin-top: 10.5px;
  margin-right: 8px;
  height: 13px;
  width: 13px;
  border-radius: 2px;
  background-color: #00A19C;
}

.boxlegendBlue {
  margin-top: 10.5px;
  margin-right: 8px;
  height: 13px;
  width: 13px;
  border-radius: 2px;
  background-color: #009DDC;
}

.subTopWaterfall {
  margin-top: 10px;
  margin-left: 22.5px;
  width: 307px;
  color: #B8B8B8;
  font-family: "Museo Sans", sans-serif;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

/* Breakdown card lv3 */

.miniCardActiveModal {
  box-sizing: border-box;
  height: 100%;
  /* min-width: 140px; */
  width: fit-content;
  border-radius: 4px;
  background: linear-gradient( 180deg, rgba(0, 207, 203, 0.2) 0%, rgba(0, 207, 203, 0.2) 50%, rgba(0, 207, 203, 0.3) 100%);
  border: 1.5px solid rgba(255, 255, 255, 1);
  padding: 16px 14px 14px 16px;
}

.primaryCardBackground {
  /* min-width : 204px; */
  /* max-width: fit-content; */
  margin-top: 10px;
  width: 100%;
  display: flex;
  gap: 14px;
  border-radius: 8px;
  border: 1px solid rgba(70, 70, 70, 0.5);
  justify-content: space-evenly;
  /* padding: 19.5px 24px; */
  padding: 19.5px 19.5px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%)
}

.mainLayoutModal {
  display: flex;
  gap: 10px;
  width: 100%;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 10px;
}

.mainLayoutModalProduc {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.listButtonBreakdownModal {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  height: 100%;
  padding: 10px 20px;
  width: 100%;
  border: 1px solid rgba(70, 70, 70, 0.5);
  opacity: 0.8;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
  overflow: scroll;
}

.secondaryValueList {
  box-sizing: border-box;
  height: 111px;
  width: -webkit-fill-available;
  border: 2px solid rgba(70, 70, 70, 0.4);
  border-radius: 8px;
  display: flex;
  gap: 20px;
  padding: 19px 18px 9px 26.5px;
  background: linear-gradient( 180deg, rgba(6, 25, 25, 0.209) 0%, rgba(0, 0, 0, 0.38) 100%);
  justify-content: space-around;
  align-items: center;
}

.summaryChartBackground {
  box-sizing: border-box;
  max-height: 111px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid rgba(70, 70, 70, 0.4);
  background: linear-gradient( 180deg, rgba(6, 25, 25, 0.209) 0%, rgba(0, 0, 0, 0.38) 100%);
  padding: 15.5px 9.5px 21.5px 21.5px;
  overflow: auto;
  margin-bottom: 10px;
}

.waterfallChartOverview ::-webkit-scrollbar {
  width: 2px;
  display: none !important;
}

.sumaryChartBackgroundModalWithPlant {
  box-sizing: border-box;
  min-width: 204px;
  width: none;
  height: 110px;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
  padding: 15.5px 9.5px 21.5px 21.5px;
  margin-bottom: 10px;
  overflow-y: scroll;
  border: 1px solid rgba(70, 70, 70, 0.5);
  margin-top: 10px;
}

.sumaryChartBackgroundModalWithFinancial {
  box-sizing: border-box;
  height: 110px;
  width: 100%;
  /* display: inline-block; */
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
  padding: 15.5px 9.5px 21.5px 21.5px;
  overflow: auto;
  margin-bottom: 10px;
  border: 1px solid rgba(70, 70, 70, 0.5);
  margin-top: 10px;
}

.sumaryChartBackgroundModalWithProduction {
  box-sizing: border-box;
  height: 110px;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
  padding: 15.5px 9.5px 21.5px 21.5px;
  overflow: auto;
  margin-top: 10px;
  border: 1px solid rgba(70, 70, 70, 0.5);
}

.divBorder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.trendChartContainerlevel3 {
  margin-top: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 355px;
  width: 100%;
  /* border: 2px solid #29292990; */
  border: 2px solid rgba(70, 70, 70, 0.4);
  border-radius: 8px;
  background: linear-gradient( 180deg, rgba(6, 25, 25, 0.209) 0%, rgba(0, 0, 0, 0.55) 100%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.topTrendChartDiv {
  display: flex;
  justify-content: space-between;
}

.topLeftTrendDiv {
  display: flex;
}

.topLeftTrendDiv a {
  height: 15px;
  width: 84px;
  color: #00A19C;
  font-family: "Museo Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 15px;
  margin-left: 7px;
  margin-top: 22.5px;
}

.topLeftTrendDiv div:first-child {
  height: 26px;
  width: 65px;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 20.5px;
  margin-top: 16.5px;
}

.topLeftTrendDiv img {
  margin-left: 11px;
  margin-top: 16.5px;
}

.subTrendChartDiv {
  margin-left: 10px;
  height: 22px;
  width: 31px;
  opacity: 0.6;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 10px;
  letter-spacing: -0.14px;
  line-height: 28px;
  white-space: nowrap;
}

.topRightTrendDiv {
  display: flex;
  margin-left: 20px;
  margin-bottom: 20px;
}

.topRightTrendDiv span {
  margin-top: 21.5px;
  margin-left: 8px;
  margin-right: 19.5px;
  height: 14px;
  opacity: 0.8;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: -0.17px;
  line-height: 14px;
}

.topRightTrendDiv div {
  margin-top: 21.5px;
  height: 13px;
  width: 13px;
  border-radius: 2px;
}

.trendChart {
  width: 100%;
  height: 68%;
  margin-left: -15px;
}

.chartNotAvailable {
  color: #ffffff;
  opacity: 0.4;
  background-image: linear-gradient(to bottom, #fff, transparent 1px), linear-gradient(#ccc 0px, transparent 0px);
  background-size: 20px 47px;
  height: 216px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.waterfallChartModal {
  width: 100%;
  border: 0px solid rgba(70, 70, 70, 0.5);
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
}

.waterfallChart {
  box-sizing: border-box;
  height: 334px;
  width: 100%;
  min-width: fit-content;
  /* border: 2px solid #29292990; */
  border-radius: 8px;
  padding: 15.5px 9.5px 21.5px 21.5px;
  background: linear-gradient(
    180deg,
    rgba(6, 25, 25, 0.209) 0%,
    rgba(0, 0, 0, 0.55) 100%
  );
}