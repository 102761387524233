.riskProfileContainer {
  margin-top: -60px;
  min-height: 100vh;
  position: relative;
}

.riskProfileRoot {
  padding-top: 60px;
  box-sizing: border-box;
  min-height: 100vh;
}
.riskProfilePanelContainer {
  width: calc(100vw - 250px);
  padding: 25px 20px;
  border-radius: 8px 8px 0px 0px;
  background: linear-gradient(154.62deg, rgba(5, 18, 18, 0.75) 9.92%, rgba(5, 18, 18, 0.75) 9.92%, #000000 123.12%);
  margin-top: 85px;
  margin-left: 220px;
  max-width: calc(100vw - 240px);
  box-sizing: border-box;
}

.riskProfileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: auto;
  margin-bottom: 40px;
}

.riskProfileHeader span {
  color: #fff;
}

.riskProfileHeader h2 {
  font-family: Museo Sans;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #FFFFFF;
  opacity: 0.8;
  margin: 0;
}

.riskProfilePanel {
  display: flex;
  width: 100%;
}

.tableHeader {
  /* font-family: 'Museo-Sans'; */
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.167px;
  text-align: left;
}

.headerSmall {
  width: 8%;
  text-align: center;
  vertical-align: middle;
  min-width: 100px;
  white-space: nowrap;
}

tbody {
  cursor: pointer;
}

.center {
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
}

.chartCenter{
  display: flex;
  justify-content: center;
}

.greyLine {
  border-bottom: 2px solid rgba(206, 206, 206, 0.075);
}

.tableRow {
  width: 77px;
  height: 62px;
  padding: 8px 19px 8px 24px;
  gap: 4px;
  border: 0px 0px 0px 4px;
}

.coloredBar {
  position: relative;
  color: white;
}

.coloredBar::before {
  content: '';
  display: block;
  height: 85%;
  width: 5%; 
  position: absolute;
  top: 7%; 
  background-color: var(--bar-color, transparent);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px; 
}

.orange { --bar-color: #FF8A00; }
.red { --bar-color: #DA2228; }
.yellow { --bar-color: #FFE500; }
.green { --bar-color: #00FF5D; }
.transparent { --bar-color: transparent; }

.oval {
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 37px;
  background: #FFFFFF1A;
  color: #000;
  font-size: 12px;
  padding: 10px 5px;
}

.oval span {
  margin-left: 2px;
}

.outlookTrendContainer {
  height: 100%;
  width: 70px;
  display: flex;
  justify-content: center;
}

.statementOval {
  display: flex;
  justify-content: center;
  background: #FFFFFF1A;
  width: 36px;
  height: 26px;
  padding: 2px 0px 2px 0px;
  border-radius: 999px;
}

.statementOval img {
  width: 22px;
  height: 22px;
}

.evenRisk {
  background-color: rgba(255, 255, 255, 0.08);
}

.tooltipContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.textTooltip {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 400px;
  transform: translateX(-60%);
  background: #182034;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 6px;
  visibility: hidden;
  z-index: 2;
}

.tooltipContainer:hover .textTooltip, .headerTooltip {
  display: block;
  visibility: visible;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.headerTooltipContainer {
  display: none;
}

.headerTooltip{
  position: relative;
  transform: translateX(-30%);
}

.tooltip:hover .headerTooltipContainer {
  display: block;
  position: absolute;
}

.headerTooltip {
  opacity: 1;
  z-index: 2;
}

@keyframes cursorBlink {
  0% { cursor: pointer; }
  50% { cursor: default; }
  100% { cursor: pointer; }
}

.tableRow:hover {
  animation: cursorBlink 0.3s;
}

.changeThis {
  min-width: calc(200px + 50px);
  max-width: 100%;
}

.noResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 80px;
  color: white;
}

.noResult h2 {
  color: white;
  font-family: 'Museo Sans';
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.194px;
  text-align: left;
}