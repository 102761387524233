body {
    background-color: #005564;
    position: relative;
    /* background-image: url(./malaysia_map.jpg);
    background-size: cover; */
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Museo Sans", sans-serif;
}

.mapRoot {
    /* min-width: 100vw; */
    /* padding-top: 60px; */
    box-sizing: border-box;
}

.mapContainer {
    /* margin-top: -60px; */
    max-width: 100vw;
    max-height: 100vh;
    position: relative;
}

.baseHeight {
    /* height: 100vh !important; */
    overflow-x: auto;
    overflow-y: hidden;
}

.baseWidth {
    width: 100vw !important;
    overflow-x: hidden;
    overflow-y: auto;
}

.mapBg {
    top: 0;
    left: 0;
    display: block;
    position: relative;
    z-index: -1;
    margin: 0 auto;
}

.lastUpdated {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 12px;
    text-align: right;
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: flex;
    align-items: center;
}

.lastUpdated i {
    font-size: 20px;
    margin-left: 5px;
}

.lastUpdated img {
    margin-left: 10px;
}

.locationDesc {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 13px;
    letter-spacing: 0.5px;
    position: fixed;
    bottom: 13px;
    left: 30px;
}

.secondLocationDesc{
    left: 1000px;
}

.pointGroup, .pointGroupWrap {
    display: inline-block;
}

.pointGroupWrap {
    position: absolute;
    z-index: 2;
    line-height: 1px;
    transform: translate(-4.4px, -4.4px);
}

.pointGroupDot {
    height: 8.81px;
    width: 8.81px;
    background-color: #DDDDDD;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
}

.pointGroupDot span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px #DDDDDD solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #DDDDDD;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all ease-in-out .2s;
}

.pointGroupDot:hover span {
    opacity: 1;
}

.pointGroupDot::before, .pointGroupDot::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.pointGroupDot::before {
    height: 22.4px;
    width: 22.17px;
    opacity: 0.5;
    background-color: #DDDDDD;
}

.pointGroupDot::after {
    height: 40px;
    width: 40px;
    opacity: 0.2;
    background-color: #DDDDDD;
}

.pointGroupWrap.green .pointGroupDot, .pointGroupWrap.green .pointGroupDot::before, .pointGroupWrap.green .pointGroupDot::after {
    background-color: #37C7A5;
}

.pointGroupWrap.green .pointGroupDot span {
    border: 1px #37C7A5 solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 10px #37C7A5;
}

.pointGroupWrap.red .pointGroupDot, .pointGroupWrap.red .pointGroupDot::before, .pointGroupWrap.red .pointGroupDot::after {
    background-color: #E75552;
}

.pointGroupWrap.red .pointGroupDot span {
    border: 1px #E75552 solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #E75552;
}

.pointGroupWrap.amber .pointGroupDot, .pointGroupWrap.amber .pointGroupDot::before, .pointGroupWrap.amber .pointGroupDot::after, .pointGroupWrap.yellow .pointGroupDot, .pointGroupWrap.yellow .pointGroupDot::before, .pointGroupWrap.yellow .pointGroupDot::after {
    background-color: #FCAA1B;
}

.pointGroupWrap.amber .pointGroupDot span, .pointGroupWrap.yellow .pointGroupDot span {
    border: 1px #FCAA1B solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #FCAA1B;
}

.pointGroupInfo {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    position: absolute;
    text-shadow: 2px 2px 4px #000000;
    z-index: 1;
}

.pointGroupInfoHover {
    top: -57px;
    left: -22px;
    position: absolute;
    padding-left: 52px;
    padding-bottom: 57px;
    display: none;
    opacity: 0;
    transition: all ease-in-out .2s;
    z-index: 998;
}

.pointGroupInfoHoverBody {
    height: 32px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.pointGroupInfoHoverBody button {
    width: 40px;
    color: #fff;
    text-decoration: none;
    transition: all ease-in-out .2s;
    font-size: 18px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 0;
    outline: none !important;
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    z-index: 999;
}

.pointGroupInfoHoverBody button::before {
    content: '';
    background-color: #fff;
    opacity: .4;
    height: 18px;
    width: 1px;
    top: 50%;
    right: 0;
    position: absolute;
    transform: translateY(-50%);
}

.pointGroupInfoHoverBody button:last-child::before {
    display: none;
}

.pointGroupInfoHoverBody button:hover {
    color: #37C7A5;
}

.pointGroupInfoHoverBody button img {
    width: 24px;
    height: 24px;
}

.pointGroupWrap:hover {
    z-index: 9;
}

.pointGroupDot:hover .pointGroupInfoHover {
    display: block;
    opacity: 1;
}

.pointGroupInfoBody {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.dLabel {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 12px;
    padding-right: 5px;
    margin-top: 10px;
    opacity: 0.8;
}

.mb5px {
    margin-bottom: 5px;
}

.pointGroupInfoTop, .pointGroupInfoBodyText {
    align-items: flex-start;
    display: flex;
    white-space: nowrap;
}

.pointGroupInfoTop, .pointGroupInfoBodyText div {
    align-items: flex-start;
    display: flex;
    margin-bottom: 3px;
}

.pointGroupInfoTop span {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
}

.pointGroupInfoTop img {
    width: 10px;
    margin-left: 10px;
    display: none;
}

.pointGroupInfoBodyText b {
    font-weight: 500;
    font-size: 24px;
    margin-right: 5px;
    line-height: 14px;
}

.pointGroupInfoBodyText span {
    margin-right: 8px;
    line-height: 0px;
    margin-top: 5px;
    font-weight: 500;
    font-size: 12px;
}

.pointGroupWrap.left .pointGroupInfo {
    top: -15px;
    right: 35px;
}

.pointGroupWrap.right .pointGroupInfo {
    top: -18px;
    left: 35px;
}

.pointGroupWrap.top .pointGroupInfo, .pointGroupWrap.topleft .pointGroupInfo {
    bottom: 25px;
    right: -12px;
}

.pointGroupWrap.topright .pointGroupInfo {
    bottom: 25px;
    left: -12px;
}

.pointGroupWrap.bottom .pointGroupInfo, .pointGroupWrap.bottomleft .pointGroupInfo {
    top: 30px;
    right: -15px;
}

.pointGroupWrap.bottomright .pointGroupInfo {
    top: 30px;
    left: -12px;
}

.pointGroupWrap.left .pointGroupInfoTop, .pointGroupWrap.left .pointGroupInfoBodyText, .pointGroupWrap.top .pointGroupInfoTop, .pointGroupWrap.top .pointGroupInfoBody, .pointGroupWrap.topleft .pointGroupInfoTop, .pointGroupWrap.topleft .pointGroupInfoBody, .pointGroupWrap.bottom .pointGroupInfoTop, .pointGroupWrap.bottom .pointGroupInfoBody, .pointGroupWrap.bottomleft .pointGroupInfoTop, .pointGroupWrap.bottomleft .pointGroupInfoBody {
    justify-content: flex-end;
}

/* .pointGroupWrap.left .pointGroupInfoBodyText span,
.pointGroupWrap.top .pointGroupInfoBodyText span,
.pointGroupWrap.topleft .pointGroupInfoBodyText span,
.pointGroupWrap.bottom .pointGroupInfoBodyText span,
.pointGroupWrap.bottomleft .pointGroupInfoBodyText span {
    margin-right: 0;
} */

.mapRightPanel {
    position: fixed;
    top: 40px;
    height: 710;
    right: -370px;
    transition: all ease-in-out .3s;
    width: 370px;
    border-radius: 8px;
    /* background-color: rgba(18, 64, 83, .5); */
    /* background-color: #124053; */
    background-color: #041C1F;
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
}

.secondary.mapRightPanel {
    position: fixed;
    top: 60px;
    bottom: 0px;
    right: -340px;
    transition: all ease-in-out .3s;
    width: 550px;
    border-radius: 8px;
    background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
    box-shadow: 0px 0px 26px rgba(198, 163, 163, 0.4);
    backdrop-filter: blur(6px);
}

.secondary.mapRightPanel.compact {
    bottom: auto;
    padding-bottom: 50px;
}

.mapRightPanel.mapRightPanelShow {
    right: 0px;
    z-index: 998;
    top: 60px;
}

.mapRightPanelBody {
    position: relative;
    max-height: 10vh;
}

.mapRightPanelClose {
    background-color: transparent !important;
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    border: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    box-shadow: none !important;
    transition: all ease-in-out .2s;
    cursor: pointer;
}

.mapRightPanelClose i {
    font-size: 14px;
    line-height: 1;
}

.mapRightPanelClose:hover {
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
}

.mapRightPanelBanner {
    width: 100%;
    height: 100px;
}

.mapRightPanelSelect {
    margin-left: 5px;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.8);
    font-family: "Museo Sans", sans-serif;
    font-weight: 100;
}

.mapRightPanelSelect div {
    height: 32px;
    width: 100px;
    border-radius: 4px;
    color: #fff;
    border-color: #2A4044 !important;
    background-color: #2A4044 !important;
}

.mapRightPanelSelect span {
    color: #fff !important;
}

.mapRightPaneTitle {
    display: flex;
    height: 40px;
    width: 340px;
    border-radius: 8px 8px 0 0;
    background: #2A4044;
}

.mapRightPanelBanner img {
    width: 100%;
    height: 100%;
}

.mapRightPanelContent {
    padding: 10px 20px;
}

.mapRightPanelButtons {
    margin-top: 18px;
    margin-bottom: 17px;
    display: flex;
}

.mapRightPanelText {
    font-size: 12px;
    color: #2A4044;
}

.mapRightPanelChart {
    width: 309px !important;
    height: 216px;
    margin-top: 8px;
    margin-left: -15px;
}

.chartNotAvailable {
    opacity: 0.4;
    background-image: linear-gradient(to bottom, #fff, transparent 1px), linear-gradient(#ccc 0px, transparent 0px);
    background-size: 20px 47px;
    height: 216px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mapRightPanelPrimary {
    display: flex;
    height: 70px;
    width: 291px;
    justify-content: space-between;
    margin-left: 5px;
    margin-top: 14px;
    flex-wrap: wrap;
    white-space: nowrap;
    position: absolute;
    z-index: 999;
    flex-direction: column;
}

.mapRightPanelPrimary div {
    padding: 5px;
}

.mapRightPanelPrimary span:first-child {
    color: #E5E5E5;
    font-family: "Museo Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    margin-right: 6px;
}

.mapRightPanelPrimary span:last-child {
    color: #E5E5E5;
    font-family: "Museo Sans", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
}

.mapRightPanelPrimary span:nth-child(2) {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 26px;
    margin-right: 5px;
    line-height: 14px;
}

.mapRightPanelBorder {
    border-top: 1px solid #2F5462;
}

.mapRightPanelLink {
    /* display: flex;
    margin-left: 24%; */
}

.mapRightPanelLink a {
    color: #00A19C;
    font-size: 13px;
    margin-left: 20px
}

.mapRightPanelTopChart {
    display: flex;
    justify-content: space-between;
    max-height: 30px;
}

.nbButton {
    cursor: pointer;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 0;
    outline: none !important;
    box-shadow: none !important;
    position: relative;
    background-color: transparent !important;
    transition: all ease-in-out .1s;
    opacity: .4;
}

.nbButton:hover {
    opacity: 1;
}

.nbButton.active {
    opacity: 1 !important;
}

.nbButton:disabled {
    opacity: .4 !important;
}

.nbButton i {
    font-size: 8px;
    line-height: 1;
}

.mapRightPanelTabs>ul {
    margin-top: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
    flex-wrap: nowrap;
    padding-left: 0;
    border-bottom: 1px #2F5462 solid;
    -ms-overflow-style: none;
    /* IE and Edge */
    overflow-x: scroll;
    scrollbar-width: none;
}

.mapRightPanelTabs>ul::-webkit-scrollbar {
    display: none;
}

::-webkit-scrollbar-button:horizontal:decrement {
    border-width: 7px 14px 7px 0;
    border-color: transparent #404040 transparent transparent;
}

::-webkit-scrollbar-button:horizontal:increment {
    border-width: 7px 0 7px 14px;
    border-color: transparent transparent transparent #404040;
}

.mapRightPanelTabs>ul>li {
    text-align: center;
    margin-bottom: -1px;
    white-space: pre;
}

.mapRightPanelTabs>ul>li>button {
    border: 0;
    border-bottom: 2px transparent solid;
    opacity: .4;
    font-size: 13px;
    text-decoration: none;
    color: #00A19C;
    padding: 2px 8px;
    display: block;
    font-weight: 500;
    background-color: transparent;
    outline: none !important;
    cursor: grab;
}

.mapRightPanelTabs>ul>li>button:active {
    border: 0;
    border-bottom: 2px transparent solid;
    opacity: .4;
    font-size: 13px;
    text-decoration: none;
    color: #00A19C;
    padding: 2px 8px;
    display: block;
    font-weight: 500;
    background-color: transparent;
    outline: none !important;
    cursor: grabbing;
}

.mapRightPanelTabActive {
    margin-bottom: 12px;
    border-color: #00A19C;
    opacity: 1;
    border: 0;
    border-bottom: 2px #00A19C solid;
    font-size: 14px;
    text-decoration: none;
    color: #00A19C;
    padding: 2px 8px;
    display: block;
    font-weight: 500;
    background-color: transparent;
    outline: none !important;
}

.mapRightPanelTab {
    margin-bottom: 12px;
    border: 0;
    border-bottom: 2px #00A19C solid;
    opacity: .4;
    font-size: 14px;
    text-decoration: none;
    color: #00A19C;
    padding: 2px 8px;
    display: block;
    font-weight: 500;
    background-color: transparent;
    outline: none !important;
}

.mapRightPanelTabContent {
    height: 63vh;
    max-height: calc(90vh - 204px);
    margin-bottom: 10px;
    margin-top: 12px;
    color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
}

.mapRightPanelTabContent::-webkit-scrollbar {
    display: none;
}

.mapRightPanelTabContent .pointGroupInfoBodyText {
    align-items: flex-start;
}

.mapRightPanelTabContent .pointGroupInfoBodyText span {
    font-size: 18px;
    line-height: 22px;
    padding-top: 5px;
}

.mapRightPanelTabContent .pointGroupInfoBodyText b {
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
}

.mapRightPanelLegend {
    justify-content: space-evenly;
    display: flex;
    border-width: 0.1px;
    border-style: solid;
    border-color: #213233;
    width: 100%;
    height: 32px;
}

.legendarea {
    margin-top: 10px;
    height: 12px;
    width: 12px;
    border-radius: 2px;
    border: 6px solid;
}

.legenddot {
    margin-top: 16px;
    box-sizing: border-box;
    height: 1px;
    width: 9px;
    border: 1px dashed;
}

.legenddash {
    margin-top: 16px;
    box-sizing: border-box;
    height: 1px;
    width: 14px;
    border: 1px solid;
}

.legendText {
    margin-left: 8px;
    margin-top: 12px;
    width: 28px;
    opacity: 0.8;
    color: rgba(255, 255, 255, 1);
    font-family: "Museo Sans", sans-serif;
    font-size: 10px;
    letter-spacing: 0.27px;
    line-height: 12px;
    white-space: nowrap;
}

.color-blue {
    color: #8FC8F8;
}

.color-yellow {
    color: #DFEB98;
}

.color-green {
    color: #50A4B9;
}

.color-purple {
    color: #9E94DB;
}

.colorGrey {
    color: #BBBBBB;
}

.legendItemContainer {
    display: flex;
    cursor: pointer;
}

.scrollInfoTitle {
    display: flex;
    padding-bottom: 12px;
    flex-direction: row;
    justify-content: space-between;
}

.scrollInfoAntDesignTable {
    overflow-y: scroll;
    max-height: 83%;
}

.scrollInfoAntDesignTable div {
    background: #2A4044;
    color: #FFFFFF;
    border-radius: 8px;
}

.scrollInfoAntDesignTable th {
    background: #2A4044 !important;
}

.scrollInfoModalHeader {
    padding: 14PX;
    display: flex;
    border-bottom-style: solid;
    border-bottom-color: rgba(239, 239, 239, 0.12);
    font-size: 18px;
}

.scrollInfoModalHeader img {
    margin-left: auto;
}

.scrollInfoModalHeader span {
    margin-right: 10%;
    opacity: 0.8;
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 18px;
    letter-spacing: 0.49px;
    line-height: 22px;
}

.tableRow span:first-child {
    height: 14px;
    width: 115px;
    opacity: 0.6;
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}

.tableRow span:last-child {
    height: 14px;
    width: 110px;
    opacity: 0.6;
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    align-self: center;
}

.tableRowLocation {
    display: flex;
    font-size: 11px;
    align-items: center;
}

.tableRowActivity {
    display: flex;
    font-size: 11px;
    align-items: center;
}

.tableRowActivity div img {
    margin-right: 10px;
}

.locationText {
    padding-left: 6px;
    display: flex;
    flex-direction: column;
    width: 138px;
    opacity: 0.6;
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 17px;
}

.locationTextModal {
    padding-left: 6px;
    display: flex;
    flex-direction: column;
    width: auto;
    opacity: 0.6;
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 17px;
    white-space: nowrap;
}

.pointTitle {
    padding: 10px;
}

.drillDown {
    cursor: pointer;
    letter-spacing: 0.27px;
    display: flex;
    width: 74px;
    height: 28px;
    justify-content: space-evenly;
    font-size: 10px;
    font-family: "Museo Sans", sans-serif;
    margin-left: 8px;
    color: #FFFFFF;
    align-items: center;
    margin-top: 6px;
    background: #041b1e;
    border-radius: 12px;
}

.drillDown:hover {
    cursor: pointer;
    letter-spacing: 0.27px;
    display: flex;
    width: 74px;
    height: 28px;
    justify-content: space-evenly;
    font-size: 10px;
    font-family: "Museo Sans", sans-serif;
    margin-left: 8px;
    color: #FFFFFF;
    align-items: center;
    margin-top: 6px;
    background: #00A19C;
    border-radius: 12px;
}

.drillDown img {
    width: 16px;
    height: 16px;
}

.drillDown span {
    margin-top: 2px;
}

.pointTitle span {
    font-weight: 300;
    font-size: 14px;
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    letter-spacing: 0.35px;
    text-transform: uppercase;
}

.pointTitle img {
    height: 12px;
    margin-left: 8px;
    display: none;
}

.pointNote {
    font-weight: 500;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.4);
}

.pointNoteModal {
    font-weight: 500;
    font-size: 12px;
    opacity: .8;
    cursor: pointer;
    color: #00A19C;
}

.pointNoteDetail {
    padding: 8px 16px;
    position: relative;
    border-radius: 8px;
    background-color: #2A4044;
    margin-right: 5px;
    height: 53%;
    overflow-y: scroll;
}

.pointNoteDetail ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;
}

.pointNoteDetail ul li {
    border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
    box-shadow: inset 0 -1px 0 0 #2A4044;
    padding: 13px 0;
    opacity: 0.6;
    color: rgba(255, 255, 255, 0.6);
    font-family: "Museo Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
}

.pointNoteDetail ul li:last-child {
    border-bottom: 0;
    box-shadow: none
}

.mapZoom {
    display: block;
    height: 312px;
    width: 50px;
    border-radius: 30px;
    background: linear-gradient(180deg, rgba(5, 18, 18, 0.55) 0%, rgba(0, 0, 0, 0.74) 100%);
    padding: 13px 11px;
    position: relative;
}

/* 
.mapZoom::before {
    content: '';
    height: 297px;
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.39;
    border-radius: 30px;
    background: linear-gradient(45deg, #303395 0%, #28EFEF 100%);
    z-index: -1;
} */

.mapZoom::after {
    content: '';
    height: 312px;
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.39;
    border-radius: 30px;
    background: linear-gradient(45deg, #303395 0%, #28EFEF 100%);
    z-index: -1;
    box-shadow: 1px 0 11px rgba(40, 239, 239, .39);
}

.mapZoomIcon {
    display: block;
    opacity: .6;
    transition: all ease-in-out .3s;
}

.mapZoomIcon img {
    height: 28px;
}

.mapZoom:hover .mapZoomIcon {
    opacity: 1;
}

.mzButtons {
    width: 20px;
    margin: 0 auto;
    margin-top: 8px;
}

.mzButton {
    border: 0;
    outline: 0;
    height: 60px;
    width: 100%;
    margin-bottom: 3px;
    box-sizing: border-box;
    display: block;
    padding: 0;
    /* cursor: pointer; */
    background-color: transparent;
}

.mzButton span {
    display: block;
    width: 5px;
    height: 100%;
    margin: 0 auto;
    opacity: 0.1;
    background-color: #FFFFFF;
    transition: all ease-in-out .3s;
}

.mzButton.prev span {
    background-color: #fff;
    opacity: 0.9;
    transition: all ease-in-out .3s;
    cursor: pointer;
}

.mzButtons .mzButton:first-child span {
    border-radius: 5px 5px 0 0;
}

.mzButtons .mzButton:last-child span {
    border-radius: 0 0 5px 5px;
}

.mzButton:not(:disabled):hover span, .mzButton:not(:disabled).active span {
    background-color: #00FFF7;
    opacity: 0.9;
    transition: all ease-in-out .3s;
    cursor: pointer;
}

.mapToggleMode {
    height: 50px;
    width: 180px;
    /* opacity: 0.8; */
    border-radius: 6px;
    /* background: linear-gradient(180deg, rgba(5, 18, 18, 0.44) 0%, #000000 80%); */
    box-shadow: 0 2px 4px 0 rgba(0, 255, 247, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.mapToggleMode::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    content: '';
    opacity: 0.64;
    background: linear-gradient(180deg, rgba(5, 18, 18, 0.55) 0%, #000000 100%);
    z-index: -1;
}

.mapToggleMode::after {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: '';
    position: absolute;
    opacity: 0.15;
    border-radius: 30px;
    background: linear-gradient(67.9deg, #303395 0%, #28EFEF 100%);
    filter: blur(6px);
    z-index: -1;
}

.mapToggleMode img {
    height: 30px;
    width: 30px;
}

.mapToggleIcon {
    height: 30px;
    width: 30px;
    margin-right: 5px;
}

.mapToggleText {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 11px;
    letter-spacing: 0;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;
    margin-right: 7px;
}

.mapToggleSwitch {
    box-sizing: border-box;
    height: 30px;
    width: 57px;
    border: 1px solid #00FFF7;
    border-radius: 15px;
    background-color: rgba(0, 161, 156, .5);
    position: relative;
    cursor: pointer;
    opacity: .8;
    transition: all ease-in-out .2s;
}

.mapToggleSwitch:hover {
    opacity: 1;
}

.mapToggleSwitch span {
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 15px;
    background-color: #fff;
    display: block;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    transition: all ease-in-out .3s;
}

.mapToggleSwitch.active span {
    left: 28px;
    transform: translateY(-50%);
}

@media(max-height:780px) {
    .mapActions, .mapActionRight {
        transform: scale(.7);
    }
}

@media(max-height:575px) {
    .mapActions, .mapActionRight {
        transform: scale(.6);
    }
}

@media(max-width:767px) {
    .mapActions, .mapActionRight {
        transform: scale(.6);
    }
}

.chartTooltip {
    height: 44px;
    width: 91.66px;
    opacity: 0.7;
    border-radius: 3px;
    background-color: #000000;
}

.paddingBottom {
    padding-bottom: 12px;
}

.noHighlight {
    top: 30px;
    opacity: 0.4;
    position: absolute;
    margin-left: 20%;
}

.shipRow {
    padding-bottom: 12px;
}

.shipRowText {
    padding-bottom: 12px;
    justify-content: space-between;
    display: flex;
}

.shipTextOrigin {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 11px;
}

.shipTextDestination {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 11px;
}

.border {
    box-sizing: border-box;
    height: 1px;
    width: 308px;
    border: 1px solid #213233;
}

.tabArrow {
    height: 34px;
    width: 3.41px;
    color: #00A19C;
}

.arrowRight {
    position: absolute;
    padding-left: 0px;
    z-index: 9;
    height: 25px;
    background-color: #041C1F;
    box-shadow: -10px 0px 10px 0px #041c1f;
}

.arrowLeft {
    left: 13px;
    position: absolute;
    transform: rotate( 180deg);
    background-color: #041C1F;
    box-shadow: -10px 0px 10px 0px #041c1f;
    z-index: 9;
    height: 25px;
}

.vesselPoint {
    position: absolute;
    width: 12px;
    height: 12px;
}

.activityArrowUp{
    transform: rotate(180deg);
}

.flowrateContainer {
    position: absolute;
    left: 10rem;
    color: white;
    display: flex;
}

.filterBlock {
    position: fixed;
    width: 327px;
    height: 44px;
    border-radius: 4px;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.8) 0%, #000000 100%);
    padding: 12px;
    line-height: 1;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
}

.filterBlockDropdown button {
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 16px;
    /* letter-spacing: 0; */
    font-weight: 100;
    line-height: 19px;
    letter-spacing: 1px;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
}

.filterBlockDropdown button i {
    font-size: 24px;
    color: #fff;
    margin-left: 8px;
    margin-right: -10px;
}

.filterBlockDropdownMenu {
    position: absolute;
    border-radius: 0 0 3px 3px;
    bottom: 100%;
    width: 100%;
    left: 0;
    background: linear-gradient(180deg, #22292C 0%, #23292C 29.4%, #151718 100%);
    z-index: 9;
    transition: all ease-in-out 0.2s;
    height: 0;
    overflow: hidden;
}

  .filterBlockDropdown.show .filterBlockDropdownMenu {
    height: 44px;
}

  .filterBlockDropdownMenu ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

  .filterBlockDropdownMenu ul li button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    color: rgba(255, 255, 255, 0.3);
    transition: all ease-in-out 0.2s;
}

  .filterBlockDropdownMenu ul li button:hover,
  .filterBlockDropdownMenu ul li button.selected {
    background-color: #004f4e;
    color: #fff;
}