/* Cargo Information */
.highlightComponent.cargoInfo {
  flex: 0 1 auto;
  margin-top: 24px
}

.cargoInformationSection {
  background: rgba(29, 29, 27, 0.4);
  border-radius: 8px;
  padding: 12px;
  margin: 20px 0;
}

.highlightComponent .highlightTitle span:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.222222px;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.8;
}

.highlightComponent .highlightTitle span:last-child {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.138889px;
  color: rgba(255, 255, 255, 0.6);
  float: right;
}

.cargoInformationSection {
}

.contractAgreementSection {
}

.chartLegendItem {
  color: #e5e5e5;
}

.chartLegendItem .legendBox {
  width: 4px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
}

.chartLegendItem .legendBox.TERM {
  background: #20419a;
}

.chartLegendItem .legendBox.SPOT {
  background: #90a0cd;
}

.chartLegendItem .legendBox.DES {
  background: #8b5ca7cd;
}

.chartLegendItem .legendBox.FOB {
  background: #bb9fcc;
}

.chartLegendItem .unitName {
  margin-right: 5px;
  font-weight: 300;
}

.chartLegendItem .value {
  margin-right: 5px;
  font-weight: 700;
}

.totalCargoSection {
  color: #e5e5e5;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.totalCargoSection p {
  margin-bottom: 0;
  font-weight: 300;
  margin-right: 15px;
  align-self: center;
}

.totalCargoSection span {
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
  margin-right: 10px;
  flex-basis: 100%;
  height: 0;
}
