.sectionTitle span {
  color: #E5E5E5;
  font-family: Museo Sans;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1944444477558136px;
  text-align: left;
  opacity: 0.6;
}

.sectionTitle h3 {
  font-family: Museo Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.detailBreakdown{
  margin-top: 12px;
}

.execSummaryRow {
  margin-top: 12px;
}

.summaryDate {
  background: rgba(122, 122, 122, 1);
  border-radius: 3px;
  width: 96px;
  height: fit-content;
  margin-right: 10px;
  text-align: center;
  padding: 0 8px;
}

.summaryDate span {
  font-family: Museo Sans;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.1666666716337204px;
}

.execSummaryContent  {
  font-family: Museo Sans;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.2222222238779068px;
  text-align: left;
  align-self: center;
}