.contentDetailPanel {
  display: flex;
  width: 95%;
}

.contentDetailContainer {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  margin: 100px 0 20px 274px;
  max-width: 80vw;
  position: relative;
  background: linear-gradient(154.62deg,
      rgba(5, 18, 18, 0.55) 0%,
      rgba(5, 18, 18, 0.55) 0%,
      #000000 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.contentDetailContainer h2 {
  opacity: 0.8;
  color: #ffffff;
  font-family: 'Museo Sans', sans-serif;
  font-size: 18px;
  letter-spacing: 0.34px;
  line-height: 22px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: 0;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
  width: 100%;
  padding-bottom: 8px;
}

.groupBtn {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 20px;
  top: 20px;
}

.groupBtn button {
  color: #ffffff;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 120px;
  height: 40px;
}

.primaryBtn {
  background-color: #00a19c;
  border-color: #00a19c;
}

.secondaryBtn {
  background: none;
  border-color: #00a19c;
  border-style: solid;
  border-width: 1px;
}

.secondaryBtn.disabled,
.primaryBtn.disabled {
  opacity: 0.3;
}

.formContent {
  margin-top: 20px;
}

.formItem {
  margin-left: 2px;
}


.formItem label:first-child {
  color: #ffffff;
  display: block;
  margin-bottom: 10px;
}

.formItem input {
  box-sizing: border-box;
  height: 44px;
  width: 100%;
  border: none;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  padding: 10px;
}

.formItem input[type='file'] {
  display: none;
}

.formItem input[type='date']::-webkit-calendar-picker-indicator {
  background: url('./icons/calendar.svg') no-repeat;
  cursor: pointer;
  background-size: contain
}

.formItem .imgUploader {
  width: 100%;
  display: block;
  height: 44px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
}

.formItem .imgUploader span:first-child {
  opacity: 0.4;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

.formItem .imgUploader span:last-child {
  color: #ffffff;
  position: absolute;
  right: 20px;
  bottom: 6px;
  cursor: pointer;
  padding: 2px 40px;
  border-radius: 4px;
  height: 30px;
  background: none;
  border: 1px solid #00a19c;
}

.slideTabs {
  position: relative;
  padding: 20px 20px 70px 20px;
  margin-top: 20px;
  box-sizing: border-box;
  border: 1px solid rgba(151, 151, 151, 0.2);
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.55) 0%, #000000 100%);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.groupSlideBtn {
  position: absolute;
  right: 20px;
  bottom: 20px;
  margin-top: 50px;
}

.groupSlideBtn button {
  color: #ffffff;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 150px;
  height: 35px;
}

.charCount {
  padding: 10px 0 0 5px;
  color: #6b8488;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 14px;
}

/* For Sustainability Summary Edit */
.summaryContentDetailPanel {
  display: flex;
}

.summaryContentDetailContainer {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  max-width: 80vw;
  position: relative;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.summaryGroupBtn {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 20px;
}

.summaryGroupBtn button {
  color: #ffffff;
  margin-left: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 60px;
  height: 30px;
}

.primaryBtn {
  background-color: #00a19c;
  border: none
}

.secondaryBtn {
  background: none;
  border-color: #00a19c;
  border-style: solid;
  border-width: 1px;
}

.secondaryBtn.disabled,
.primaryBtn.disabled {
  opacity: 0.3;
}