.sectionInfo,
.sustainabilityInfo,
.sustainabilityValue {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.mhsevInfo {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.sustainabilityInfo {
    width: 300px;
}

.sustainabilityValue {
    width: 20vw;
}

.sectionInfo .sectionDate,
.sustainabilityInfo .sustainabilityEffort {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.194px;
}

.sectionInfo .sectionDate {
    color: #E5E5E5;
    opacity: 0.6;
}

.sectionInfo .sectionEntity {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}

.sectionDetail {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.194px;
    padding-top: 3px;
    width: 370px;
}

.sustainabilityInfo .sustainabilityDetail {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.6);
}

.sustainabilityValue .title {
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #93989A;
}

.sustainabilityValue .actualValue {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.mhsevInfo .mhsevQuarter {
    height: 20px;
    border-radius: 3px;
    display: flex;
    padding: 10px 10px 10px 10px;
    position: relative;
}

.mhsevInfo .mhsevQuarter .mhsevQuarterValue {
    align-self: center;
}

/* .detailsItem {
    height: inherit;
    background: rgba(29, 29, 27, 0.4);
    border-radius: 8px;
    display: flex;
    color: #FFFFFF;
    padding: 12px;
    gap: 30px;
    position: relative;
  } */

.mhsevInfo .mhsevQuarter {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}

.mhsevInfo .mhsevMission {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.quarter2, .quarter3, .quarter4 {
    background: #666666;
}

.quarter2 {
    background: #0B882E;
}