.mapRightPanelPrimaryInfo {
  background: rgba(29, 29, 27, 0.4);
  border-radius: 8px;
  padding: 12px;
  display: block;
  margin: 20px 0;
  height: auto;
}

.primaryInfoImage {
  height: 100%;
}

.primaryInfoImage img {
  width: 100%;
  height: 136px;
  object-fit: cover;
  border-radius: 4px;
}