:root {
  --brand-color: #fff;
}

.headerRoot {
  color: #fff;
  background: linear-gradient( 180deg, rgba(6, 25, 25, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%);
  position: fixed;
  width: 100vw;
  z-index: 999;
  top: 0;
  left: 0;
}

.headerContainer {
  margin: 0 auto;
  padding: 0 40px 0 0;
  height: 60px;
}

.brand {
  position: relative;
  text-decoration: none;
}

/* @media(max-width:576px) {
    .brandTxt {
        display: none;
    }
} */

.banner {
  text-align: center;
}

.bannerTitle {
  margin: 0;
  padding: 10px;
  font-weight: normal;
  font-size: 4em;
  line-height: 1em;
}

.bannerDesc {
  padding: 0;
  color: rgba(255, 255, 255, 0.5);
  font-size: 1.25em;
  margin: 0;
}

/* responsive mobile */

.backGroundSideBarMobile {
  transition-duration: 1s;
  height: calc(100vh - 60px);
  /* margin-top: -60px; */
  /* height: 100vh; */
  width: 75vw;
  z-index: 1000;
  position: fixed;
}

.groupButton {
  z-index: 2;
}

.brandTxt {
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 15px;
  font-weight: 350;
  letter-spacing: 0.5px;
  line-height: 22px;
  text-decoration: none;
  display: flex;
  padding-left: 23%;
  align-items: center;
  white-space: nowrap;
  margin-top: 5px;
}

.headerSideBar {
  display: flex;
  align-items: center;
  width: 100vw;
  background: linear-gradient(180deg, rgba(6, 25, 25, 1) 0%, #000000 100%);
}

.logo {
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  height: 26px;
  width: 26px;
  margin-left: 190px;
  margin-top: 5px;
}

.logoBrand {
  display: flex;
  width: 100%;
}

.naviBackground {
  overflow: scroll;
  padding-top: 24px;
  width: 75vw;
  height: 100%;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.547631) 0%, #000000 100%);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 60px;
  left: 0;
}

.saperate {
  height: 1px;
  width: 80%;
  /* opacity: 0.25; */
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 34px;
}

.iconClose {
  position: absolute;
  margin-left: 30px;
}

.isCloseSideBarMobile {
  height: calc(100vh - 60px);
  width: 75vw;
  z-index: 1000;
  position: fixed;
  transition-duration: 1s;
  transform: translate(-100vw);
  /* margin-top: -60px; */
}

.adminSidebar {
  margin-top: 60px;
  transform-origin: top left;
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 3;
  height: 100vh;
  width: 200px;
  opacity: 0.9;
  background-color: #092427;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.adminSidebar>div {
  display: flex;
  height: 130px;
  width: 200px;
  min-height: 130px;
  justify-content: center;
  align-items: center;
}

.activeSidebar>div {
  height: 100px;
  width: 180px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
}

.adminSidebar .adminSidebarButton {
  display: flex;
  flex-direction: column;
  margin-left: 28px;
  margin-right: 30px;
  margin-top: 23px;
  margin-bottom: 22px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.adminSidebar div img {
  height: 31.87px;
  width: 32.98px;
  margin-bottom: 9px;
}

.adminSidebar div span {
  color: #00a19c;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
}

.personaAccess {
  opacity: 0.4;
  cursor: not-allowed;
}

.personaAccess:hover:after, .personaAccess.active::after {
  background: unset !important
}