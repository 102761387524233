.scoreCardsSectionTitle {
    color: #fff;
    font-size: 30px;
    letter-spacing: 0;
    line-height: 42px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    font-weight: 500;
    margin-top: 24px;
}

.scoreCardsSectionTitle span {
    font-weight: 100;
}

.scoreCardsLeft .scoreCardsSectionTitle {
    margin-bottom: 30px;
}

.scoreCardsSectionTitleWithAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterContainer {
    display: flex;
    gap: 10px;
    align-items: center;
}

.filterBlocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.filterBlock {
    border-radius: 4px;
    background: linear-gradient(180deg,
            rgba(6, 25, 25, 0.374) 0%,
            rgba(0, 0, 0, 0.68) 100%);
    padding: 15px 5px 15px 15px;
    line-height: 1;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
}

.filterBlock>span:nth-of-type(1)  {
    color: #dddddd;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 100;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px rgba(255, 255, 255, 0.3) solid;
}

.filterBlock>span:nth-of-type(2) {
    font-family: 'Museo Sans', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    padding-right: 10px;
}

.filterBlockDropdown button {
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 100;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
}

.filterBlockDropdown button i {
    font-size: 24px;
    color: #00a19c;
    margin-left: 8px;
    margin-right: -10px;
}

.mapRightPanelSelect {
    color: #00a19c;
}

.mapBreadcrumbContainer {
    position: fixed;
    top: 90px;
    left: 2%;
}

.mapBreadcrumb {
    background: linear-gradient(180deg, rgba(5, 18, 18, 0.44) 0%, rgba(0, 0, 0, 0.80) 100%);
    mix-blend-mode: normal;
    border-radius: 6px;
    padding: 15px;
    height: 50px;
    width: fit-content;
    box-shadow: 8px -8px 16px 0px rgba(40, 239, 239, 0.10);
}

.mapBreadcrumb span,
.mapBreadcrumb a {
    color: white !important;

}

.mapBreadcrumbItem {
    cursor: pointer;
    font-weight: 200;
}

.mapBreadcrumbItem.currentLocationBcItem {
    font-weight: 500;
}