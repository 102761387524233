  .equipmentItemContainer {
    background: #172B35CC;
    mix-blend-mode: normal;
    border-radius: 8px;
    padding: 16px;
    gap: 8px;
    margin-bottom: 20px;
    margin-right: 20px;
    width: 320px;
    max-width: 320px;
    flex-direction: column;
    display: flex;
    height: 278px;
    overflow: auto;
    z-index: 5;
  }
  
  .header {
    font-family: 'Museo Sans', sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.3px;
    text-align: left;
    color: #FFFFFF;
    padding-bottom: 5px;
  }

  .unit {
    font-family: Museo Sans;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.17px;
    opacity: 0.8;
    float: right;
  }

  .equipmentModule {
    background: #1D1D1B66;
    border-radius: 5px;
    padding: 4px 8px;
    color: #FFFFFF;
    font-family: 'Museo Sans', sans-serif;
    font-weight: 500;
    margin-bottom: 4px;
    letter-spacing: 0.22px;
    cursor: pointer;
  }

  .equipmentList {
    margin-top: 8px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
    align-items: center;
    gap: 20px;
    font-family: 'Museo Sans', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1944444477558136px;
    text-align: left;
  }

  .equipmentName {
    max-width: 130px;
    white-space: normal;
    word-wrap: break-word;
    color: rgba(227, 227, 227, 0.75);
    font-family: 'Museo Sans', sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.19px;
    text-align: left;
  }

  .data {
    color: rgba(255, 255, 255, 0.8);
    font-family: 'Museo Sans', sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.19px;
    text-align: left;
  }

  .font {
    color: white;
    font-weight: 500;
  }
