body {
  background-color: #005564;
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Museo Sans", sans-serif;
}

.valueChainRoot {
  /* min-width: 100vw; */
  padding-top: 60px;
  box-sizing: border-box;
  min-height: 100vh;
}

.valueChainContainer {
  min-height: 100vh;
  width: 100vw;
  position: relative;
}

.progressTop {
  height: 60px;
  margin: 50px 20px 30px 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressItem {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  position: relative;
}

/* .progressTop .progressItem:first-child{
    justify-content: flex-end;
}
.progressTop .progressItem:last-child{
    justify-content: flex-start;
} */

.progressItemIcon img {
  height: 110px;
}

.progressItemSeparator {
  margin-left: 10px;
  margin-right: -10px;
  border-bottom: 1px solid rgb(0 161 156 / 60%);
  /* opacity: 0.6; */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(-50% + 60px);
  width: 50%;
}

.progressItemText {
  opacity: 0.8;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0.27px;
  line-height: 17px;
  text-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
  text-transform: uppercase;
  margin-right: 6px;
  margin-left: -10px;
}

.integrateLNGContainer, .integrateGASContainer {
  position: relative;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.integrateLNG,
.integrateGAS {
  display: flex;
  border-radius: 8px;
  background: linear-gradient(
    166.91deg,
    rgba(5, 18, 18, 0.374) 0%,
    rgba(0, 0, 0, 0.68) 100%
  );
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
}

.integrateLNGContainer h2,
.integrateGASContainer h2 {
  opacity: 0.8;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.34px;
  line-height: 22px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: 0;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.integrateLNGItem {
  /* display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-wrap: wrap; */
  width: 100%;
}

.integrateLNGItem h2 {
  padding: 16px 0px 0px 16px;
}

.integrateItem {
  margin: 20px 16px 16px 16px;
  flex: 1;
  padding: 12px 20px;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(6, 25, 25, 0.374) 0%,
    rgba(0, 0, 0, 0.68) 100%
  );
}

.integrateItemNA {
  padding: 22px 20px 32px;
  margin-top: 0px;
  opacity: 0.4;
  color: #C6C6C6;
  font-size: 18px;
  letter-spacing: 0.53px;
  line-height: 22px;
  text-align: center;
}

.integrateItemHeading {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  height: 60px;
}

.integrateItemHeadingBoxShadow {
  box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.1);
}

.integrateItemHeading b {
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 1.04px;
  line-height: 31px;
  font-weight: 500;
}

.integrateItemHeading span {
  opacity: 0.6;
  color: #ffffff;
  font-size: 13px;
  letter-spacing: 0.61px;
  line-height: 19px;
  margin-left: 5px;
  font-weight: 300;
}

.integrateItemHeading img {
  height: 88px;
  margin-left: -20px;
}

.itemHeadingNA {
  opacity: 0.6;
  color: #C6C6C6;
  font-family: "Museo Sans", sans-serif;
  font-size: 24px;
  letter-spacing: 0.96px;
  line-height: 29px;
}

.integrateItemBody {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  column-gap: 20px;
  margin-top: 15px;
}

.flexStart {
  justify-content: flex-start !important;
}

.integrateItemValue {
  min-width: 20%;
  flex: 1;
}

.flexStart .integrateItemValue {
  min-width: calc((100% - 40px) / 3);
  flex: initial;
}

.integrateItemValue > span,
.integrateItemValue > b,
.integrateItemValue > small {
  display: block;
}

.integrateItemValue span {
  opacity: 0.8;
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.35px;
  line-height: 14px;
  font-weight: 300;
  white-space: nowrap;
}

.integrateItemValue b {
  font-size: 20px;
  letter-spacing: 0.58px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 4px;
  margin-bottom: 1px;
}

.integrateItemValue small {
  opacity: 0.6;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0.38px;
  line-height: 17px;
  font-weight: 300;
}

/* GAS */

.integrateGASLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 18%;
  padding: 12px 20px;
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(6, 25, 25, 0.374) 0%,
    rgba(0, 0, 0, 0.68) 100%
  );
  margin-top: 20px;
}

.integrateGASLeft b,
.integrateGASLeft small {
  display: block;
  text-align: center;
}

.integrateGASLeft b {
  font-size: 26px;
  letter-spacing: 1.04px;
  line-height: 31px;
  font-weight: 500;
}

.integrateGASLeft small {
  opacity: 0.6;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.61px;
  line-height: 19px;
  font-weight: 300;
}

.integrateGASTitle {
  opacity: 0.8;
  color: #ffffff;
  font-size: 22px;
  letter-spacing: 0.5px;
  line-height: 27px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 0 50px;
}

.integrateGASLines {
  flex: 1;
}

.integrateGASLine {
  display: flex;
  align-items: center;
}

.integrateGASItems {
  display: flex;
  column-gap: 20px;
  width: 100%;
}
/* 
@media(max-width: 1599px) {
    .integrateLNG, .integrateGAS, .progressTop {
        margin-left: 110px;
        margin-right: 12px;
        padding: 12px;
    }
    .integrateLNG h2, .integrateGAS h2 {
        font-size: 16px;
    }
    .integrateGASLeft {
        width: 200px;
    }
    .progressItemIcon img {
        height: 100px;
    }
    .progressItemSeparator {
    }
    .progressItemText {
        font-size: 16px;
    }
    .integrateItemValue b {
        font-size: 18px;
    }
    .integrateItemValue small {
        font-size: 10px;
    }
    .integrateItem {
        padding: 12px;
    }
    .integrateGASItems, .integrateLNGContainer {
        grid-column-gap: 12px;
        column-gap: 12px;
    }
    .integrateItem {
        margin-top: 12px;
    }
    .integrateItemBody {
        grid-column-gap: 8px;
        column-gap: 8px;
    }
    .integrateItemHeading b {
        line-height: 24px;
    }
    .integrateGASTitle {
        font-size: 16px;
        padding: 0 8px;
    }
}

@media(max-width: 1100px) {
    .integrateLNGContainer .integrateItem {
        width: calc(50% - 24px);
        flex: auto;
    }
    .integrateGASLeft {
        width: 100px;
    }
    .progressItemText {
        font-size: 13px;
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: block;
        text-align: center;
        top: calc(100% - 10px);
    }
    .progressTop{
        margin-bottom: 50px;
    }
    .progressItemSeparator{
        transform: translate(-50%, -50%);
        right: -50%;
        width: 50%;
        margin-right: 0;
    }
}

@media(max-width:992px) {
    .integrateGASContainer {
        flex-wrap: wrap;
    }
   
}

@media(max-width: 767px) {
    .integrateLNGContainer .integrateItem {
        width: 100%;
        flex: auto;
    }
} */
