.limitTable {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(5, 18, 18, 0.55) 0%, #000000ab 100%);
  border-radius: 4px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 50px;
}
.limitTable table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  overflow-x: scroll;
}
.limitTable table img {
  margin-left: 14px;
}
.limitTable table input[type="checkbox"] {
  appearance: none;
  border: 2px solid #00a19c;
  border-radius: 4px;
  box-sizing: border-box;
  height: 18px;
  outline: none;
  width: 18px;
}
.limitTable table input[type="checkbox"]:checked::after {
  color: #00a19c;
  content: "x";
  margin-left: 3.5px;
  margin-top: -2px;
  position: absolute;
}
.limitTable table td {
  color: #ffffffcb;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  cursor: pointer;
}
.limitTable table .expandRow td {
  cursor: unset;
}
.limitTable table td div:nth-child(2) {
  display: flex;
}
.limitTable table td:first-child {
  height: 10px;
  margin-top: 16px;
  display: flex;
  width: 40px;
}

.limitTable table .expandRow td:first-child img {
  transform: rotate(180deg);
  cursor: pointer;
}

.limitTable table th {
  align-items: center;
  color: #ffffffc2;
  display: flex;
  font-size: 14px;
  font-weight: 200;
  letter-spacing: 0;
  line-height: 16px;
}
.limitTable table th div:nth-child(2) {
  display: flex;
  flex-direction: column;
}
.limitTable table th div:nth-child(2) img:first-child {
  margin-bottom: 2px;
  transform: rotate(180deg);
}
.limitTable table th:first-child {
  align-items: center;
  display: flex;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 16px;
  width: 40px;
  min-width: 40px;
}
.limitTable table th:nth-child(2),
.limitTable table td:nth-child(2) {
  min-width: 60px;
  width: 1%;
}
.limitTable table th:nth-child(3),
.limitTable table td:nth-child(3) {
  min-width: 180px;
  width: 10%;
}
.limitTable table th:nth-child(4),
.limitTable table td:nth-child(4) {
  width: 15%;
  min-width: 191px;
}
.limitTable table td:nth-child(4) div {
  margin-left: 0px !important;
}
.limitTable table th:nth-child(5),
.limitTable table td:nth-child(5) {
  width: 23%;
  min-width: 191px;
}
.limitTable table th:nth-child(6),
.limitTable table td:nth-child(6) {
  width: 9%;
  min-width: 110px;
}
.limitTable table th:nth-child(7),
.limitTable table td:nth-child(7) {
  width: 9%;
  min-width: 170px;
}
.limitTable table th:nth-child(8),
.limitTable table td:nth-child(8) {
  width: 9%;
  min-width: 170px;
}
.limitTable table tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  height: 47px;
}

.limitTable table tbody tr:hover {
  background-color: rgba(216, 216, 216, 0.1);
}

.limitTable table tbody .expandRow tr:hover {
  background-color: unset;
}

.tbodyText {
  align-items: center;
  display: flex;
  height: 32px;
  margin-left: 7px;
  margin-top: 5px;
}

.tbodyText p {
  margin-top: 13px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tbodyDropdown div {
  align-items: center;
  background-color: #ffffff1f;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 32px;
  width: 90%;
  justify-content: space-between;
  margin-left: unset;
  margin-top: 5px;
  text-indent: 8px;
}
.tbodyDropdown img {
  margin-right: 12px;
}
.tbodyDropdown option {
  background-color: unset;
  border: unset;
  height: 100%;
  text-indent: 8px;
  width: 100%;
}
.tbodyDropdown select {
  background-color: unset;
  border: unset;
  height: 100%;
  text-indent: 8px;
  width: 100%;
}
.popoverContent {
  display: flex;
  flex-direction: column;
}
.popoverContent span {
  color: #ffffff82;
  cursor: pointer;
  padding: 3px;
}
.popoverContent span:hover {
  background-color: #ffffff3d;
}

.green {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #37c7a594;
  position: relative;
}

.green div {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #37c7a5;
}

.amber {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fca91bbd;
  position: relative;
}

.amber div {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #fcaa1b;
}

.red {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fc5d55a6;
  position: relative;
}

.red div {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #fc5d55;
}

.plusNumber {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  height: 25px;
  width: 25px;
  border: 1px solid #464646;
  border-radius: 50%;
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 14px;
  margin-left: 6px;
}

.colorsColum {
  display: flex;
  margin-top: 10px;
}

.expandRow {
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #031719 100%);
}
.colorText {
  height: 14px;
  width: 37px;
  color: #ffffffc9;
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 14px;
  margin-top: 6px;
  margin-left: 7px;
}

.tbodyNumber {
  margin-top: 14px;
}

.tbodyNumber p {
  margin-bottom: 18px;
}

.tbodyImageEdit {
  margin-top: 10px;
}

.wmTableSearch {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-sizing: border-box;
  color: #ffffff;
  height: 32px;
  margin-top: 25px;
  text-indent: 16px;
  width: 261px;
}
.wmTableSearch:focus-visible {
  outline: unset;
}

.disable {
  cursor: not-allowed !important;
}
