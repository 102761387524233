/* Berthing */
.highlightComponent.berthing {
  flex: 1 1 auto;
}

.cargoInformationSection {
  background: rgba(29, 29, 27, 0.4);
  border-radius: 8px;
  padding: 12px;
  margin-top: 20px;
  height: calc(100% - 40px);
}

.highlightComponent .highlightTitle span:first-child {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.222222px;
  color: #ffffff;
  mix-blend-mode: normal;
  opacity: 0.8;
}

.highlightComponent .highlightTitle span:last-child {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.138889px;
  color: rgba(255, 255, 255, 0.6);
  float: right;
}

.berthItem {
  color: rgba(255, 255, 255, 0.8);
  padding: 10px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.berthingContainer>div:last-child {
  border: none;
}

.berthName {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.22px;
  line-height: 24px;
}

.shipName {
  margin-left: 10px;
  font-weight: 300;
  font-size: 12px;
  opacity: 0.8;
  letter-spacing: 0.22px;
}

.transportInfo .colorDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #a8a8a8;
  display: inline-block;
}

.transportInfo .colorDot.active {
  background: #0b882e;
}

.transportInfo .transportMethod {
  font-weight: 300;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.4);
  margin: 0 5px;
}

.transportMethod span {
  font-size: 12px;
}

.transportDate {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Museo Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0.167px;
}

.transportCompany .flag {
  width: 15px;
  height: 16px;
  border-radius: 4px;
  margin-right: 10px;
  object-fit: cover;
}

.transportCompany.companyName {
  font-size: 14px;
  margin-left: 5px;
}