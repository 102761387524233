.uploadFileItem {
    display: flex;
    align-items: start;
    gap: 16px;
    padding: 16px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.10);
}

.uploadFileItem div p {
    font-size: 14px;
    font-weight: 300;
    color: #FFF;
    overflow-wrap: anywhere;
}

.uploadFileItem div span {
    color: rgba(163, 163, 163, 1);
    font-size: 12px;
    font-weight: 300;
}

.uploadFileItemContainer {
    margin: 16px 0;
    height: 196px;
}

.uploadFileItemName {
    flex-grow: 1;
}