.highlightComponent {
    flex: 1 1 auto;
    margin-top: 30px;
}

.highlightComponent .highlightTitle span:first-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.222222px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
}

.highlightComponent .highlightTitle span:last-child {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.138889px;
    color: rgba(255, 255, 255, 0.6);
    float: right;
}

.mapRightPanelDemandValue {
    background: rgba(29, 29, 27, 0.4);
    border-radius: 8px;
    margin-top: 20px;
    height: calc(100%);
}