.scorecardorderContainer {
    margin-top: -60px;
    min-height: 100vh;
    position: relative;
  }
  
  .scorecardorderRoot {
    /* min-width: 100vw; */
    padding-top: 60px;
    box-sizing: border-box;
    min-height: 100vh;
  }
  .scorecardorderPanelContainer {
    width: 100%;
    padding: 20px;
    border-radius: 4px;
    background: rgba(6, 25, 25, 0.5);
    /*margin-top: 100px;
    margin-left: 274px;*/
    margin: 70px 10px 10px 210px;
    max-width: 100vw;
  }
  
  .scorecardorderPanelContainer h2 {
    opacity: 0.8;
    color: #ffffff;
    font-family: "Museo Sans", sans-serif;
    font-size: 18px;
    letter-spacing: 0.34px;
    line-height: 22px;
    margin-top: 0;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
    width: 100%;
    padding-bottom: 8px;
  }
  
  .scorecardorderPanel {
    display: flex;
    width: 100%;
  }
  