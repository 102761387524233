.timeStamp {
    position: fixed;
    bottom: 40px;
    right: 40px;
    border-radius: 4px;
    background: var(--Black_gradient, linear-gradient(180deg, rgba(6, 25, 25, 0.33) 0%, rgba(0, 0, 0, 0.60) 100%));
    display: inline-flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
}

.timeStamp span {
    color: rgba(255, 255, 255, 0.60);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Museo Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */
    letter-spacing: 0.167px;
}

.timeStamp img {
    margin-right: 4px;
}