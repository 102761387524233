.modalHeader {
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
  padding-bottom: 20px;
}

.modalHeader p {
  margin: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
}

.modalBody {
  overflow-y: auto;
  overflow-x: hidden;
  height: 114vw;
}

.chartContainer {
  height: 300px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
  padding: 10px 0 0 0;
}

.datepickerSelector {
  position: relative;
}

/* .chartContainer span {
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.166667px;
    color: #FFFFFF;
    mix-blend-mode: normal;
    opacity: 0.8;
    margin-left: 1rem;
    padding-bottom: 1rem;
  }
  
  .chartContainer h1 {
    color: #ffffff;
    text-align: center;
    padding-top: 10rem;
    font-size: 14px;
  } */

.modalFooter {
  padding: 10px 0 0 0;
}

.modalFooter span {
  color: #ffffff;
  font-weight: 300;
  font-size: 12px;
}

.filterBlocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.filterBlock {
  border-radius: 4px;
  background: #000000;
  padding: 10px 5px 10px 15px;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.filterBlock span {
  color: #dddddd;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 100;
  padding-right: 10px;
  margin-right: -2px;
}

.filterBlockDropdown button {
  background-color: transparent;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: 100;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
}

.selectedCustomer {
    width: 100px;
    font-size: 18px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
    transition: max-width 0.3s ease;
    font-weight: 300 !important;
}

.filterBlockDropdown button i {
  font-size: 24px;
  color: #00a19c;
  margin-left: 8px;
  margin-right: -10px;
}

.filterBlockDropdownMenu,
.filterBlockDropdownCustomerMenu {
  position: absolute;
  border-radius: 0 0 3px 3px;
  top: 40px;

  right: 0;
  background: linear-gradient(
    180deg,
    rgb(34 40 42) 0%,
    rgba(0, 0, 0, 0.88) 100%
  );
  z-index: 9;
  transition: all ease-in-out 0.2s;
  height: 0;
  overflow: hidden;
}

.filterBlockDropdownMenu {
  width: 180px;
}

.filterBlockDropdownCustomerMenu {
  width: 236px;
}

.filterBlockDropdown.show .filterBlockDropdownMenu {
  height: auto;
  padding: 8px 0;
}

.filterBlockDropdown.show .filterBlockDropdownCustomerMenu {
  height: 282px;
  padding: 8px 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.filterBlockDropdownMenu ul,
.filterBlockDropdownCustomerMenu ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.filterBlockDropdownMenu ul li button,
.filterBlockDropdownCustomerMenu ul li button {
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0 20px;
  /* color: rgba(255, 255, 255, 0.3); */
  transition: all ease-in-out 0.2s;
  font-weight: 300;
  color: #ffffffc4;
}

.filterBlockDropdownMenu ul li button {
  justify-content: space-between;
}

.filterBlockDropdownCustomerMenu ul li button {
  justify-content: left;
}

.filterBlockDropdownMenu ul li button.selected,
.filterBlockDropdownCustomerMenu ul li button.selected {
  background-color: #004f4e;
  color: #fff;
}

.filterBlockDropdownMenu ul li button:hover,
.filterBlockDropdownCustomerMenu ul li button:hover {
  background-color: #004f4ed8;
  color: #ffffffc4;
}

.filterCountryCustomerBlock {
  display: flex;
  gap: 20px;
}

.filterPeriodUnitBlock {
  display: flex;
  gap: 20px;
}

.countrySelection {
  display: flex;
  flex-direction: row;
  height: 30px;
  align-items: center;
}

.countrySelection li {
  display: flex;
  flex-direction: row;
}

.countrySelection li p {
  color: #ffffff;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  padding: 10px;
}

/* CSS for Country/Customer selection */
.filterSelectionDropdownMenu {
  position: absolute;
  border-radius: 0 0 3px 3px;
  top: 40px;
  width: 210px;
  right: 0;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.85) 0%, #000000 100%);
  z-index: 9;
  transition: all ease-in-out 0.2s;
}

.filterBlockDropdown {
  border-left: 1px solid #dddddd69;
}

.filterBlockDropdown .filterSelectionDropdownMenu {
  height: 0;
}

.filterBlockDropdown.show .filterSelectionDropdownMenu {
  height: 282px;
  padding: 8px 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.filterSelectionDropdownMenu ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filterSelectionDropdownMenu ul li button:hover,
.filterSelectionDropdownMenu ul li button.selected {
  background-color: #004f4e;
  color: #fff;
}

.filterSelectionDropdownMenu ul li button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
  color: rgba(255, 255, 255, 0.3);
  transition: all ease-in-out 0.2s;
}

.filterSelectionDropdownMenu ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

/* CSS for Source selection */
.filterSourceSelectionDropdownMenu {
  position: absolute;
  border-radius: 0 0 3px 3px;
  top: 40px;
  width: 210px;
  right: 0;
  background: #ffffff;
  color: #000000;
  z-index: 9;
  transition: all ease-in-out 0.2s;
}

.sourceFilterBlock {
  border-radius: 4px;
  background: #ffffff;
  padding: 10px 5px 10px 15px;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.sourceFilterBlockDropdown .filterSourceSelectionDropdownMenu {
  height: 0;
}

.sourceFilterBlockDropdown.show .filterSourceSelectionDropdownMenu {
  height: 282px;
  padding: 8px 0;
  overflow-y: scroll;
  overflow-x: hidden;
}

.filterSourceSelectionDropdownMenu ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filterSourceSelectionDropdownMenu ul li button:hover,
.filterSourceSelectionDropdownMenu ul li button.selected {
  background-color: #004f4e;
  color: #fff;
}

.filterSourceSelectionDropdownMenu ul li button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 0 20px;
  color: rgba(255, 255, 255, 0.3);
  transition: all ease-in-out 0.2s;
}

.filterSourceSelectionDropdownMenu ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

/* Charts CSS */
.chartBody {
  margin-top: 10px;
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* gap: 10px; */
}

.chartBody.salesDelivered {
  display: flex;
}

.chartContainer {
  height: 445px;
  background: #1d1d1b66;
  border-radius: 8px;
}

.listContainer {
  height: 592px;
  background: #1d1d1b66;
  border-radius: 8px;
}

.lineChartContainer {
  width: 99%;
  height: 592px;
  background: #1d1d1b66;
  border-radius: 8px;
  position: absolute;
}

.chartHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
}

.chartHeader span {
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0.19px;
  padding-left: 5px;
}

.sourceChartHeader {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}

.sourceChartHeader .filterBlock {
  margin-right: 20px;
  width: 210px;
}

.customerComparison {
  width: 880px;
}

.salesChart {
  width: auto;
  height: 300px;
  margin-left: 20px;
  margin-right: 20px;
}

.lineChart {
  width: 95%;
  height: 470px;
  margin-left: 20px;
}

.chartNotAvailable {
  color: #ffffff;
  opacity: 0.4;
  background-image: linear-gradient(to bottom, #fff, transparent 1px),
    linear-gradient(#ccc 0px, transparent 0px);
  background-size: 20px 47px;
  margin-top: 8px;
  width: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 445px;
  color: white;
}
  
.noResult h2 {
  color: white;
  font-family: 'Museo Sans';
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.194px;
  text-align: left;
}

.legendwrapper {
  border: 1px dotted #c99;
  max-height: 50px;
}

#legendDiv {
  border: 1px dotted #c99;
  max-height: 50px;
}
