.modalHeader p {
  height: 17px;
  opacity: 0.8;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0.49px;
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modalHeader img {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  margin-top: -5px;
}

.modalContent p {
  margin-bottom: unset;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.166667px;
  color: #C2C2C2;
}


.modalContent div p {
  margin-top: 16px;
}

.modalContent input {
  cursor: pointer;
  box-sizing: border-box;
  height: 44px;
  width: 100%;
  border: unset;
  border-radius: 4px;
  background-color: #ffffff17;
  text-indent: 8px;
  color: #ffffff;
}

.modalContent input::placeholder {
  font-weight: 200
}

.modalContent textarea {
  box-sizing: border-box;
  height: 132px;
  width: 100%;
  border: unset;
  border-radius: 4px;
  background-color: #ffffff17;
  text-indent: 8px;
  color: #ffffff;
}

.modalContent textarea:disabled {
  box-sizing: border-box;
  height: 132px;
  width: 100%;
  /* border: unset; */
  border-radius: 4px;
  border: 2px solid #ffffff17;
  background-color: transparent;
  text-indent: 8px;
  color: #ffffff;
}

.modalContent textarea::placeholder {
  font-weight: 200
}



.modalContent img {
  margin-left: -25px;
}

.modalFooter {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modalFooterButton {
  cursor: pointer;
  height: 44px;
  width: 120px;
  border-radius: 8px;
  border: unset;
  color: #ffffff;
  background-color: rgba(0, 161, 156, 1);
}

.modalFooterDelButton {
  cursor: pointer;
  height: 44px;
  width: 120px;
  border-radius: 8px;
  border: unset;
  color: #ffffff;
  background-color: #FF4F4F;
  margin-right: 12px;
}

.modalFooterSwitch {
  border: rgba(0, 161, 156, 1);
}

.modalFooterSwitch .ant-switch-checked {
  background: rgba(0, 161, 156, 1);
}

.popoverContent {
  display: flex;
  flex-direction: column;
  width: 100% !important;
}

.popoverContent span {
  color: #ffffff82;
  padding: 3px;
  cursor: pointer;
}

.popoverContent span:hover {
  background-color: #ffffff3d;
}

.divider {
  border-bottom: solid 1px rgba(239, 239, 239, 0.12);
  width: 600px;
  margin-left: -24px;

}

.modalDropdownContainer {
  display: flex;
  justify-content: flex-start;
  column-gap: 20px;
}

.modalDropdownContainer div {
  width: 173.33px;
}

.modalFooter span {
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 18px;
  color: #C2C2C2;
  margin-right: 14px;
}

.headerInitial {
  padding-top: 10px;
  padding-left: 10px;
  width: 40px;
  height: 38.66px;
  background: #EDF1F8;
  border-radius: 50%;
  border: 1px solid #979797;
  color: rgba(103, 124, 145, 1) !important;
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 16px;
}

.modalEditHeader {
  padding-bottom: 20px;
  border-bottom: solid 1px rgba(239, 239, 239, 0.12);
  display: flex;
}

.headerUsersDetail {
  margin-top: 12px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.headerUsersDetail span:first-child {
  font-family: "Museo Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.222222px;
  color: #FFFFFF;
}

.headerUsersDetail span:nth-child(2) {
  font-family: "Museo Sans", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.194444px;
  color: #FFFFFF;
}

.modalDelIcon {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.modalDelHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.modalDelHeader span {
  font-family: "Museo Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  color: #ffffffcc;
}

.modalDelSubtxt {
  font-family: 'Museo Sans', sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.194444px;
  color: #ffffffcc;
  mix-blend-mode: normal;
  margin-top: 14px;
}

.modalDelButton {
  margin-top: 26px;
  display: flex;
  justify-content: space-between;
}

.modalDelFooterCancelButton {
  cursor: pointer;
  height: 44px;
  width: 120px;
  border-radius: 8px;
  border: 1px solid rgba(0, 161, 156, 1);
  color: #ffffff;
  background: unset;
}

.modalDelFooterDelButton {
  cursor: pointer;
  height: 44px;
  width: 120px;
  border-radius: 8px;
  border: unset;
  color: #ffffff;
  background-color: #FF4F4F;
}

.opuPopover,
.personaPopover,
.rolePopover {
  position: initial;
}