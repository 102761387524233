.callOutFooter {
    /* position: absolute;
    bottom: 0;
    width: 100%; */
    padding: 14px;
    background: rgba(45, 55, 80, 0.95) 50%;
    border-radius: 0 0 8px 8px;
    border-top: 1px solid rgba(217, 217, 217, 0.1);
}

.callOutGroupActionBtn {
    padding: 10px 14px;
}

.calloutActionBtn {
    background: rgba(0, 209, 202, 0.2);
    border-radius: 8px;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.calloutActionBtn img {
    margin-right: 5px;
    flex-grow: 1;
    max-width: 20px;
    max-height: 20px;
}

.calloutActionBtn span {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.138889px;
    color: #ffffff;
    flex-grow: 2;
}

.calloutActionBtn a,
.calloutActionBtn a:hover,
.calloutActionBtn a:active,
.calloutActionBtn a:focus {
    text-decoration: none;
    color: inherit;
}

.calloutActionBtn.disabled {
    opacity: 0.5;
    cursor: auto;
}

.sustainabilityCalloutActionBtn {
    background: rgba(0, 209, 202, 0.2);
    border-radius: 8px;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
}

.sustainabilityCalloutActionBtn img {
    margin-right: 5px;
    flex-grow: 1;
    max-width: 20px;
    max-height: 20px;
}

.sustainabilityCalloutActionBtn span {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.138889px;
    color: #ffffff;
}

.sustainabilityCalloutActionBtn a,
.sustainabilityCalloutActionBtn a:hover,
.sustainabilityCalloutActionBtn a:active,
.sustainabilityCalloutActionBtn a:focus {
    text-decoration: none;
    color: inherit;
}

.sustainabilityCalloutActionBtn.disabled {
    opacity: 0.5;
    cursor: auto;
}

.disabledButton {
    pointer-events: none;
}
