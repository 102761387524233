.scoreCardsRoot {
  /* min-width: 100vw; */
  /* padding-top: 60px; */
  box-sizing: border-box;
  min-height: 100vh;
  max-width: 100vw;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 140px
}

.buttonContainer span {
  color: #00a19c;
}


.scoreCardsContainer {
  /* margin-top: -60px; */
  min-height: 100vh;
  position: absolute;
  top: 60px;
}

.titleScoreCardLv2 div p {
  height: 42px;
  width: 188px;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 42px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: inline;
  margin-left: 4px;
  margin-right: 2px;
}

.titleScoreCardLv2 div span {
  height: 42px;
  width: 188px;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 42px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: 200;
}

.titleScoreCardLv2 div img {
  margin-bottom: 6px;
}

.titleScoreCardLv2 {
  color: #fff;
  height: 50px;
  margin-left: 20px;
  display: flex;
  align-items: center;
  max-width: fit-content;
  margin-top: 10px;
}

.titleScoreCardLv2 div {
  box-sizing: border-box;
  border-bottom: solid 1px rgba(255, 255, 255, 0.274);
  height: 100%;
  width: 90vw;
}

.filterBlocks {
  max-width: 100vw;
  display: flex;
  gap: 1px;
  padding-left: 2px;
  padding-right: 1px;
  margin-top: 1px;
}

.filterBlock {
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.374) 0%, rgba(0, 0, 0, 0.68) 100%);
  padding: 10px 2px 10px 12px;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.filterBlock>span {
  color: #dddddd;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 100;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px rgba(255, 255, 255, 0.3) solid;
}

.scoreCardsRightTab {
  width: 100vw;
  margin-top: 10px;
  height: 40px;
}

.scoreCardsRightTab ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  /* justify-content: space-between; */
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  column-gap: 2px;
}

.scoreCardsRightTab ul li {
  cursor: pointer;
  /* color: rgba(255, 255, 255, 0.6); */
  color: #ffff;
  padding: 12px 12px;
  height: 38px;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 16px;
  transition: all ease-in-out 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.6;
  background-color: #00A19C;
}

/* .scoreCardsRightTab ul li::after {
  position: absolute; 
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  content: '';
  width: 0;
  height: 4px;
  background-color: #00a19c; 
  transition: all ease-in-out 0.3s;
}  */

/* .scoreCardsRightTab ul li:hover::after,
.scoreCardsRightTab ul li.active::after {
  color: #ffff;
  opacity: 1;
  background-color: #00a19c;
  width: max-content;
  border-radius: 6px 6px 0 0;
  overflow: unset;
  white-space: nowrap;
  text-overflow: unset;
} */

.disableTabLi {
  pointer-events: none;
  opacity: 0.6;
}

.scoreCardsRightTab ul li:hover,
.scoreCardsRightTab ul li.active {
  /* color: rgba(255, 255, 255, 1); */
  color: #ffff;
  opacity: 1;
  background-color: #00a19c;
  /* width: max-content; */
  border-radius: 6px 6px 0 0;
  overflow: unset;
  white-space: nowrap;
  text-overflow: unset;
  height: 40px;
}

/* .scoreCardsRightTab ul li:hover::after,
.scoreCardsRightTab ul li.active::after {
  width: 100%;
} */

.scoreCardsRightTabContent {
  position: relative;
}

.scoreCardsRightTabContent p {
  margin-bottom: 16px;
  color: #d9d9d9;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 1;
  display: flex;
  align-items: flex-end;
}

.scoreCardsRightTabContent p img {
  margin-right: 6px;
  height: 16px;
}

.scoreCardsRightTabContent ::-webkit-scrollbar {
  display: none;
}

.scoreCardsRightTabContent ::-webkit-scrollbar-track {
  display: none;
}

.scoreCardsLeft {
  width: 100vw;
  margin-bottom: 10px;
  margin-top: 10px;
}

.scoreCardsLeft .scoreCardsSectionTitle {
  /* margin-bottom: 30px; */
}

.scoreCardsLeft>p {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 20px;
}

.scoreCardsLeftListViewFull {
  height: 400px;
  border-radius: 8px;
  padding: 20px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.209) 0%, #000000 100%);
  overflow: auto;
  border-bottom: solid 12px #020202;
  margin-left: 20px;
  margin-right: 20px;
  /* border-top: solid 12px #3A434B; */
}

.scoreCardsLeftListViewFull ul li div {
  color: #fff;
  font-weight: 500;
}

.scoreCardsLeftListViewFull ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.scoreCardsLeftListViewLess {
  height: 110px;
  border-radius: 8px;
  padding: 20px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.209) 0%, #000000 100%);
  overflow: auto;
  border-bottom: solid 12px #020202;
  margin-left: 20px;
  margin-right: 20px;
}

.scoreCardsLeftListViewLess ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.scoreCardsLeftListViewLess ul li div {
  color: #fff;
  font-weight: 500;
}

.scoreCardsLeft ::-webkit-scrollbar-thumb {
  width: 3px;
  opacity: 0.5;
  border-radius: 10px;
  background-color: rgba(0, 161, 156, 0.6);
  -webkit-box-shadow: unset;
  height: 63px;
}

.scoreCardsLeft ::-webkit-scrollbar {
  width: 3px;
  height: 63px;
}

.scoreCardsLeftList ul li div {
  color: #fff;
  font-weight: 500;
}

.scoreCardsLeft>div:nth-child(1) {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
}

.scoreCardsLeftList ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}

.scoreCardsSectionTitle {
  color: #fff;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 42px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: 500;
  margin-bottom: 6px;
  padding: 0 20px;
}

.viewSummary {
  box-sizing: border-box;
  height: 29px;
  width: 80px;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.17);
  opacity: 0.6;
  color: #FFFFFF;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 6px;
  margin-top: 6px;
}

.scoreCardsSectionTitleWithAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scoreCardsSectionTitle span {
  font-weight: 100;
}

.scoreCardsItems {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  width: 100vw;
  margin-top: 10px;
}

.scoreCardsItems .scoreCardsItem:nth-child(3n) {
  margin-right: 0;
}

.scoreCardsPagination {
  display: flex;
  width: 100vw;
  justify-content: flex-end;
  padding-right: 20px;
  position: absolute;
  top: -50px;
}

.scoreCardsPagination button {
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  opacity: 0.68;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.55) 0%, #000000 100%);
  color: #00a19c;
  font-size: 18px;
  cursor: pointer;
}

.scoreCardsPagination button:first-child {
  border-radius: 4px 0 0 4px;
  margin-right: 1px;
}

.scoreCardsPagination button:last-child {
  border-radius: 0 4px 4px 0;
}

.scoreCardsPagination button:disabled {
  opacity: 0.68;
  color: rgba(255, 255, 255, 0.3);
}

.miniCardsList {
  /* padding-left: 20px; */
  width: 100vw;
}

.miniCardsList2 {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  flex-direction: column;
  padding: 0 20px;
}

.miniCardItemBody {
  /* padding: 20px 17px 0 17px; */
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.209) 0%, rgba(0, 0, 0, 0.38) 100%);
  display: flex;
  justify-content: space-between;
  gap: 15px;
  width: 100vw;
  overflow-y: inherit;
}

.miniCardsWrap {
  display: flex;
  width: 100vw;
  overflow-y: scroll;
  gap: 18px;
  margin: 14px 0 0 17px;
}

.miniCardItem {
  margin-bottom: 30px;
  flex: 1;
  position: relative;
  overflow: hidden;
}

.miniCardsList ::-webkit-scrollbar-thumb {
  display: none;
}

.miniCardItemHeader {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 19px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding-bottom: 10px;
  padding-right: 20px;
  margin-bottom: 10px;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  position: relative;
}

/* Chart */

.backGroundChart {
  box-sizing: border-box;
  width: 100vw;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.209) 0%, rgba(0, 0, 0, 0.38) 100%);
  padding: 10.5px;
  border: 2px solid rgb(39 37 37 / 40%);
}

.backGroundChart ::-webkit-scrollbar-thumb {
  width: 2px;
  opacity: 0.5;
  border-radius: 10px;
  background-color: rgba(0, 161, 156, 0.6);
  -webkit-box-shadow: unset;
  /* display: none; */
}

.backGroundChart ::-webkit-scrollbar {
  width: 2px;
  display: block;
}

/* MiniCard lv2 */

.miniCardsListLevel2 {
  display: flex;
  gap: 20px;
  width: 100vw;
  padding-left: 20px;
  margin-bottom: 20px;
  overflow: scroll;
  margin-top: 20px;
}

.selectedMiniCardlv2 {
  width: 90vw;
  margin-left: 20px;
  margin-right: 20px;
  color: #ffff;
}

.selectedMiniCardlv2>div {
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.5) 0%, #222121ce 50%);
  /* color: #FFFFFF; */
  font-size: 18px;
  line-height: 22px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  letter-spacing: 0;
  font-family: "Museo Sans", sans-serif;
  height: 40px !important;
  padding-top: 4px !important;
  border-radius: 4px !important;
  /* opacity: 0.7; */
}

.iconDropdown {
  color: #00A19C;
}

/* div[aria-selected="true"]{
    background-color: #114948 !important;
    font-size: 18px;
    font-weight: 400 !important;
  }
  div[class="rc-virtual-list-holder-inner"]{
    padding-top: 10px !important;
    padding-bottom: 10px !important;

  }
  div[class="ant-select-item ant-select-item-option"]{
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    font-size: 18px !important;
    font-weight: 400 !important; */

/* } */