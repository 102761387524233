.userManagementContainer {
    margin-top: -60px;
    min-height: 100vh;
    position: relative;
}

.userManagementRoot {
    /* min-width: 100vw; */
    padding-top: 60px;
    box-sizing: border-box;
    min-height: 100vh;
}

.userManagementPanel {
    display: flex;
    width: 100vw;
    height: 100vh;
}

.userManagementPanelContainer {
    width: 100%;
    padding: 23px;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.55) 0%, #000000 100%);
    margin: 70px 10px 10px 210px;
    max-width: 100vw;
    overflow: auto;
}

.userManagementPanelContainer h2 {
    opacity: 0.8;
    color: #ffffff;
    font-family: "Museo Sans", sans-serif;
    font-size: 20px;
    letter-spacing: 0.34px;
    line-height: 22px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-top: 0;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 rgb(255 255 255 / 10%);
    padding-bottom: 12px;
}

.periodTab {
    margin: 10px 0 17px 0;
    display: flex;
    justify-content: space-between;
}

/* modal */

.uploadButton {
    padding: 10px 12px 10px 12px;
    border-radius: 8px;
    background-color: rgba(0, 161, 156, 1);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 1);
    outline: none !important;
    cursor: pointer;
    border: none
}

.modalDelIcon {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.modalFooter {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalFooterButton {
    cursor: pointer;
    height: 44px;
    width: 120px;
    border-radius: 8px;
    border: unset;
    color: #ffffff;
    background-color: rgba(0, 161, 156, 1);
}

.modalFooterDelButton {
    cursor: pointer;
    height: 44px;
    width: 120px;
    border-radius: 8px;
    border: unset;
    color: #ffffff;
    background-color: #FF4F4F;
    margin-right: 12px;
}

.modalFooterSwitch {
    border: rgba(0, 161, 156, 1);
}

.modalFooterSwitch .ant-switch-checked {
    background: rgba(0, 161, 156, 1);
}

.divider {
    border-bottom: solid 1px rgba(239, 239, 239, 0.12);
    width: 600px;
    margin-left: -24px;

}

.headerInitial {
    padding-top: 10px;
    padding-left: 10px;
    width: 40px;
    height: 38.66px;
    background: #EDF1F8;
    border-radius: 50%;
    border: 1px solid #979797;
    color: rgba(103, 124, 145, 1) !important;
    font-style: normal;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px;
}

.modalEditHeader {
    padding-bottom: 20px;
    border-bottom: solid 1px rgba(239, 239, 239, 0.12);
    display: flex;
}

.headerUsersDetail {
    margin-top: 12px;
    margin-left: 10px;
    display: flex;
    flex-direction: column;
}

.headerUsersDetail span:first-child {
    font-family: "Museo Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.222222px;
    color: #FFFFFF;
}

.headerUsersDetail span:nth-child(2) {
    font-family: "Museo Sans", sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.194444px;
    color: #FFFFFF;
}

.modalDelIcon {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.modalDelHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.modalDelHeader span {
    font-family: "Museo Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #ffffffcc;
}

.modalDelSubtxt {
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.194444px;
    color: #ffffffcc;
    mix-blend-mode: normal;
    margin-top: 14px;
}

.modalDelButton {
    margin-top: 26px;
    display: flex;
    justify-content: space-between;
}

.modalDelFooterCancelButton {
    cursor: pointer;
    height: 44px;
    width: 120px;
    border-radius: 8px;
    border: 1px solid rgba(0, 161, 156, 1);
    color: #ffffff;
    background: unset;
}

.modalDelFooterDelButton {
    cursor: pointer;
    height: 44px;
    width: 120px;
    border-radius: 8px;
    border: unset;
    color: #ffffff;
    background-color: #FF4F4F;
}

.opuPopover,
.personaPopover,
.rolePopover {
    position: initial;
}

.tbodyText {
    width: 100%;
}

.filterBlocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.filterBlock {
    position: relative;
}