.scorecardMenuHeader {
    color: rgba(255, 255, 255, 1);
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 8px;
}

.scorecardMenuHeader strong {
    font-size: 14px;
    font-weight: 600
}

.scoreCardItem {
    padding: 8px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    font-size: 12px;

}

.scoreCardItem h5 {
    font-size: 14px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 10px;
}

.scoreCardItem span {
    padding: 4px 8px 4px 8px;
    border-radius: 5px;
    gap: 12px;
    background-color: rgba(240, 249, 255, 0.1);
    font-weight: 700;
    font-weight: 12px;
}