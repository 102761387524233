.limitConfigContainer {
  margin-top: -60px;
  min-height: 100vh;
  position: relative;
}

.limitConfigRoot {
  /* min-width: 100vw; */
  padding-top: 60px;
  box-sizing: border-box;
  min-height: 100vh;
}
.limitConfigPanelContainer {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  background: rgba(6, 25, 25, 0.5);
  margin-top: 100px;
  margin-left: 274px;
  max-width: 80vw;
}

.limitConfigPanelContainer h2 {
  opacity: 0.8;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.34px;
  line-height: 22px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: 0;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
  width: 100%;
  box-shadow: inset 0 -1px 0 0 rgb(255 255 255 / 10%);
  padding-bottom: 8px;
}

.limitConfigPanel {
  display: flex;
  width: 95%;
}
