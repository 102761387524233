.header {
  padding-top: 12px;
  padding-left: 23px;
}

.header p {
  height: 22px;
  width: 159px;
  color: #ffffffcc;
  font-size: 18px;
  letter-spacing: 0.49px;
  line-height: 22px;
  margin-bottom: unset;
}

.divBorder {
  margin-top: 15px;

  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.filterBlocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.filterBlock {
  margin-right: 21px;
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1) !important;
  height: 40px;
  padding: 10px 5px 10px 15px;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.filterBlock span {
  color: #dddddd;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 100;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px rgba(255, 255, 255, 0.3) solid;
}

.filterBlockDropdown button {
  background-color: transparent;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: 100;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
}

.filterBlockDropdown button i {
  font-size: 24px;
  color: #00a19c;
  margin-left: 8px;
  margin-right: -10px;
}

.filterBlockDropdownMenu {
  position: absolute;
  border-radius: 0 0 3px 3px;
  top: 40px;
  width: 180px;
  right: 0;
  background-color: rgba(255, 255, 255, 0.1) !important;
  z-index: 9;
  transition: all ease-in-out 0.2s;
  height: 0;
  overflow: hidden;
}

.filterBlockDropdown.show .filterBlockDropdownMenu {
  height: auto;
  padding: 8px 0;
}

.filterBlockDropdownMenu ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.filterBlockDropdownMenu ul li button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: rgba(255, 255, 255, 0.3);
  transition: all ease-in-out 0.2s;
}

.filterBlockDropdownMenu ul li button:hover,
.filterBlockDropdownMenu ul li button.selected {
  background-color: #004f4e;
  color: #fff;
}

.periodBlock {
  display: flex;
  height: 80px;
  align-items: center;
  flex-direction: row-reverse;
}

.chartBlock {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  justify-content: center;
}

.chartContainer {
  box-sizing: border-box;
  height: 365px;
  width: 1001px;
  border: 1px dashed #464646;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgb(6 25 25 / 30%) 0%,
    #0000008c 100%
  ) !important;
}

.legendSection {
  display: flex;
  justify-content: space-between;
  margin-top: 21px;
}

.legendGroup {
  display: flex;
  cursor: pointer;
}

.legendGroup p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  letter-spacing: -0.17px;
  line-height: 14px;
  margin-right: 18px;
}

.legendBlock {
  display: flex;
}

.legendGroup div {
  margin-right: 10px;
}

.legend {
  height: 13px;
  width: 13px;
  border-radius: 2px;
}

.zoomBlock {
  margin-left: 12px;
  margin-top: -10px;
}

.lastUpdated {
  color: #d8d8d8;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 12px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.lastUpdated i {
  font-size: 20px;
  margin-left: 5px;
}

.lastUpdated img {
  margin-left: 10px;
}

.slideItem {
  background: linear-gradient(162.9deg, #396160 0%, #264746 100%, #244544 100%);
  border-radius: 13px;
}

.imgContainer {
  height: 400px;
  border-radius: 13px 13px 0 0;
}

.imgContainer img {
  width: 100%;
  height: 100%;
  border-radius: 13px 13px 0 0;
  object-fit: contain;
}

.slideContent {
  padding: 70px 20px 30px 20px;
  /* background: linear-gradient(162.9deg, #396160 0%, #264746 100%, #244544 100%); */
  text-align: left;
  border-radius: 0 0 13px 13px;
}

.slideContent .slideTitle {
  height: 29px;
  color: #FFFFFF;
  font-size: 28px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 29px;

}

.slideContent .slideDescription {
  color: white;
}

.crCarousel {
  position: relative;
}
