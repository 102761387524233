.filterBlocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.filterBlock {
    border-radius: 4px;
    background: linear-gradient(180deg,
            rgba(6, 25, 25, 0.374) 0%,
            rgba(0, 0, 0, 0.68) 100%);
    padding: 10px 5px 10px 15px;
    line-height: 1;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
}

.filterBlock>span {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 100;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px rgba(255, 255, 255, 0.3) solid;
}

.filterBlockDropdown button {
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 100;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
}

.filterBlockDropdown button i {
    font-size: 24px;
    color: #00a19c;
    margin-left: 8px;
    margin-right: -10px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 140px
}

.buttonContainer span {
    color: #00a19c;
}