@value colors: "../../../styles/common/colors.module.css";
@value blue,
yellow,
red,
green from colors;

.cardContainer {
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.372389) 0%, rgba(0, 0, 0, 0.68) 100%);
    border-radius: 8px;
    height: 160px;
    padding: 10px;
    margin-bottom: 20px;
}

@media (min-width: 992px) {
    .cardContainer {
        width: 350px;
    }
}

.cardInner {
    color: white;
    display: flex;
}

.cardPrimarySection {
    flex: 3;
    display: flex;
    gap: 10px;
}

.statusBar {
    width: 10px;
    height: 140px;
    background: #0B882E;
}

.red {
    background: #DA2228;
}

.green {
    background: #0B882E;
}

.yellow {
    background: #FDB924;
}

.defaultColor {
    background: #000000ad;
}

.primaryInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.headline {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
}

.primaryValue {
    display: flex;
    flex-direction: column;
}

.primaryValueCursor {
    display: flex;
    flex-direction: column;
}

.primaryValueCursor:hover {
    cursor: pointer;
}

.cardInnerCursor {
    color: white;
    display: flex;

}

.cardInnerCursor:hover {
    cursor: pointer;
}

.primaryNumber {
    font-weight: 500;
    font-size: 47px;
    line-height: 47px;
    align-items: flex-start;
}

.unit {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    opacity: 0.6;
    align-self: flex-start;
}

.unit span {
    margin-right: 2px;
}

.comparisonText {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    opacity: 0.6;
}

.comparisonNumber {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}

.cardSecondarySection {}

.subLabel {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    opacity: 0.6;
}

.subNumber,
.subNumber span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.subNumberWithArrow {
    display: flex;
    align-items: center;
    gap: 5px;
}

.subDirection {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    display: flex;
}

.popUpIcon {
    align-self: start;
    margin-left: 10px;
}

.popUpIcon:hover {
    cursor: pointer;
}

.ytdContainer {
    display: flex;
}

.ytdVariance {
    margin: 3px 0 4px 4px;
    line-height: 15px;
}

.ytdVariance span {
    font-family: Museo Sans;
    font-weight: 300;
    font-size: 10px;
    letter-spacing: 0.1388888955116272px;
    text-align: left;
}

.ytdVarianceArrow {
    height: 9px;
}

.varianceDown { color: #E6454D; }
.varianceUp { color: #00FF5D; }