.executiveSummaryList {
  list-style: none;
  padding-left: 0;
}

.executiveSummaryItemLevel2 {
  color: #b8b8b8;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 26px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.executiveSummaryItem {
  color: #b8b8b8;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 26px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 15px;
  margin-bottom: 15px;
}