.activeStatus div {
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  display: flex;
  height: 32px;
  margin-left: 8px;
  margin-top: 6px;
  width: 70px;
}

.activeStatus p {
  color: #00a19c;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 11px;
}

.grantBtn button {
  background-color: #00a19c;
  border: unset;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  height: 32px;
  margin-top: 6px;
  width: 80px;
}

.hide {
  display: none;
}

.inactiveStatus div {
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  display: flex;
  height: 32px;
  margin-left: 8px;
  margin-top: 6px;
  width: 84px;
}

.inactiveStatus p {
  color: #ffbe4f;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 11px;
}

.popoverContent {
  display: flex;
  flex-direction: column;
}

.popoverContent div {
  padding: 8px 0;
}

.popoverContent span:not(span > span) {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height, or 150% */

  letter-spacing: 0.194444px;
  color: #ffffff;
  cursor: pointer;
  padding: 6px;
}



.popoverContent div:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}

.revokeBtn button {
  background-color: #ff4f4f;
  border: unset;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  height: 32px;
  margin-top: 6px;
  width: 80px;
}

.rowHighlight {
  background: rgba(255, 255, 255, 0.15);
}

.tbodyDropdown div {
  align-items: center;
  background-color: #ffffff1f;
  border-radius: 4px;
  border-style: none;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin-left: 12px;
  margin-top: 5px;
  text-indent: 8px;
}

.tbodyDropdown img {
  margin-right: 12px;
}

.tbodyDropdown option {
  background-color: unset;
  border: unset;
  height: 100%;
  text-indent: 8px;
  width: 100%;
}

.tbodyDropdown select {
  background-color: unset;
  border: unset;
  height: 100%;
  text-indent: 8px;
  width: 100%;
}

.tbodyText {
  align-items: center;
  display: flex;
  height: 32px;
  margin-left: 12px;
  margin-top: 5px;
}

.tbodyTextEdit div {
  align-items: center;
  background-color: #ffffff1f;
  border-radius: 4px;
  border-style: none;
  display: flex;
  height: 32px;
  margin-left: 12px;
  margin-top: 5px;
}

.tbodyTextEdit input {
  background-color: unset;
  border: unset;
  height: 100%;
  text-indent: 8px;
  width: 100%;
}

.userTable {
  display: flex;
  flex-direction: column;
}

.userTable table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-x: scroll;
  width: 100%;
}

.userTable table img {
  margin-left: 14px;
}

.userTable table input[type="checkbox"] {
  appearance: none;
  border: 2px solid #00a19c;
  border-radius: 4px;
  box-sizing: border-box;
  height: 18px;
  outline: none;
  width: 18px;
}

.userTable table input[type="checkbox"]:checked::after {
  color: #00a19c;
  content: "x";
  margin-left: 3.5px;
  margin-top: -2px;
  position: absolute;
}

.userTable table td {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  width: 15%;
}

.userTable table td div:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.userTable table td:first-child {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: -2px;
  width: 64px;
}

.userTable table td:nth-child(2) {
  min-width: 191px;
  width: 191px;
}

.userTable table td:nth-child(3) {
  min-width: 230px;
  width: 230px;
}

.userTable table td:nth-child(4) {
  width: 191px;
}

.userTable table td:nth-child(5) {
  width: 191px;
}

.userTable table td:nth-child(6) {
  width: 191px;
}

.userTable table td:nth-child(7) {
  width: 191px;
}

.userTable table th {
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 14px;
}

.userTable table th div:nth-child(2) {
  display: flex;
  flex-direction: column;
}

.userTable table th div:nth-child(2) img:first-child {
  margin-bottom: 2px;
  transform: rotate(180deg);
}

.userTable table th:first-child {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 2px;
  width: 64px;
}

.userTable table th:nth-child(2) {
  min-width: 191px;
  width: 191px;
}

.userTable table th:nth-child(3) {
  min-width: 230px;
  width: 230px;
}

.userTable table th:nth-child(4) {
  width: 191px;
}

.userTable table th:nth-child(5) {
  width: 191px;
}

.userTable table th:nth-child(6) {
  width: 191px;
}

.userTable table th:nth-child(7) {
  width: 191px;
}

.userTable table th:nth-child(8) {
  width: 191px;
}

.userTable table tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  height: 47px;
}

.userTableSearch {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
}

.userTableSearch input {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-sizing: border-box;
  color: #ffffff;
  height: 32px;
  margin-top: 25px;
  text-indent: 16px;
  width: 200px;
}

.userTableSearch input:focus-visible {
  outline: unset;
}

.userTableSearchButton {
  background-color: #00a19c;
  border-color: #00a19c;
  border-radius: 4px;
  color: #ffffff;
  height: 40px;
  margin-left: 6px;
  width: 120px;
}

.indicatorGreenGlow {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #0B882E;
  border: 2px solid #60AE5C;
  margin-left: 12px;
  box-shadow: 0px 0px 6px 2px rgba(152, 199, 122, 0.4);
}

.indicatorGreenDim {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #60AE5C;
  margin-left: 12px;
}

.indicatorGrey {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #6F6F6F;
  margin-left: 12px;
}


.tableTextWrap {
  display: inline-flex;
  flex-direction: column;
}

.tableTextUserWrap {
  display: flex;
  flex-direction: column;
}

.tableText {
  font-family: "Museo Sans", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.194444px;
  color: #FFFFFF;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tableTextReason {
  font-family: "Museo Sans", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.194444px;
  color: #FFFFFF;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 600px;
}

.tableSubText {
  font-family: "Museo Sans", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.166667px;
  color: #FFFFFF99;
}

.tableStripe {
  background: rgba(255, 255, 255, 0.03);
  border-style: solid;
  border-radius: 5px;
  border-radius: 10px;
  border-width: 3px;
}


.tableStripe td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.tableStripe td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.tableHeaderInput {
  width: 400px;
  height: 44px;
  background: #e4e4e41a;
  border-radius: 4px;
  border: unset;
  color: #FFFFFF99;
  padding-left: 20px;
  background-repeat: no-repeat;
  background-position: right center;
  background-position-x: 360px;
}

.tableHeaderInput:focus {
  outline: none;
}

.tableHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableHeaderAddUser {
  border-style: solid;
  cursor: pointer;
  background: #00A19C;
  border-radius: 8px;
  width: 104px;
  height: 44px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  font-family: "Museo Sans", sans-serif;
  font-style: normal;
  margin-left: 12px;
}

.exportBtn {
  cursor: pointer;
  background: black;
  border-radius: 8px;
  width: 104px;
  height: 44px;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 400;
  font-family: "Museo Sans", sans-serif;
  font-style: normal;
  border: 1px solid #00A19C;
}

.editButton {
  cursor: pointer;
}

.searchAndFilter {
  display: flex;
  align-items: center;
  height: 44px;
}

.tbodyText {
  display: flex;
  padding-right: 8px;
  padding-left: 8px;
  height: 44px;
  width: 173px;
  border-radius: 4px;
  background-color: #ffffff26;
  justify-content: space-between;
  margin-top: 0
}

.tbodyText p {
  margin-top: 13px;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  font-weight: 300;
}

.lastActiveArrow {
  margin-left: 24px;
  display: inline-grid;
  flex-direction: column;
  align-items: center;
  line-height: 1;
  font-size: 11px;
}