.modalHeader p {
  height: 42px;
  width: 475px;
  color: #ffffff;
  font-weight: 200;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 42px;
  margin: unset;
}

.modalHeader {
  display: flex;
  color: #ffff;
  justify-content: space-between;
  border-bottom: solid 1px rgba(239, 239, 239, 0.12);
}

.modalHeader img {
  cursor: pointer;
}

.modalContentTitle {
  display: flex;
  margin-top: 20px;
}
  
.parentText {
  height: 14px;
  width: 73px;
  color: #d8d8d8;
  font-weight: 100;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 14px;
  margin-bottom: 10px;
  white-space: nowrap;
}

.childText {
  margin-bottom: unset;
  color: #d8d8d8;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: 0.5px;
  text-indent: 17px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 170px;
  overflow: hidden;
}

.modalContentTitle div div {
  display: flex;
  box-sizing: border-box;
  height: 49px;
  width: 175px;
  color: #d8d8d8;
  border: 1px solid #464646;
  border-radius: 4px;
  background: #031719bf;
  align-items: center;
  margin-right: 20px;
}
.modalContentTitle div:last-child div {
  display: flex;
  margin-right: unset;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.1);
  border: unset;
  width: 212px;
  cursor: pointer;
}

.modalContentTitle div:last-child div img {
  margin-right: 10px;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;
}

.modalFooter button {
  height: 40px;
  width: 120px;
  border-radius: 4px;
  color: #ffffff;
  border-color: #00a19c;
  background-color: #00a19c;
}

.popoverContent {
  display: flex;
  flex-direction: column;
}

.popoverContent span {
  color: #ffffff82;
  padding: 3px;
  cursor: pointer;
}

.popoverContent span:hover {
  background-color: #ffffff3d;
}

.modalBody {
  margin-top: 17px;
  background: #031719bf;
  align-items: center;
  margin-right: 20px;
  box-sizing: border-box;
  height: 385px;
  width: 601px;
  border: 1px solid #464646;
  border-radius: 4px;
}

.modalTable {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-top: 20px;
}

.modalTable tr {
  border-bottom: 1px solid rgba(151, 151, 151, 0.12);
}
.modalTable thead th div,
.modalTable tbody td > div {
  display: flex;
  width: 176px;
}

.modalTable tbody td:last-child > div {
  width: auto;
}

.modalBody tbody input {
  height: 47.6px;
  width: 156px;
  border-radius: 4px;
  color: #d8d8d8;
  border: unset;
  text-indent: 16px;
  background-color: rgba(255, 255, 255, 0.1);
}
.modalTable thead th p {
  height: 14px;
  color: #d8d8d8;
  font-weight: 100;
  font-size: 11px;
  letter-spacing: 0.5px;
  line-height: 14px;
}

.modalTable thead {
  display: flex;
}

.modalTable tbody tr {
  display: flex;
  align-items: center;
  height: 68px;
}

.modalTable tbody {
  height: 272px;
}

.addMoreColor > div {
  display: flex;
  margin-left: 21px;
  cursor: pointer;
}
.addMoreColor > div p {
  margin-top: 16px;
  margin-left: 5px;
  font-weight: 200;
  color: #d8d8d8;
  font-size: 13px;
  letter-spacing: 0;
}

.tbodyImgDelete {
  cursor: pointer;
}

.tbody {
  display: block;
  height: 272px;
  overflow: auto;
}
