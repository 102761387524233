.divider {
    border-bottom: solid 1px rgba(239, 239, 239, 0.12);
    width: 600px;
    margin-left: -24px;

}

.modalContent div p {
    margin-top: 16px;
}

.modalContent input {
    cursor: pointer;
    box-sizing: border-box;
    height: 44px;
    width: 100%;
    border: unset;
    border-radius: 4px;
    background-color: #ffffff17;
    text-indent: 8px;
    color: #ffffff;
}

.modalContent button {
    font-weight: 300;
    cursor: pointer;
    box-sizing: border-box;
    height: 44px;
    width: 100%;
    border: unset;
    border-radius: 4px;
    background-color: #ffffff17;
    text-indent: 8px;
    color: rgba(255, 255, 255, 1);
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modalContent input::placeholder {
    font-weight: 200
}

.modalContent img {
    margin-left: -25px;
}

.modalDropdownContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}

.modalDropdownContainer div {
    width: 100%;
}

.opuPopover,
.personaPopover,
.rolePopover {
    position: initial;
}

.filterBlocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.filterBlock {
    position: relative;
}

.errorMessage {
    display: inline-block;
    margin-top: 16px;
    font-size: 12px;
    color: rgba(255, 255, 255, 1);
    border-radius: 8px;
    padding: 6px 8px;
    background: rgba(218, 34, 40, 0.90);
    box-shadow: 0px 4px 10px 0px rgba(0, 65, 63, 0.60), 0px 4px 20px 0px rgba(0, 65, 63, 0.60);
}

.modalFooter span {
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 18px;
    color: #C2C2C2;
    margin-right: 14px;
}

.modalFooter {
    margin-top: 20px;
    display: flex;
    justify-content: right;
}

.uploadButton {
    padding: 10px 12px 10px 12px;
    border-radius: 8px;
    background-color: rgba(0, 161, 156, 1);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 1);
    outline: none !important;
    cursor: pointer;
    border: none
}

.disableUploadButton {
    pointer-events: none;
    background-color: grey;
}

.modalHeader p {
    height: 17px;
    font-weight: 700;
    opacity: 0.8;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0.49px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.modalHeader img {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    margin-top: -5px;
}

.modalHeader p {
    height: 17px;
    opacity: 0.8;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0.49px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.modalHeader img {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    margin-top: -5px;
}

/* upload */

.uploadContainer {
    margin-top: 20px;
    width: 100%;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.205) 0%, rgba(0, 0, 0, 0.315) 100%);
    z-index: 100000;
    height: 194px;
    border: 1px dashed rgba(151, 151, 151, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    opacity: none;
    cursor: pointer
}

.uploadContainer img {
    width: 40px;
    height: 40px;
}

.uploadContainer p {
    font-size: 14px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
    margin: 0;
}

.uploadContainer p strong {
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 209, 202, 1)
}

.uploadContainer span {
    color: rgba(163, 163, 163, 1);
    font-weight: 300;
    font-size: 12px
}

.modalContent p {
    margin-bottom: unset;
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.166667px;
    color: #C2C2C2;
}


.popoverContent {
    display: flex;
    flex-direction: column;
    width: 100% !important;
}

.popoverContent span {
    color: #ffffff82;
    padding: 3px;
    cursor: pointer;
}

.popoverContent span:hover {
    background-color: #ffffff3d;
}