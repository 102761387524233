.modalHeader {
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
  padding-bottom: 20px;
}

.modalHeader p {
  margin: 0;
  color: #ffffff;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
}

.modalBody {
  max-height: 70vh;
  display: block;
  overflow: auto;
}

.modalFooter {
  padding: 20px 0 0 0;
}

.modalFooter span {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  font-size: 12px;
}

.chartExpandIcon {
  border-radius: 4px;
  color: #dddddd;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.374) 0%, rgba(0, 0, 0, 0.68) 100%);
  padding: 15px 10px 15px 20px;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.detailsCard {
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.374) 0%, rgba(0, 0, 0, 0.68) 100%);
}

.detailsTab ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  column-gap: 10px;
  margin-bottom: 13px;
}

.detailsTab ul li {
  cursor: pointer;
  padding: 8px 20px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  letter-spacing: 0.2px;
  line-height: 24px;
  transition: all ease-in-out 0.3s;
  font-weight: 700;
  position: relative;
}

.detailsTab ul li::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  content: '';
  width: 0;
  height: 4px;
  background-color: #00a19c;
  transition: all ease-in-out 0.3s;
}

.detailsTab ul li:hover,
.detailsTab ul li.active {
  color: rgba(255, 255, 255, 1);
}

.detailsTab ul li:hover::after,
.detailsTab ul li.active::after {
  width: 100%;
}

.detailsContent {
  padding: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
  /* max-height: 90%; */
  /* overflow-y: auto; */
}

.detailsItem {
  height: 86px;
  background: rgba(29, 29, 27, 0.4);
  border-radius: 8px;
  display: flex;
  color: #FFFFFF;
  padding: 12px;
  gap: 60px;
  position: relative;
}

.detailsItem .parameterData,
.detailsItem .yepData,
.detailsItem .yetData {
  display: flex;
  flex-direction: column;
  justify-items: left;
  width: 20%;
}

.detailsItem .parameterData {
  width: 30%;
}

.detailsItem .parameterData .actual,
.detailsItem .parameterData .secondary,
.detailsItem .yepData .yebValue,
.detailsItem .yetData .yetValue{
  display: flex;
  align-items: center;
  gap: 4px;
}

.parameterData .parameterName {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #E5E5E5
}

.parameterData .actual .value {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.parameterData .actual .unit {
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
}

.parameterData .secondary .type {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: rgba(229, 229, 229, 0.8);
  letter-spacing: 0.14px;
}

.parameterData .secondary .value {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.parameterData .secondary .unit {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.yepData .yebValue .value,
.yetData .yetValue .value {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.yepData .yebValue .unit,
.yetData .yetValue .unit {
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
}

.yepData .label,
.yetData .label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.194444px;
  color: #E5E5E5;
  opacity: 0.6;
}

.popUpIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.popUpIcon:hover {
  cursor: pointer;
}