.modalContainer {
  margin-top: -60px;
  min-height: 100vh;
  position: relative;
}

.modalRoot {
  /* min-width: 100vw; */
  padding-top: 60px;
  box-sizing: border-box;
  min-height: 100vh;
}

.authAlertDetailText {
  padding-top: 20px;
  border-bottom: 1px solid #0000001a;
}

.authAlertDetailText b {
  font-size: 20px;
  line-height: 24px;
}

.authAlertDetailText p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
}

.authUserInfo {
  align-items: center; /*Ensure the name and email address are centered*/
  padding: 20px 0;
  border-bottom: 1px solid #0000001a;
  display: flex;
}

.authUserInfo img {
  border-radius: 50%;
  margin-right: 20px;
  height: 42px;
  width: 42px;
  border: 1px solid #fff
}

/*To handle display of the email address*/
.authUserInfo .userBasicInfo span{
  display: block;
  font-size: 14px;
  line-height: 12px;
}

.authUserInfo .userBasicInfo b {
  display: block;
  font-size: 16px;
  line-height: 14px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  margin-bottom: 6px;/*To separate between the name and email */
}

.authUserForm {
  padding-top: 20px
}

.authUserForm form {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
}

.authUserForm .authUserFormItem label {
  display: block;
}

.authUserForm .authUserFormItem:first-child {
  width: 50%;
  padding-right: 20px;
}

.authUserForm .authUserFormItem:nth-child(2) {
  width: 50%;
  padding-left: 20px;
}

.authUserForm .authUserFormItem:last-child {
  padding-top: 20px;
  width: 100%;
}

.authUserForm .authUserFormItem select {
  width: 100%;
  height: 44px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
}

.authUserForm .authUserFormItem option {
  font-size: 14px;
}

.authUserForm .authUserFormItem option:before {
  content: ">";
  font-size: 20px;
  display: none;
  padding-right: 10px;
  padding-left: 5px;
  color: #fff;
}

.authUserForm .authUserFormItem option:hover:before {
  display: inline;
}

.authUserForm .authUserFormItem textarea {
  width: 100%;
  height: 92px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
}

.submitBtn.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

/*To bring the non functional header to the front in access form.*/
.plainHeader{
  display: flex;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
}