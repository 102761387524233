.mapToggleMode {
  height: 60px;
  width: 100vw;
  position: fixed;
  top: 61px;
  left: 0;
  background: linear-gradient(180deg, rgba(5, 18, 18, 0.44) 0%, #000000 80%);
  box-shadow: 0 2px 4px 0 rgba(0, 255, 247, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.8;
  padding-left: 22.5px;
  padding-right: 18px;
  transition-duration: 1s;
  animation: appear 1s linear;
  z-index: 10;
}

.mapToggleMode div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mapToggleMode > div:nth-child(2) {
  box-sizing: border-box;
  height: 34px;
  width: 1px;
  border-right: 1px solid #979797;
  opacity: 0.5;
}

.mapToggleModeTablet {
  height: 60px;
  width: 40vw;
  position: fixed;
  top: 61px;
  right: 0;
  background: linear-gradient(180deg, rgba(5, 18, 18, 0.44) 0%, #000000 80%);
  box-shadow: 0 2px 4px 0 rgba(0, 255, 247, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.8;
  padding-left: 22.5px;
  padding-right: 18px;
  transition-duration: 1s;
  animation: appear 1s linear;
  z-index: 10;
}

.mapToggleModeTablet div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.mapToggleModeTablet > div:nth-child(2) {
  box-sizing: border-box;
  height: 34px;
  width: 1px;
  border-right: 1px solid #979797;
  opacity: 0.5;
}

.mapToggleTxt {
  height: 16px;
  width: 64px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.mapZoom {
  display: block;
  height: 312px;
  width: 50px;
  border-radius: 30px;
  background: linear-gradient(
    180deg,
    rgba(5, 18, 18, 0.55) 0%,
    rgba(0, 0, 0, 0.74) 100%
  );
  padding: 13px 11px;
  position: fixed;
  transform: scale(0.8);
  margin-right: 6px;
  margin-bottom: -10px;
}

.pageActionBottomRight {
  position: fixed;
  /* width: 100vw;
    height: 100vh; */
  bottom: calc(312px * 0.8 + 54px);
  right: calc(50px * 0.8 + 18px);
  /* align-items: flex-end;
    justify-content: flex-end; */
  transform-origin: top right;
  -webkit-backface-visibility: hidden;
  transition-duration: 1s;
  z-index: 9;
}

.closeMapzoomBySideBar {
  position: fixed;
  display: flex;
  bottom: calc(312px * 0.8 + 54px);
  right: calc(50px * 0.8 + 18px);
  transform-origin: top right;
  z-index: 9;
  -webkit-backface-visibility: hidden;
  transform: translate(100vw);
  transition-duration: 1s;
}

.closeMaptype {
  /* height: 60px;
    width: 100vw;
    position: fixed;
    top: 61px;
    left: 0;
    background: linear-gradient(180deg, rgba(5, 18, 18, 0.44) 0%, #000000 80%);
    box-shadow: 0 2px 4px 0 rgba(0, 255, 247, 0.08);
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0; */
  /* position: relative; */
  /* padding-left: 22.5px;
    padding-right: 18px;
    transition: opacity 1s; */
  /* display: none; */
  /* transform: scale(0);
    transition: opacity 1s linear; */
  display: none;
  animation: appear 1.5s ease-out;
}

/* .closeMaptype div:nth-child(3){
    display: flex;
    align-items: center;
    gap: 10px;
}
.closeMaptype > div:nth-child(2){
    box-sizing: border-box;
    height: 34px;
    width: 1px;
    border-right: 1px solid #979797;
    opacity: 0.5;
} */

.closeMapTypeBySideBar {
  height: 60px;
  width: 100vw;
  position: fixed;
  top: 61px;
  left: 0;
  background: linear-gradient(180deg, rgba(5, 18, 18, 0.44) 0%, #000000 80%);
  box-shadow: 0 2px 4px 0 rgba(0, 255, 247, 0.08);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0.8;
  padding-left: 22.5px;
  padding-right: 18px;
  transform: translate(100vw);
  transition-duration: 1s;
}

.closeMapTypeBySideBar div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 10px;
}

.closeMapTypeBySideBar > div:nth-child(2) {
  box-sizing: border-box;
  height: 34px;
  width: 1px;
  border-right: 1px solid #979797;
  opacity: 0.5;
}

/* Detail point */

.mapRightPanel {
  position: fixed;
  top: 60px;
  height: 710;
  right: -300vh;
  /* transition: all ease 1s; */
  width: 100%;
  border-radius: 8px;
  /* background-color: rgba(18, 64, 83, .5); */
  /* background-color: #124053; */
  background-color: #041c1f;
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
}

.mapRightPanelChart {
  width: 100% !important;
  height: 216px;
  margin-top: 8px;
  margin-left: -15px;
}

.mapRightPaneTitle {
  display: flex;
  height: 40px;
  border-radius: 8px 8px 0 0;
  background: #2a4044;
}

.mapRightPanel.mapRightPanelShow {
  left: 0;
  z-index: 998;
  top: 60px;
  width: 100vw;
  animation: appear 1s ease-out;
}

@keyframes appear {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.mapRightPanelBody {
  position: relative;
  max-height: 100vh;
}

.mobileModalTitle span {
  display: flex;
  height: 19px;
  width: 243px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-left: 20px;
  margin-top: 16px;
}

.mapRightPaneMobileTitle img {
  padding-top: 15px;
  padding-right: 14px;
}

.mapRightPaneMobileTitle {
  display: flex;
  justify-content: space-between;
}

.inputSearch {
  display: flex;
  margin-top: 16px;
  margin-left: 20px;
  width: 362px;
  margin-bottom: 16px;
}

.inputSearch span span {
  /* border: unset !important; */
}

.inputSearch span input {
  /* border: unset !important; */
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top-left-radius: unset !important;
  border-bottom-left-radius: unset !important;
}

.mobileModalExpandRowContainer {
  display: flex;
  background: rgba(255, 255, 255, 0) !important;
}

.mobileModalExpandRowContainer div:first-child > div {
  height: 14px;
  width: 100%;
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 19px;
  margin-top: 20px;
  margin-bottom: 12px;
  margin-right: 58px;
  line-height: 14px;
  background: rgba(255, 255, 255, 0) !important;
}

.mobileModalExpandRowContainer div:nth-child(2) > div {
  margin-left: 4px;
  margin-top: 14px;
  background: rgba(255, 255, 255, 0) !important;
}

.mobileModalExpandRowContainer div:last-child > div {
  height: 14px;
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 56px;
  margin-top: 20px;
  margin-bottom: 12px;
  line-height: 14px;
  background: rgba(255, 255, 255, 0) !important;
}

.mobileModalExpandRowContainer > div {
  background: rgba(255, 255, 255, 0) !important;
}

/* for mobile expand row for type 5 */
.mobileModalExpandRow5Container {
  display: flex;
  background: rgba(255, 255, 255, 0) !important;
}

.mobileModalExpandRow5Container div:first-child > div {
  height: 14px;
  width: 100%;
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 19px;
  margin-top: 20px;
  margin-bottom: 12px;
  margin-right: 58px;
  line-height: 14px;
  background: rgba(255, 255, 255, 0) !important;
}

.mobileModalExpandRow5Container div:nth-child(2) > div {
  margin-left: 4px;
  margin-top: 14px;
  background: rgba(255, 255, 255, 0) !important;
}

.mobileModalExpandRow5Container div:last-child > div {
  height: 14px;
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 0;
  margin-left: 15px;
  margin-top: 20px;
  margin-bottom: 12px;
  line-height: 14px;
  background: rgba(255, 255, 255, 0) !important;
}

.mobileModalExpandRow5Container > div {
  background: rgba(255, 255, 255, 0) !important;
}

.divBorder {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.locationTextModal {
  padding-left: 6px;
  display: flex;
  flex-direction: column;
  width: 100px;
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 17px;
}

.mapRightPanelTabContent {
  height: 100%;
  max-height: calc(83vh - 204px);
  margin-bottom: 10px;
  margin-top: 12px;
  color: #fff;
  overflow-y: scroll;
  overflow-x: hidden;
}

.mapRightPanelTabContent::-webkit-scrollbar {
  display: none;
}

.mapRightPanelTabContent .pointGroupInfoBodyText {
  align-items: flex-start;
}

.mapRightPanelTabContent .pointGroupInfoBodyText span {
  font-size: 18px;
  line-height: 22px;
  padding-top: 5px;
}

.mapRightPanelTabContent .pointGroupInfoBodyText b {
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
}
