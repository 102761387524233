body {
    background-color: #005564;
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Museo Sans", sans-serif;
}

.valueChainRoot {
    /* min-width: 100vw; */
    padding-top: 60px;
    box-sizing: border-box;
    min-height: 100vh;
}

.valueChainContainer {
    margin-top: -60px;
    min-height: 100vh;
    position: relative;
}

.progressTop {
    height: 60px;
    margin: 50px 20px 30px 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progressItem {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: center;
    position: relative;
    right: 187px;
}

/* .progressTop .progressItem:first-child{
    justify-content: flex-end;
}
.progressTop .progressItem:last-child{
    justify-content: flex-start;
} */

.progressItemIcon img {
    height: 110px;
}

.progressItemSeparator {
    margin-left: 10px;
    margin-right: -10px;
    border-bottom: 1px solid rgb(0 161 156 / 60%);
    /* opacity: 0.6; */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(-50% + 60px);
    width: 50%;
}

.progressItemText {
    position: absolute;
    top: 50%;
    left: calc(50% + 40px);
    transform: translateY(-50%);
    opacity: 0.8;
    color: #FFFFFF;
    font-size: 18px;
    letter-spacing: 0.34px;
    line-height: 22px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
}

.integrateLNG,
.integrateGAS {
    margin-left: 50px;
    margin-right: 20px;
    position: relative;
    padding: 20px;
    border-radius: 8px;
    background: linear-gradient(166.91deg, rgba(5, 18, 18, 0.374) 0%, rgba(0, 0, 0, .68) 100%);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
}

.integrateLNG h2,
.integrateGAS h2 {
    opacity: 0.8;
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 18px;
    letter-spacing: 0.34px;
    line-height: 22px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-top: 0;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 0;
}

.integrateLNGContainer {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    flex-wrap: wrap;
}

.integrateItem {
    margin-top: 20px;
    flex: 1;
    padding: 12px 20px;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.374) 0%, rgba(0, 0, 0, .68) 100%);
}

.integrateItemNA {
    display: flex;
    opacity: 0.6;
    color: #C6C6C6;
    font-size: 26px;
    letter-spacing: 1.04px;
    line-height: 31px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.integrateItemHeading {
    box-shadow: inset 0 -1px 0 0 rgba(255, 255, 255, 0.1);
    padding-bottom: 8px;
}

.integrateItemHeading b {
    color: #FFFFFF;
    font-size: 26px;
    letter-spacing: 1.04px;
    line-height: 31px;
    font-weight: 500;
}

.integrateItemHeading span {
    opacity: 0.6;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 0.61px;
    line-height: 19px;
    margin-left: 5px;
    font-weight: 300;
}

.integrateItemBody {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    column-gap: 20px;
    margin-top: 15px;
}

.flexStart {
    justify-content: flex-start !important;
}

.integrateItemValue {
    min-width: 20%;
    flex: 1;
}

.flexStart .integrateItemValue {
    min-width: calc((100% - 40px)/ 3);
    flex: initial;
}

.integrateItemValue>span,
.integrateItemValue>b,
.integrateItemValue>small {
    display: block;
}

.integrateItemValue span {
    opacity: 0.8;
    color: #FFFFFF;
    font-size: 12px;
    letter-spacing: 0.35px;
    line-height: 14px;
    font-weight: 300;
    white-space: nowrap;
}

.integrateItemValue b {
    font-size: 20px;
    letter-spacing: 0.58px;
    line-height: 24px;
    font-weight: 500;
    margin-top: 4px;
    margin-bottom: 1px;
}

.integrateItemValue small {
    opacity: 0.6;
    color: #FFFFFF;
    font-size: 14px;
    letter-spacing: 0.38px;
    line-height: 17px;
    font-weight: 300;
}

/* GAS */

.integrateGASContainer {
    display: flex;
    width: 100%;
}

.integrateGASLeft {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 18%;
    padding: 12px 20px;
    border-radius: 4px;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.374) 0%, rgba(0, 0, 0, .68) 100%);
    margin-top: 20px;
}

.integrateGASLeft b,
.integrateGASLeft small {
    display: block;
    text-align: center;
}

.integrateGASLeft b {
    font-size: 26px;
    letter-spacing: 1.04px;
    line-height: 31px;
    font-weight: 500;
}

.integrateGASLeft small {
    opacity: 0.6;
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 0.61px;
    line-height: 19px;
    font-weight: 300;
}

.integrateGASTitle {
    opacity: 0.8;
    color: #FFFFFF;
    font-size: 22px;
    letter-spacing: 0.5px;
    line-height: 27px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 0 50px;
}

.integrateGASLines {
    flex: 1;
}

.integrateGASLine {
    display: flex;
    align-items: center;
}

.integrateGASItems {
    display: flex;
    column-gap: 20px;
    width: 100%;
}

.OPUSelectionContainer {
    margin-left: 50px;
    margin-right: 20px;
}

@media(min-width:1238px) and (max-width:1365px) {
    .integrateGASLeft {
        width: 14%;
    }
}

@media(min-width:1238px) and (max-width:1450px) {
    .integrateGASLeft {
        width: 15%;
    }
}

@media(min-width:1451px) and (max-width:1600px) {
    .integrateGASLeft {
        width: 16%;
    }
}

@media(min-width:1601px) and (max-width:1750px) {
    .integrateGASLeft {
        width: 17%;
    }
}

/* 
@media(max-width: 1599px) {
    .integrateLNG, .integrateGAS, .progressTop {
        margin-left: 110px;
        margin-right: 12px;
        padding: 12px;
    }
    .integrateLNG h2, .integrateGAS h2 {
        font-size: 16px;
    }
    .integrateGASLeft {
        width: 200px;
    }
    .progressItemIcon img {
        height: 100px;
    }
    .progressItemSeparator {
    }
    .progressItemText {
        font-size: 16px;
    }
    .integrateItemValue b {
        font-size: 18px;
    }
    .integrateItemValue small {
        font-size: 10px;
    }
    .integrateItem {
        padding: 12px;
    }
    .integrateGASItems, .integrateLNGContainer {
        grid-column-gap: 12px;
        column-gap: 12px;
    }
    .integrateItem {
        margin-top: 12px;
    }
    .integrateItemBody {
        grid-column-gap: 8px;
        column-gap: 8px;
    }
    .integrateItemHeading b {
        line-height: 24px;
    }
    .integrateGASTitle {
        font-size: 16px;
        padding: 0 8px;
    }
}

@media(max-width: 1100px) {
    .integrateLNGContainer .integrateItem {
        width: calc(50% - 24px);
        flex: auto;
    }
    .integrateGASLeft {
        width: 100px;
    }
    .progressItemText {
        font-size: 13px;
        position: absolute;
        width: 100%;
        left: 50%;
        transform: translate(-50%,-50%);
        display: block;
        text-align: center;
        top: calc(100% - 10px);
    }
    .progressTop{
        margin-bottom: 50px;
    }
    .progressItemSeparator{
        transform: translate(-50%, -50%);
        right: -50%;
        width: 50%;
        margin-right: 0;
    }
}

@media(max-width:992px) {
    .integrateGASContainer {
        flex-wrap: wrap;
    }
   
}

@media(max-width: 767px) {
    .integrateLNGContainer .integrateItem {
        width: 100%;
        flex: auto;
    }
} */