.header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.header img{
    cursor: pointer;
}

.header p {
    color: #FFFFFF;
    letter-spacing: 1px;
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    margin-bottom: unset !important;
}

.divBorder {
    margin-top: 22px;
    margin-right: -24px;
    margin-left: -24px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.filterBlocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

.filterBlock {
    margin-right: 21px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1) !important;
    height: 40px;
    padding: 10px 5px 10px 15px;
    line-height: 1;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.filterBlock span {
    color: #dddddd;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 100;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px rgba(255, 255, 255, 0.3) solid;
}

.filterBlockDropdown button {
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 100;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
}

.filterBlockDropdown button i {
    font-size: 24px;
    color: #00a19c;
    margin-left: 8px;
    margin-right: -10px;

}

.filterBlockDropdownMenu {
    position: absolute;
    border-radius: 0 0 3px 3px;
    top: 40px;
    width: 180px;
    right: 0;
    background-color: rgba(255, 255, 255, 0.1) !important;
    z-index: 9;
    transition: all ease-in-out 0.2s;
    height: 0;
    overflow: hidden;
}

.filterBlockDropdown.show .filterBlockDropdownMenu {
    height: auto;
    padding: 8px 0;
}

.filterBlockDropdownMenu ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.filterBlockDropdownMenu ul li button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: rgba(255, 255, 255, 0.3);
    transition: all ease-in-out 0.2s;
}

.filterBlockDropdownMenu ul li button:hover,
.filterBlockDropdownMenu ul li button.selected {
    background-color: #004f4e;
    color: #fff;
}

.periodBlock {
    display: flex;
    height: 80px;
    align-items: center;
    flex-direction: row-reverse;
}

.chartBlock {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    justify-content: center;
}

.chartHeader {
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.222222px;
}

.chartContainer {
    box-sizing: border-box;
    height: 365px;
    width: 1001px;
    border-radius: 8px;
}

.legendSection {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    margin-bottom: 30px;
}

.legendGroup {
    display: flex;
    cursor: pointer;
}

.legendGroup p {
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    margin-right: 30px;
}

.legendALL {
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    ;
}

.legendBlock {
    display: flex;
    height: 20px;
}

.legendGroup div {
    margin-right: 10px;
}

.legend {
    width: 4px;
    height: 12px;
}

.zoomBlock {
    margin-left: 12px;
    margin-top: -10px;
}

.lastUpdated {
    color: #D8D8D8;
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 12px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.lastUpdated i {
    font-size: 20px;
    margin-left: 5px;
}

.lastUpdated img {
    margin-left: 10px;
}