.modalHeader {
	border-bottom: 1px solid #d9d9d91a;
	padding-bottom: 20px;
	margin: 0 -24px;
}

.modalHeader p {
	margin: 0;
	color: #ffffff;
	font-weight: 700;
	font-size: 20px;
	line-height: 30px;
	letter-spacing: 0.15px;
	margin: 0 24px;
}

.modalBody {
  max-height: 70vh;
  display: block;
	margin-top: 24px;
}

.cardContainer {
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.372389) 0%, rgba(0, 0, 0, 0.68) 100%);
    border-radius: 8px;
    height: 150px;
    padding: 10px;
    width: 245px;
}

.cardInner {
    color: white;
    display: flex;
}

.cardPrimarySection {
    display: flex;
    gap: 10px;
    width: 170px;
}

.statusBar {
    width: 10px;
    height: inherit;
    background: #0B882E;
}

.red {
    background: #DA2228;
}

.green {
    background: #0B882E;
}

.yellow {
    background: #FDB924;
}

.defaultColor {
    background: #000000ad;
}

.primaryInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}

.headline {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    height: 40px;
}

.primaryValue {
    display: flex;
    flex-direction: column;
}

.primaryValueCursor {
    display: flex;
    flex-direction: column;
}

.primaryValueCursor:hover {
    cursor: pointer;
}

.cardInnerCursor {
    color: white;
    display: flex;

}
.cardInnerCursor:hover {
    cursor: pointer;
}

.primaryNumber {
    font-weight: 500;
    font-size: 47px;
    line-height: 47px;
    align-items: flex-start;
}

.unit {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    opacity: 0.6;
    align-self: flex-start;
}

.unit span {
    margin-right: 2px;
}

.comparisonText {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    opacity: 0.6;
}

.comparisonNumber {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
}

.cardSecondarySection {
    /* flex: 1; */
    width: max-content;
}

.scoreCardsCol {
    width: inherit;
		margin-top: 24px;
}

.popUpIcon {
    align-self: start;
}

.popUpIcon:hover {
    cursor: pointer;
}

.subLabel {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    opacity: 0.6;
}

.subNumber,
.subNumber span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.subNumberWithArrow {
    display: flex;
    align-items: center;
    gap: 5px;
}

.subDirection {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    display: flex;
}

.subDirection span {
    width: max-content;
}

.noDataAvail {
    background-color: rgba(29, 29, 27, 0.4);
    width: 100%;
    height: 50px;
    border-radius: 8px;
    color: white;
    display: flex;
    align-items: center;
    padding-left: 18px;
}