.assuranceContainer {
	margin-top: -60px;
	min-height: 100vh;
	position: relative;
}

.assuranceRoot {
	padding-top: 60px;
	box-sizing: border-box;
	min-height: 100vh;
}

.assurancePanel {
  width: calc(100vw - 250px);
  max-width: calc(100vw - 240px);
  margin-left: 220px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.assurancePanelHeader {
  margin-top: 85px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.assurancePanelBody {
  margin-top: 30px;
}

.assurancePanelContent {
  display: flex;
  justify-content: space-between;
}

.threeLines {
  width: fit-content;
  height: 193px;
  padding: 16px 12px 16px 12px;
  gap: 10px;
  border-radius: 8px;
  background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
  text-align: left;
}

.threeLines > span {
  color: white;
  font-family: 'Museo Sans';
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0.25px;
}

.lineEntryContainer {
  margin-top: 10px;
}

.lineEntry {
  display: flex;
  height: 36px;
  padding: 20px;
  font-family: 'Museo Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.19px;
  color: white;
  opacity: 0.6;
  cursor: pointer;
  align-items: center;
}

.lineEntry.active {
   background: #FFFFFF1A;
   color: #00D1CA;
   border-radius: 8px;
}

.mapBreadcrumbItem {
  cursor: pointer;
  font-weight: 200;
}

.mapBreadcrumbItem.currentLocationBcItem {
  font-weight: 500;
}

.mapBreadcrumb {
  background: linear-gradient(180deg, rgba(5, 18, 18, 0.44) 0%, rgba(0, 0, 0, 0.80) 100%);
  mix-blend-mode: normal;
  border-radius: 6px;
  padding: 15px;
  height: 50px;
  width: fit-content;
  box-shadow: 8px -8px 16px 0px rgba(40, 239, 239, 0.10);
}

.mapBreadcrumb span,
.mapBreadcrumb a {
	font-family: Museo Sans;
  color: white !important;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.1944444477558136px;
	text-align: left;
}

.opuTab ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  /* justify-content: space-between; */
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  column-gap: 10px;
  margin-bottom: 13px;
}

.opuTab ul li {
  cursor: pointer;
  padding: 8px 20px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  transition: all ease-in-out 0.3s;
  font-weight: 500;
  position: relative;
}

.opuTab ul li::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  content: '';
  width: 0;
  height: 4px;
  background-color: #00a19c;
  transition: all ease-in-out 0.3s;
}

.opuTab ul li:hover,
.opuTab ul li.active {
  color: rgba(255, 255, 255, 1);
}

.opuTab ul li:hover::after,
.opuTab ul li.active::after {
  width: 100%;
}

.disableTabLi {
  pointer-events: none;
  opacity: 0.6;
}