.trendChart {
    width: 500px;
    height: 320px;
    margin-top: 20px;
}

.trendChartHide {
    margin-top: 20px;
    display: none;
}

.trendChartLeft {
    width: 100%;
    height: 300px;
    margin-top: 20px;
}

.trendChartRight {
    width: 100%;
    height: 300px;
    margin-top: 20px;
}


.emissionLabel {
    color: #9E94DB;
}

.reductionLabel {
    color: #00cfcb;
}

.tooltipHtml tr:nth-child(2) {
    background-color: unset !important;
}

.tooltipHtml p {
    margin-bottom: unset;
}

.chartBlock {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
    justify-content: center;
}

.chartHeader {
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 16px;
    line-height: 24px;
    color: #ffffffa6;
    letter-spacing: 0.222222px;
}

.chartContainer {
    box-sizing: border-box;
    height: 365px;
    width: 1001px;
    border-radius: 8px;
}

.legendSection {
    display: flex;
    justify-content: space-between;
    margin-top: 21px;
    margin-bottom: 30px;
}

.legendGroup {
    display: flex;
    cursor: pointer;
}

.legendGroup p {
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    margin-right: 30px;
}

.legendALL {
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 16px;
    margin-right: 25px;
    padding-right: 25px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    cursor: pointer;
}

.legendBlock {
    display: flex;
    height: 20px;
}

.legendGroup div {
    margin-right: 10px;
}

.legend {
    width: 4px;
    height: 12px;
}

.zoomBlock {
    margin-left: 12px;
    margin-top: -10px;
}

.lastUpdated {
    color: #D8D8D8;
    font-size: 13px;
    letter-spacing: 0.5px;
    line-height: 12px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.lastUpdated i {
    font-size: 20px;
    margin-left: 5px;
}

.lastUpdated img {
    margin-left: 10px;
}

.scrollbar {
    position: relative;
    width: 100%;
    height: 50px;
}

.chartContent {
    display: flex;
    justify-content: space-between;
}

.chartContentColumn {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.divBorder {
    margin-top: 55px;
    margin-right: -24px;
    margin-left: -24px;
    opacity: 0.68;
    border-left: 1px solid rgba(255, 255, 255, 0.05);
}

.displayNone {
    display: none;
}

.lastUpdatedDate{
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.138889px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: flex-end;
}