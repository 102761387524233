.body {
    color: white;
    padding: 0px;
    margin: 0px;
}

.pageContainer {
    padding: 0 30px
}

.valueChainRoot {
    /* min-width: 100vw; */
    padding-top: 60px;
    box-sizing: border-box;
    min-height: 100vh;
}

.valueChainContainer {
    margin-top: -60px;
    min-height: 100vh;
    position: relative;
}

.progressTop {
    height: 60px;
    margin: 15px 0px 30px 10%;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
}

.progressItem {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.progressItemIcon img {
    height: 110px;
}

.progressItemText {
    opacity: 0.8;
    color: #FFFFFF;
    font-size: 18px;
    letter-spacing: 0.34px;
    line-height: 22px;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
    transform: translateX(-15px);
    display: flex;
    align-items: end;
}

.progressItemSeparator {
    border-bottom: 1px solid rgb(0 161 156 / 60%);
    /* opacity: 0.6; */
    flex-grow: 1;
}

.popUpIcon {
    align-self: start;
    margin-left: 10px;
    width: 26px;
}

.popUpIcon:hover {
    cursor: pointer;
}

.plctablecontainer {
    clear: both;
    /* background: linear-gradient(180deg, rgba(5, 18, 18, 0.22) 0%, rgba(0, 0, 0, 0.40) 100%); */
    /* box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20); */
    position: relative;
    margin-bottom: 10px;
}


.plccontainerright {
    width: 240px;
    background: linear-gradient(180deg, rgba(5, 18, 18, 0.22) 0%, rgba(0, 0, 0, 0.40) 100%);
    border-radius: 8px;
}

.plccontainerright .plctablecontainer {
    padding: 1px 0px;
    /* height:500px; */
    height: 100%;
    /* max-height: 500px; */
    /* overflow-y: auto; */
}


#green {
    color: #00A19C;
}

.plctablerowsingle {
    /* background-color: slategrey; */
    margin: 16px 10px 0px;
    padding: 0px 10px 0px;
    height: 58px;
    position: relative;
    clear: both;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background-color: rgba(111, 111, 111, 0.4);
}

.plctablerowsinglelabel {
    float: left;
    width: 234px;
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    font-weight: 300;
}

.tableMain {
    display: flex;
    margin-top: 75px;
}

.tableContainer {
    background: linear-gradient(180deg, rgba(5, 18, 18, 0.22) 0%, rgba(0, 0, 0, 0.40) 100%);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.20);
    margin-right: 10px;
    border-radius: 8px;
}

.tableHeaderContainer {
    display: flex;
    align-items: center;
    padding: 0 30px 0 10px;
    width: 20px;
    height: 100%;
    background: var(--secondary-callout-background, linear-gradient(181deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%));
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.tableHeader {
    font-size: 18px;
    font-weight: 600;
    color: white;
    width: 25px;
    transform: rotate(-180deg);
    writing-mode: vertical-lr;
    -webkit-transform: rotate(-180deg);
    -webkit-writing-mode: vertical-lr;
}

.tableBody {
    padding: 16px 18px 16px 10px;
    width: 100%;
}

.headerRightCell,
.headerLeftCell {
    display: flex;
    /* position: relative; */
    justify-content: end;
    align-items: center;
    background: var(--neutral-neutral-black-50, #6F6F6F);
    color: var(--base-white, #FFF);
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.167px;
    /* padding: 4px 0 */
    padding: 4px 0px 4px 0px;
}

.headerShift {
    margin-bottom: 30px;
}


.headerContainer {
    display: flex;
    background: grey;
    width: 37%;
    margin-top: 30px;
    margin-left: 30.6%;
}

.headerRightCellTest,
.headerLeftCellTest {
    display: flex;
    justify-content: end;
    align-items: center;
    /* background: var(--neutral-neutral-black-50, #6F6F6F); */
    background: grey;
    color: var(--base-white, #FFF);
    font-size: 18px;
    font-style: normal;
    line-height: 23px;
    letter-spacing: 0.167px;
    padding: 4px 0
}

.headerContainer {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.gasFieldCellContainer,
.productionCell {
    background-color: rgba(111, 111, 111, 0.40);
}

.TotalCell {
    background: rgba(43, 43, 43, 0.5);
}

.headerRightCell,
.TotalRightCell,
.productionRightCell {
    padding-right: 40px
}

.headerRightCell {
    border-top-right-radius: 10px;
}

.headerLeftCell {
    border-top-left-radius: 10px;
}

.productionCell,
.TotalCell {
    display: flex;
    justify-content: end;
    align-items: center;
    width: 100%;
}

.productionCell {
    color: #FFFFFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.222px;
}

.TotalCell {
    color: #00A19C;
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    line-height: 41px;
    border-bottom: 1px solid #051212;

}

.cellBorder {
    border-bottom: 1px solid #051212;

}

.gasFieldCellContainer {
    display: flex;
}

.entryName {
    padding: 8px 4px;
    font-size: 14px;
    font-weight: 300;
    background: #00616D;
    color: var(--base-white, #FFF);
    text-align: center;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);
    -moz-transform: rotate(-180deg);
}

.gasFieldCellWrapper {
    display: flex;
    align-items: center;
    margin-left: 24px;
    width: 100%;

}

.gasFieldCell {
    color: var(--neutral-white, #FFF);
    /* text-align: center; */
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Museo Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.167px;
    padding: 16px 0px;
    text-align: start;
}

.tableFooterContainer {
    padding: 16px 16px 16px 0;
}

.tableFooter {
    height: 100%;
    border-radius: 4px;
    background: rgba(111, 111, 111, 0.40);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #00A19C;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
}

.tableFooter img {
    position: absolute;
    width: 5%;
    right: 1%;
    margin-right: 20px;
    margin-top: 20px;
    top: 1%;
}

.timeStampContainer {
    display: flex;
    justify-content: end;
    padding: 0 30px;
    margin-top: 40px;
}

.timeStamp {
    color: #FFF;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0.139px;
    display: flex;
    align-items: center;
}

.timestampHover {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: (255, 255, 255, 0.9);
    border: 1px solid black;
    top: 0;
    right: 0;
}