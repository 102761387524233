.pointGroup,
.pointGroupWrap {
    display: inline-block;
}

.pointGroupWrap {
    position: absolute;
    z-index: 2;
    line-height: 1px;
    transform: translate(-4.4px, -4.4px);
}

.pointGroupDot {
    height: 8.81px;
    width: 8.81px;
    background-color: #DDDDDD;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
}

.pointGroupDot span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px #DDDDDD solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #DDDDDD;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all ease-in-out .2s;
}

.pointGroupDot:hover span {
    opacity: 1;
}

.pointGroupDot::before,
.pointGroupDot::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.pointGroupDot::before {
    height: 22.4px;
    width: 22.17px;
    opacity: 0.5;
    background-color: #DDDDDD;
}

.pointGroupDot::after {
    height: 40px;
    width: 40px;
    opacity: 0.2;
    background-color: #DDDDDD;
}

.pointGroupWrap.green .pointGroupDot,
.pointGroupWrap.green .pointGroupDot::before,
.pointGroupWrap.green .pointGroupDot::after {
    background-color: #37C7A5;
}

.pointGroupWrap.green .pointGroupDot span {
    border: 1px #37C7A5 solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 10px #37C7A5;
}

.pointGroupWrap.red .pointGroupDot,
.pointGroupWrap.red .pointGroupDot::before,
.pointGroupWrap.red .pointGroupDot::after {
    background-color: #E75552;
}

.pointGroupWrap.red .pointGroupDot span {
    border: 1px #E75552 solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #E75552;
}

.pointGroupWrap.amber .pointGroupDot,
.pointGroupWrap.amber .pointGroupDot::before,
.pointGroupWrap.amber .pointGroupDot::after,
.pointGroupWrap.yellow .pointGroupDot,
.pointGroupWrap.yellow .pointGroupDot::before,
.pointGroupWrap.yellow .pointGroupDot::after {
    background-color: #FCAA1B;
}

.pointGroupWrap.amber .pointGroupDot span,
.pointGroupWrap.yellow .pointGroupDot span {
    border: 1px #FCAA1B solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #FCAA1B;
}

.pointGroupInfo {
    color: #FFFFFF;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    position: absolute;
    text-shadow: 2px 2px 4px #000000;
    display: flex;
    border-radius: 4px;
    background: var(--Black_gradient, linear-gradient(180deg, rgba(6, 25, 25, 0.33) 0%, rgba(0, 0, 0, 0.60) 100%));
    padding: 16px;
    gap: 12px;
}

.pointGroupInfoHover {
    top: -57px;
    left: -22px;
    position: absolute;
    padding-left: 52px;
    padding-bottom: 57px;
    display: none;
    opacity: 0;
    transition: all ease-in-out .2s;
    z-index: 998;
}

.pointGroupInfoHoverBody {
    height: 32px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.pointGroupInfoHoverBody button {
    width: 40px;
    color: #fff;
    text-decoration: none;
    transition: all ease-in-out .2s;
    font-size: 18px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 0;
    outline: none !important;
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    z-index: 999;
}

.pointGroupInfoHoverBody button::before {
    content: '';
    background-color: #fff;
    opacity: .4;
    height: 18px;
    width: 1px;
    top: 50%;
    right: 0;
    position: absolute;
    transform: translateY(-50%);
}

.pointGroupInfoHoverBody button:last-child::before {
    display: none;
}

.pointGroupInfoHoverBody button:hover {
    color: #37C7A5;
}

.pointGroupInfoHoverBody button img {
    width: 24px;
    height: 24px;
}

.pointGroupWrap:hover {
    z-index: 9;
}

.pointGroupDot:hover .pointGroupInfoHover {
    display: block;
    opacity: 1;
}

.pointGroupInfoBody {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.pointGroupInfoTop {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.pointGroupInfoBodyText {
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
}

.pointGroupInfoTop,
.pointGroupInfoBodyText div {
    align-items: flex-start;
    display: flex;
    margin-bottom: 3px;
}

.pointGroupInfoTop>span {
    color: #FFF;
    font-family: 'Museo Sans', sans-serif;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 3px;
    line-height: 27px;
    letter-spacing:0.25px;
}

.pointGroupInfoTop img {
    width: 10px;
    margin-left: 10px;
    display: none;
}

.pointGroupInfoBodyText b {
    color: #FFF;
    margin-top: 2px;
    margin-bottom: 2px;
    text-align: right;
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    line-height: normal;
    margin-right: 2px;
}

.pointGroupInfoBodyText span {
    color: #FFF;
    font-size: 12px;
    font-weight: 300;
}

.pointGroupWrap.left .pointGroupInfo {
    top: -15px;
    right: 35px;
}

.pointGroupWrap.right .pointGroupInfo {
    top: -18px;
    left: 35px;
}

.pointGroupWrap.top .pointGroupInfo,
.pointGroupWrap.topleft .pointGroupInfo {
    bottom: 25px;
    right: -12px;
}

.pointGroupWrap.topright .pointGroupInfo {
    bottom: 25px;
    left: -12px;
}

.pointGroupWrap.bottom .pointGroupInfo,
.pointGroupWrap.bottomleft .pointGroupInfo {
    top: 30px;
    right: -15px;
}

.pointGroupWrap.bottomright .pointGroupInfo {
    top: 30px;
    left: -12px;
}

.pointGroupWrap.left .pointGroupInfoTop,
.pointGroupWrap.left .pointGroupInfoBodyText,
.pointGroupWrap.top .pointGroupInfoTop,
.pointGroupWrap.top .pointGroupInfoBody,
.pointGroupWrap.topleft .pointGroupInfoTop,
.pointGroupWrap.topleft .pointGroupInfoBody,
.pointGroupWrap.bottom .pointGroupInfoTop,
.pointGroupWrap.bottom .pointGroupInfoBody,
.pointGroupWrap.bottomleft .pointGroupInfoTop,
.pointGroupWrap.bottomleft .pointGroupInfoBody {
    justify-content: flex-end;
}

.pointGroupContent {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    width: 100%;
}

.pointGroupContent .label {
    color: #E5E5E5;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.194px;
    opacity: 0.6;
}

.pointGroupInfoBodyFooterText span {
    color: rgba(229, 229, 229, 0.80);
    font-size: 10px;
    margin-top: 2px;
    font-weight: 300;
    line-height: 15px;
    letter-spacing: 0.139px;
}

.pointGroupInfoBodyFooterText .unit {
    color: #FFF;
    font-size: 12px;
    font-weight: 300;
}

.chartContainer {
    width: 62px;
    height: 62px;
}

/* progressbar */

.progressBar {
    width: 100%;
    height: 24px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    margin-bottom: 12px;
}

.planed {
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FDB924;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.planedLNG {
    background-color: #00FF5D;
}

.percentNumber {
    text-shadow: none;
    color: #000000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.173px;
}

.separateLine {
    width: 2px;
    height: 24px;
    background-color: #00A19C;
}

.yepCustomize {
    height: 16px;
    position: relative;
    background: #5B5D5D;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.yepCustomize span {
    position: absolute;
    top: calc(100% + 2px);
    left: calc(100% - 4px);
    text-shadow: none;
    color: #FFF;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.115px;
}

.yep {
    height: 16px;
    position: relative;
    background: #5B5D5D;
}

.yep span {
    position: absolute;
    top: calc(100% + 2px);
    left: calc(100% - 4px);
    text-shadow: none;
    color: #FFF;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.115px;
}

.yet {
    height: 16px;
    position: relative;
    background-color: #C2C2C2;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.yet span {
    position: absolute;
    text-shadow: none;
    color: #FFF;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: 0.115px;
    bottom: calc(100% + 2px);
    left: 30%
}

.subText {
  font-family: 'Museo Sans', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: none;
}