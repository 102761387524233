.popoverContent {
  display: flex;
  flex-direction: column;
}
.popoverContent p {
  color: #ffffff82;
  cursor: pointer;
  margin-bottom: unset;
  width: 100px;
  padding-left: 10px;
}

.activePop {
  background-color: #00a19c91;
}