.pointGroup,
.pointGroupWrap {
    display: inline-block;
}

.pointGroupWrap {
    position: absolute;
    z-index: 2;
    line-height: 1px;
    transform: translate(-4.4px, -4.4px);
}

.pointGroupDot {
    height: 8.81px;
    width: 8.81px;
    background-color: #DDDDDD;
    position: relative;
    border-radius: 50%;
    cursor: pointer;
}

.pointGroupDot span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px #DDDDDD solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #DDDDDD;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all ease-in-out .2s;
}

.pointGroupDot:hover span {
    opacity: 1;
}

.pointGroupDot::before,
.pointGroupDot::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}

.pointGroupDot::before {
    height: 22.4px;
    width: 22.17px;
    opacity: 0.5;
    background-color: #DDDDDD;
}

.pointGroupWrap.green .pointGroupDot,
.pointGroupWrap.green .pointGroupDot::before,
.pointGroupWrap.green .pointGroupDot::after {
    background-color: #37C7A5;
}

.pointGroupWrap.green .pointGroupDot span {
    border: 1px #37C7A5 solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 10px #37C7A5;
}

.pointGroupWrap.red .pointGroupDot,
.pointGroupWrap.red .pointGroupDot::before,
.pointGroupWrap.red .pointGroupDot::after {
    background-color: #E75552;
}

.pointGroupWrap.red .pointGroupDot span {
    border: 1px #E75552 solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #E75552;
}

.pointGroupWrap.amber .pointGroupDot,
.pointGroupWrap.amber .pointGroupDot::before,
.pointGroupWrap.amber .pointGroupDot::after,
.pointGroupWrap.yellow .pointGroupDot,
.pointGroupWrap.yellow .pointGroupDot::before,
.pointGroupWrap.yellow .pointGroupDot::after {
    background-color: #FCAA1B;
}

.pointGroupWrap.amber .pointGroupDot span,
.pointGroupWrap.yellow .pointGroupDot span {
    border: 1px #FCAA1B solid;
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0), 1px 0 20px #FCAA1B;
}

.pointGroupInfo {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 17px;
    position: absolute;
    text-shadow: 2px 2px 4px #000000;
}

.pointGroupInfoHover {
    top: -57px;
    left: -22px;
    position: absolute;
    padding-left: 52px;
    padding-bottom: 57px;
    display: none;
    opacity: 0;
    transition: all ease-in-out .2s;
    z-index: 998;
}

.pointGroupInfoHoverBody {
    height: 32px;
    border-radius: 2px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 30%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

.pointGroupInfoHoverBody button {
    width: 40px;
    color: #fff;
    text-decoration: none;
    transition: all ease-in-out .2s;
    font-size: 18px;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 0;
    outline: none !important;
    box-shadow: none;
    padding: 0;
    background-color: transparent;
    cursor: pointer;
    z-index: 999;
}

.pointGroupInfoHoverBody button::before {
    content: '';
    background-color: #fff;
    opacity: .4;
    height: 18px;
    width: 1px;
    top: 50%;
    right: 0;
    position: absolute;
    transform: translateY(-50%);
}

.pointGroupInfoHoverBody button:last-child::before {
    display: none;
}

.pointGroupInfoHoverBody button:hover {
    color: #37C7A5;
}

.pointGroupInfoHoverBody button img {
    width: 24px;
    height: 24px;
}

.pointGroupWrap:hover {
    z-index: 9;
}

.pointGroupDot:hover .pointGroupInfoHover {
    display: block;
    opacity: 1;
}

.pointGroupInfoBody {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.pointGroupInfoTop {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}

.pointGroupInfoBodyText {
    align-items: flex-start;
    display: flex;
    white-space: nowrap;
}

.pointGroupInfoTop,
.pointGroupInfoBodyText div {
    align-items: flex-start;
    display: flex;
    margin-bottom: 3px;
}

.pointGroupInfoTop span {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 700;
    margin-bottom: 5px;
}

.pointGroupInfoTop img {
    width: 10px;
    /* margin-left: 10px; */
    display: none;
}

.pointGroupInfoBodyText b {
    font-weight: 400;
    font-size: 16px;
    margin-right: 5px;
    line-height: 17px;
}

.pointGroupInfoBodyText span {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    letter-spacing: 0;
    line-height: 17px;
    margin-top: 5px;
    /* margin-right: 10px; */
    font-weight: 300;
    font-size: 12px;
}

.pointGroupInfoBodyText img {
    width: 22px;
    height: 22px;
    display: inline-flex;
    padding: 2px;
    /* margin-left: -5px; */
    flex-direction: column;
    align-items: flex-start;
}

.pointGroupWrap.left .pointGroupInfo {
    top: -15px;
    right: 35px;
}

/* GT */
.pointGroupWrap.right .pointGroupInfo {
    top: -18px;
    left: 35px;
}

.pointGroupWrap.top .pointGroupInfo,
.pointGroupWrap.topleft .pointGroupInfo {
    bottom: 25px;
    right: -12px;
}

.pointGroupWrap.topright .pointGroupInfo {
    bottom: 25px;
    left: -12px;
}

/* RGTSU */
.pointGroupWrap.bottom .pointGroupInfo,
.pointGroupWrap.bottomleft .pointGroupInfo {
    top: -15px;
    left: 35px;
}

.pointGroupWrap.bottomright .pointGroupInfo {
    top: 30px;
    left: -12px;
}

.pointGroupWrap.left .pointGroupInfoTop,
.pointGroupWrap.left .pointGroupInfoBodyText,
.pointGroupWrap.top .pointGroupInfoTop,
.pointGroupWrap.top .pointGroupInfoBody,
.pointGroupWrap.topleft .pointGroupInfoTop,
.pointGroupWrap.topleft .pointGroupInfoBody,
.pointGroupWrap.bottom .pointGroupInfoTop,
.pointGroupWrap.bottom .pointGroupInfoBody,
.pointGroupWrap.bottomleft .pointGroupInfoTop,
.pointGroupWrap.bottomleft .pointGroupInfoBody {
    justify-content: flex-end;
}

.pointGroupContent {
    display: flex;
}

.pointGroupContent span {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    letter-spacing: 0;
    line-height: 8px;
    margin-top: 5px;
    font-weight: 100;
    font-size: 12px;
}

.alignRight {
    align-items: flex-end;
}

.piIcon {
    margin-left: 4px;
}