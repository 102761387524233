.riskModal {
  width: 500px !important;
  /* max-width: 500px;
  padding: 16px; */
}

.riskModal>div{
  background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(3px);
  border-radius: 8px;
  position: fixed;
  right: 6px;
  top: 61px;
  width: 550px;
  height: calc(100vh - 60px);
  overflow-y: scroll;
}

.riskModalContent {
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  font-family: 'Museo Sans';
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
  color: white;
}

.separatorLine {
  height: 1px;
  background-color: #D9D9D91A;
  margin: 0 -24px 20px;
}

.closeButtonContainer {
  cursor: pointer;
}

.section {
  background: #1D1D1B66;
  width: Fill (452px);
  border-radius: 8px;
  padding: 10px;
}

.section h3 {
  font-family: 'Museo Sans';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.22px;
  text-align: left;
  color: white;
}

.sectionContainer {
  border-radius: 8px;
  background: #22343E;
  padding: 8px;
  display: block;
}

.sectionContainer:first-of-type {
  margin-bottom: 10px;
}

.sectionContainer span {
  margin-top: 8px;
  border-radius: 4px;
  color: white;
  font-family: 'Museo Sans';
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.19px;
  text-align: left;
}


.sectionTitle,
.sectionContainer > span {
  display: block;
}

.sectionTitle {
  font-weight: 600;
  color: #fff;
  display: inline-block;
  background-color: #00A19C33;
  padding: 2px 16px 2px 16px;
  border-radius: 100px;
  font-family: 'Museo Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.167px;
  text-align: left;
}

.tabs {
  display: flex;
  border-bottom: 1px solid #D9D9D91A;
  margin-bottom: 16px;
}

.tab {
  padding: 8px 16px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.167px;
  text-align: center;
  color: rgba(255, 255, 255, 0.288);
  font-family: 'Museo Sans';
}

.tab.active {
  font-weight: bold;
  border-bottom: 3px solid #00A19C;
  color: white;
}

.tabContent {
  padding: 10px 10px 2px;
  border-radius: 8px;
  background: #1D1D1B66;
}

.mainContainer h3 {
  font-family: 'Museo Sans';
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.22px;
  text-align: left;
  color: white;
  margin-bottom: 8px;
}

.mitigationItem {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 8px;
  font-size: 14px;
  background: #FFFFFF0D;
}

.mitigationItem > span {
  display: block;
}

.indicatorsContainer {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.indicatorItem {
  display: flex;
  align-items: center;
  font-family: 'Museo Sans';
  font-size: 12px;
}

.infoIconContainer {
  align-items: center;
}

.infoIcon {
  cursor: pointer;
}

.infoIcon img {
  width: 14px;
  height: 14px;
}

.KRItooltip {
  display: none;
  position: absolute;
  right: 0;
  transform: translateX(-5%);
  transform: translateY(5%);
  padding: 10px;
  z-index: 2;
  white-space: nowrap;
}

.infoIconContainer:hover .KRItooltip {
  display: block;
  padding: 12px;
  gap: 8px;
  border-radius: 4px;
  box-shadow: 0px 4px 4px 0px #00000080;
  background: #182034;
  border: none;
}

.otherIndicator {
  height: 15px;
  margin-top: 2px;
  gap: 4px;
}

.indicatorType {
  color: #ffffffa2;
  font-family: 'Museo Sans';
  font-size: 11px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0.14px;
  text-align: left;
}

.indicatorValue {
  margin-left: 7px;
  font-family: 'Museo Sans';
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.14px;
  text-align: left;
  color: #FFFFFF;
}


.completed { background-color: #00A19C4D; }
.onTrack { background-color: #3685D34D; } 
.delayed { background-color: #FEE9C6; }
.notStarted { background-color: #D6D6D6; }

.completedCircle { background: #00A19C; }
.onTrackCircle { background: #3685D3; } 
.delayedCircle { background: #E29E20; }
.notStartedCircle { background: #3D3D3D; }

.base { background: #0B882E; }
.warning { background: #c28d1a; }
.critical { background: #a31116; }

.baseCircle { background: #00FF5D; }
.warningCircle { background: #FDB924; }
.criticalCircle { background: #DA2228; }


.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  flex-shrink: 0;
  flex-grow: 0;
}

.programTitle, .programContent {
  margin-bottom: 4px;
}

.ownerContainer {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  color: white;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.194px;
  text-align: left;
  font-family: 'Museo Sans';
}

.ownerLabel {
  color: #C2C2C2;
  margin-right: 3px;
  font-family: 'Museo Sans';
}

.ownerList {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.ownerLine {
  position: relative;
  padding-left: 15px;
}

.ownerLine::before {
  content: '•';
  position: absolute;
  left: 4px;
  color: #C2C2C2;
}

.mitigationStat{
  height: 26px;
  padding: 4px 8px 4px 8px;
  border-radius: 8px;
  display: flex;
  margin-right: 5px;
  width: fit-content;
  align-items: center;
}

.mitigationStat > span {
  color:white;
  font-family: 'Museo Sans';
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.167px;
  text-align: left;

}

.mitigationNew {
  font-weight: bold;
  font-family: 'Museo Sans';
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.194px;
  color: white;
  margin: 14px 0;
}

.mitigationDesc {
  border-radius: 4px;
  color: #C2C2C2;
  font-family: 'Museo Sans';
  font-size: 12px;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0.194px;
  text-align: left;
  white-space: pre-wrap;
}

.empty {
  color: white;
  margin-bottom: 10px;
}

.disabled::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(75, 75, 75, 0.171); 
  pointer-events: none; 
  border-radius: 8px;
}

.enabled {
  outline: 1px solid rgba(255, 255, 255, 0.651);
}

.disabled {
  position: relative;
  opacity: 0.3;
}

.dueDateContainer {
  text-align: right;
}

.dueDate {
  display: inline;
  position: relative;
  color: #FFFFFF;
  font-family: 'Museo sans';
}

.pointer {
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 130%;
  font-family: 'Museo sans';
  z-index: 1000;
  display: none;
  font-size: 12px;
  transform: translateX(-20%);
  background: #182034;
  color: #FFFFFF;
  padding: 5px 10px;
  border-radius: 6px;
  pointer-events: none;
  width: 220px;
  text-align: center;
}

.dueDate:hover .tooltip {
  display: block;
}

.mitigationHeadercontainer {
  margin-top: 14px;
}