.userManagementPanel {
  display: flex;
  width: 95%;
}

.userManagementPanelContainer {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.55) 0%, #000000 100%);
  margin: 100px 0 20px 274px;
  max-width: 80vw;
}

.userManagementPanelContainer h2 {
  opacity: 0.8;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.34px;
  line-height: 22px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: 0;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0;
  width: 100%;
  padding-bottom: 8px;
}


.activeStatus div {
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  display: flex;
  height: 32px;
  margin-left: 8px;
  margin-top: 6px;
  width: 70px;
}
.activeStatus p {
  color: #00a19c;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 11px;
}
.grantBtn button {
  background-color: #00a19c;
  border: unset;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  height: 32px;
  margin-top: 6px;
  width: 80px;
}
.hide {
  display: none;
}
.inactiveStatus div {
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  display: flex;
  height: 32px;
  margin-left: 8px;
  margin-top: 6px;
  width: 84px;
}
.inactiveStatus p {
  color: #ffbe4f;
  font-size: 14px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 10px;
  margin-top: 11px;
}
.popoverContent {
  display: flex;
  flex-direction: column;
}
.popoverContent span {
  color: #ffffff82;
  cursor: pointer;
  padding: 3px;
}
.popoverContent span:hover {
  background-color: #ffffff3d;
}
.revokeBtn button {
  background-color: #ff4f4f;
  border: unset;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  height: 32px;
  margin-top: 6px;
  width: 80px;
}
.rowUnPublish {
  color: rgb(216, 214, 214, 0.4)!important
}
.tbodyDropdown div {
  align-items: center;
  background-color: #ffffff1f;
  border-radius: 4px;
  border-style: none;
  cursor: pointer;
  display: flex;
  height: 32px;
  justify-content: space-between;
  margin-left: 12px;
  margin-top: 5px;
  text-indent: 8px;
}
.tbodyDropdown img {
  margin-right: 12px;
}
.tbodyDropdown option {
  background-color: unset;
  border: unset;
  height: 100%;
  text-indent: 8px;
  width: 100%;
}
.tbodyDropdown select {
  background-color: unset;
  border: unset;
  height: 100%;
  text-indent: 8px;
  width: 100%;
}
.tbodyText {
  align-items: center;
  display: flex;
  height: 32px;
  margin-left: 12px;
  margin-top: 5px;
}
.tbodyTextEdit div {
  align-items: center;
  background-color: #ffffff1f;
  border-radius: 4px;
  border-style: none;
  display: flex;
  height: 32px;
  margin-left: 12px;
  margin-top: 5px;
}
.tbodyTextEdit input {
  background-color: unset;
  border: unset;
  height: 100%;
  text-indent: 8px;
  width: 100%;
}
.userTable {
  display: flex;
  flex-direction: column;
}
.userTable table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  overflow-x: scroll;
  width: 100%;
  min-height: 750px;
}
.userTable table img {
  margin-left: 14px;
}
.userTable table input[type='checkbox'] {
  appearance: none;
  border: 2px solid #00a19c;
  border-radius: 4px;
  box-sizing: border-box;
  height: 18px;
  outline: none;
  width: 18px;
}
.userTable table input[type='checkbox']:checked::after {
  color: #00a19c;
  content: 'x';
  margin-left: 3.5px;
  margin-top: -2px;
  position: absolute;
}
.userTable table td {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  width: 15%;
}
.userTable table td div:nth-child(2) {
  display: flex;
  flex-direction: column;
}
.userTable table td:first-child {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: -2px;
  width: 64px;
}
.userTable table td:nth-child(2) {
  min-width: 191px;
  width: 191px;
}
.userTable table td:nth-child(3) {
  min-width: 230px;
  width: 230px;
}
.userTable table td:nth-child(4) {
  width: 191px;
}
.userTable table td:nth-child(5) {
  width: 191px;
}
.userTable table td:nth-child(6) {
  width: 191px;
}
.userTable table td:nth-child(7) {
  width: 191px;
}
.userTable table th {
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 14px;
}
.userTable table th div:nth-child(2) {
  display: flex;
  flex-direction: column;
}
.userTable table th div:nth-child(2) img:first-child {
  margin-bottom: 2px;
  transform: rotate(180deg);
}
.userTable table th:first-child {
  align-items: center;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  justify-content: center;
  letter-spacing: 0;
  line-height: 16px;
  padding-left: 2px;
  width: 64px;
}
.userTable table th:nth-child(2) {
  min-width: 191px;
  width: 191px;
}
.userTable table th:nth-child(3) {
  min-width: 230px;
  width: 230px;
}
.userTable table th:nth-child(4) {
  width: 191px;
}
.userTable table th:nth-child(5) {
  width: 191px;
}
.userTable table th:nth-child(6) {
  width: 191px;
}
.userTable table th:nth-child(7) {
  width: 191px;
}
.userTable table th:nth-child(8) {
  width: 191px;
}
.userTable table tr {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  height: 47px;
  justify-content: space-between;
}
.userTableSearch {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.userTableSearch input {
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-sizing: border-box;
  color: #ffffff;
  height: 32px;
  margin-top: 25px;
  text-indent: 16px;
  width: 200px;
}
.userTableSearch input:focus-visible {
  outline: unset;
}
.userTableSearchButton {
  position: absolute;
  right: 0;
  top: -30px;
  background-color: #00a19c;
  /*border-color: #00a19c;*/
  border-radius: 4px;
  border-style: none;
  color: #ffffff;
  height: 40px;
  margin-left: 6px;
  width: 120px;
  cursor: pointer;
}

.switchBtn button {
  margin: 10px;
  border: 1px solid #00a19c;
}

.switchOnBtn button {
  background-color: #00a19c;
}

.switchOffBtn button span {
  color: #00a19c;
}

.groupBtn {
  padding: 10px;
}

.groupBtn img {
  cursor: pointer;
}

.noDataMessage{
  padding: 50px 0;
}

.noDataMessage img {
  display: block;
  margin: 0 auto !important;
  width: 30%;
}

.noDataMessage p {
  text-align: center;
  color: rgba(255,255,255,0.58);
  margin-bottom: 0;
}

.lastRow td:first-child {
  display: revert !important;
  padding-left: 2px;
}
