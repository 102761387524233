.miniCardItemInforLevel2 {
    padding: 16px 17px 14px 17px;
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.38) 100%
    );
    gap: 15px;
    text-align: left;
    /* min-width: 110px; */
    cursor: pointer;
  }
  /* body .miniCardItemInforLevel2 ::-webkit-scrollbar{
    display: none;
  }
  body .miniCardItemInforLevel2 ::-webkit-scrollbar-track{
    display: none;
  } */