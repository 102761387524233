.green {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #37c7a594;
  position: relative;
}

.green div {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #37c7a5;
}

.amber {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fca91bbd;
  position: relative;
}

.amber div {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #fcaa1b;
}

.red {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fc5d55a6;
  position: relative;
}

.red div {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  position: absolute;
  top: 4px;
  left: 4px;
  background-color: #fc5d55;
}

.colorsColum {
  display: flex;
}

.colorText {
  color: #ffffffc9;
  font-size: 12px;
  letter-spacing: 0.33px;
  font-weight: 100;
  line-height: 14px;
  margin-top: 6px;
  margin-left: 7px;
}

.dotBox {
  display: flex;
  justify-content: space-between;
  height: 48px;
  width: 156px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  align-items: center;
  cursor: pointer;
}

.dotBox .colorsColum {
  margin-left: 7px;
}

.dotBox img {
  margin-right: 10px;
}

.popoverContent {
  display: flex;
  flex-direction: column;
}
.popoverContent span {
  color: #ffffff82;
  cursor: pointer;
}
.popoverContent span:hover {
  background-color: #ffffff3d;
}