.container {
  position: fixed;
  top: 60px;
  bottom: 10px;
  right: -340px;
  transition: all ease-in-out .5s;
  width: 550px;
  border-radius: 8px;
  background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
}

.sustainabilityCalloutContainer {
  position: fixed;
  top: 60px;
  bottom: 10px;
  right: -700px;
  transition: all ease-in-out .5s;
  width: 550px;
  border-radius: 8px;
  background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
}

.container.compact {
  bottom: auto;
}

.container.compact .callOutBody div:first-child{
  margin-bottom: 0;
}

.container.show,
.sustainabilityCalloutContainer.show {
  right: 0px;
  z-index: 998;
  top: 60px;
}

.calloutContent {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Callout Header */

.headerTitle {
  display: flex;
  padding: 10px 0 10px 7px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
}

.pointTitle {
  font-weight: 700;
  padding: 10px;
}

.pointTitle span {
  font-family: 'Museo Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #ffffff;
}

.callOutCloseBtn{
  margin-top: 5px;
  height: 32px;
  width: 42px;
}

/* Callout Body */

.callOutBody {
  overflow-y: scroll; /*to avoid overflow of callout*/
  padding: 16px 14px 12px 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.callOutContainer.compact .callOutBody {
  height: auto;
}

.calloutLoadingIconContainer {
  min-height: 75vh;
}

.callOutCloseBtn {
  background-color: transparent !important;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  border: 0;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none !important;
  box-shadow: none !important;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.callOutCloseBtn i {
  font-size: 14px;
  line-height: 1;
}

.callOutCloseBtn:hover {
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
}

.sustainabilityCallOutBody {
  overflow-y: scroll; /*to avoid overflow of callout*/
  padding: 16px 14px 12px 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filterBlock {
  border-radius: 4px;
  background: #000000;
  padding: 12px;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.filterBlockDropdown button {
  background-color: transparent;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16.8px;
  font-weight: 300;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
}

.filterBlockDropdown button i {
  font-size: 24px;
  color: #00a19c;
  margin-left: 8px;
  margin-right: -10px;
}

.filterBlockDropdownMenu {
  position: absolute;
  border-radius: 0 0 3px 3px;
  top: 40px;
  width: 214.2px;
  left: 0;
  background: linear-gradient(180deg,
          rgb(34 40 42) 0%,
          rgba(0, 0, 0, 0.88) 100%);
  z-index: 9;
  transition: all ease-in-out 0.2s;
  height: 0;
  overflow: hidden;
}

.filterBlockDropdown.show .filterBlockDropdownMenu {
  height: auto;
  padding: 8px 0;
}

.filterBlockDropdownMenu ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.filterBlockDropdownMenu ul li button {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  color: #FFFFFF;
  transition: all ease-in-out 0.2s;
}

.filterBlockDropdownMenu ul li button:hover,
.filterBlockDropdownMenu ul li button.selected {
  background-color: #004f4e;
  color: #fff;
}