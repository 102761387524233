.modalHeader {
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
  padding-bottom: 20px;
  margin: 0 -24px;
}

.modalHeader p {
  margin: 0 24px;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin: 0 24px;
}

.modalBody {
  max-height: 635px;
  overflow-y: auto;
  overflow-x: hidden;
}

.chartContainer {
  height: auto;
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
  padding: 10px 0 0 0;
}

.chartContainer span {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.166667px;
  color: #FFFFFF;
  mix-blend-mode: normal;
  opacity: 0.8;
  margin-left: 1rem;
  padding-bottom: 1rem;
}

.chartContainer h1 {
  color: #ffffff;
  text-align: center;
  padding-top: 10rem;
  font-size: 14px;
}

.modalFooter {
  padding: 10px 0 0 0;
}

.modalFooter span {
  color: #FFFFFF;
  font-weight: 300;
  font-size: 12px;
}

.detailsTab ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  /* justify-content: space-between; */
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  column-gap: 10px;
  margin-bottom: 13px;
}

.detailsTab ul li {
  cursor: pointer;
  padding: 8px 8px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
  transition: all ease-in-out 0.3s;
  font-weight: 700;
  position: relative;
}

.detailsTab ul li::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  content: '';
  width: 0;
  height: 4px;
  background-color: #00a19c;
  transition: all ease-in-out 0.3s;
}

.detailsTab ul li:hover,
.detailsTab ul li.active {
  color: rgba(255, 255, 255, 1);
}

.detailsTab ul li:hover::after,
.detailsTab ul li.active::after {
  width: 100%;
}

.detailsTab .switchUnit {
  margin-left: 5px;
}

.detailsContent {
  /* padding: 20px 0 0 0; */
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 100vh;
  max-width: inherit;
  overflow-y: auto;
  overflow-x: hidden;
}

.listItem ul {
  margin-bottom: 0;
  padding-left: 1rem;
}

.detailsItem {
  height: inherit;
  background: rgba(29, 29, 27, 0.4);
  border-radius: 8px;
  color: #FFFFFF;
  padding: 12px;
  position: relative;
}

.detailsItemFlex {
  display: flex;
  gap: 30px;
}

.detailsItem h3 {
  font-family: Museo Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.1944444477558136px;
  text-align: left;
  color: #E5E5E5;
  text-transform: capitalize;
  max-width: 100%;
}

.detailsItem .parameterData {
  display: flex;
  flex-direction: column;
  justify-items: left;
  width: 30%;
}

.detailsItem .parameterData .actual,
.detailsItem .parameterData .secondary {
  display: flex;
  align-items: center;
  gap: 4px;
}

.parameterData .parameterName {
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #E5E5E5
}

.parameterData .label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #E5E5E5
}

.parameterData .actual .value {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

.parameterData .actual .unit {
  font-weight: 300;
  font-size: 12px;
  line-height: 17px;
  color: #E5E5E5
}

.parameterData .secondary .type {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: #929A9D
}

.parameterData .secondary .value {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
}

.parameterData .secondary .value.grey {
  color: #c2c2c2
}

.parameterData .secondary .value.red {
  color: #da2228
}

.parameterData .secondary .value.yellow {
  color: #f1c31b
}

.parameterData .secondary .value.green {
  color: #0b882e
}

.parameterData .secondary .unit {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
}

.popUpIcon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.popUpIcon:hover {
  cursor: pointer;
}

.parameterData .colorDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 4px;
  display: inline-block;
}

.parameterData .colorDot.grey {
  background-color: #c2c2c2;
}

.parameterData .colorDot.red {
  background-color: #da2228;
}

.parameterData .colorDot.yellow {
  background-color: #f1c31b;
}

.parameterData .colorDot.green {
  background-color: #0b882e;
}

.executiveSummary {
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  color: #B8B8B8;
}