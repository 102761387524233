.mapBreadcrumb {
    position: fixed;
    top: 150px;
    left: 2%;
    height: 510px;
    display: flex;
    flex-flow: column wrap;
  }
  
  .mapBreadcrumbRight {
    position: fixed;
    top: 150px;
    left: 2%;
    height: 530px;
    display: flex;
    flex-flow: column wrap;
  }

  h3 {
    word-wrap: break-word;
    max-width: 250px;
    white-space: normal;
  }
  
  .mapBreadcrumbLeft {
    position: fixed;
    top: 150px;
    right: 2%;
    height: 660px;
    display: flex;
    flex-flow: column wrap;
  }

  .mapBreadcrumbLeft
  .equipmentItemContainer {
    margin-right: 0;
  }

  .mapBreadcrumbBottom {
    position: fixed;
    bottom: 80px;
    left: 2%;
    display: flex;
    flex-flow: column wrap;
  }

  .jumpPFLNGContainer {
    position: fixed;
    top: 90px;
    right: 2%;
    z-index: 10;
  }

  .jumpPFLNG {
    display: flex;
    cursor: pointer;
    align-items: center;
    background: linear-gradient(180deg, rgba(5, 18, 18, 0.547631) -18.82%, #000000 141.09%);
    border-radius: 6px;
    padding: 15px;
    height: 50px;
    box-shadow: 0px 2px 4px 0px #00FFF715;

    color: #00D1CA;
    font-family: Museo Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.19px;
    text-align: left;
  }

  .equipmentItemContainer {
    background: linear-gradient(
      180deg,
      rgba(5, 18, 18, 0.438105) -18.82%,
      rgba(0, 0, 0, 0.8) 141.09%
    );
    mix-blend-mode: normal;
    border-radius: 6px;
    padding: 16px;
    gap: 8px;
    margin-bottom: 20px;
    margin-right: 20px;
    width: 320px;
  }
  
  .listEquipmentContainer {
    color: #ffffff;
  }
  
  h3 {
    font-family: 'Museo Sans', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
    height: 24px;
  }
  
  .listEquipmentItem {
    border-radius: 6px;
  }
  
  .listEquipmentItem tr td {
    color: #e3e3e3;
  }
  
  table {
    width: 100%;
    font-family: 'Museo Sans', sans-serif;
    font-weight: 300;
    font-size: 14px;
  }
  
  table tr {
    height: 21px;
    width: 288px;
    gap: 12px;
  }
  
  .valueEquipment {
    text-align: right;
  }
  
  .totalWord {
    color: white;
    font-weight: 500;
  }

  .totalEquipment {
    text-align: right;
    color: white;
    font-weight: 500;
  }

  .pflngTotalWord td {
    color: white;
    font-weight: 600;
    padding-top: 15px;
    position: relative;
  }

  .pflngTotalWord td::before {
    content: "";
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #6f6f6f8a;
  }

  .timeStamp {
    position: fixed;
    bottom: 40px;
    right: 40px;
    border-radius: 4px;
    background: var(--Black_gradient, linear-gradient(180deg, rgba(6, 25, 25, 0.33) 0%, rgba(0, 0, 0, 0.60) 100%));
    color: white;
    display: inline-flex;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    z-index: 2;
  }

  .timeStamp span {
    color: rgba(255, 255, 255, 0.60);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Museo Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px; /* 150% */
    letter-spacing: 0.167px;
}