.trendChart {
  width: 100%;
  height: 400px;
  margin-left: -15px;
}

.tooltipHtmlYep td > p {
  margin-bottom: unset;
}

.tooltipHtmlYet td > p {
  margin-bottom: unset;
}

.tooltipHtmlYep td:nth-child(1) > p {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.tooltipHtmlYep td:nth-child(2) > p {
  color: #9b9b9b;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.tooltipHtmlYep td:nth-child(3) > p {
  color: #847fe0;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
}

.tooltipHtmlYep td:nth-child(4) > p {
  color: #9b9b9b;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.tooltipHtmlYet td:nth-child(1) > p {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}

.tooltipHtmlYet td:nth-child(2) > p {
  color: #9b9b9b;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
}

.tooltipHtmlYet td:nth-child(3) > p {
  color: #00a19c;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  text-align: center;
}

.tooltipHtmlYet td:nth-child(4) > p {
  color: #9b9b9b;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
}
