.commercialHeader {
    border-bottom: 1px rgba(255, 255, 255, 0.1) solid;
    /* margin-bottom: 13px; */
    display: flex;
    padding: 12px;
    gap: 30px;
    position: relative;
}

.commercialTotal {
    border-top: 1px rgba(255, 255, 255, 0.1) solid;
    /* margin-bottom: 13px; */
    display: flex;
    padding: 12px 12px 0px 12px;
    gap: 20px;
    position: relative;
}

.commercialHeaderValue,
.commercialHeaderItem {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    font-weight: 700;
    display: flex;
    /* padding-right: 20px; */
    position: relative;
    width: 30%;
}

.commercialHeaderValue {
    justify-content: flex-end;
}

.commercialHeaderItem {
    flex: inherit;
    width: 200px;
}

.commercialItem {
    height: inherit;
    background: rgba(29, 29, 27, 0.4);
    border-radius: 8px;
    display: flex;
    color: #FFFFFF;
    padding: 12px;
    gap: 30px;
    position: relative;
}

.customAntCollapse {
    height: inherit;
    background: rgba(29, 29, 27, 0.4);
    border-radius: 8px;
    display: flex;
    padding: 5px 0px;
    gap: 30px;
    position: relative;
}

.commercialCollapseItems .ant-collapse-borderless .ant-collapse-item {
    border: none !important;
}

/* .ant-collapse .ant-collapse-item {
    border: none;
}

.ant-collapse-borderless .ant-collapse-item {
    border: none;
} */

.customAntHeader {
    display: flex;
    width: 440px;
}
  
.customAntHeader > div:first-child {
    width: 250px;
}

.commercialBody {
    height: 480px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    gap: 15px;
    flex-direction: column;
}

.commercialData {
    display: flex;
    width: 40%;
    justify-content: flex-end;
}

.commercialInitiative .commercialData {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    margin-right: 24px;
}

.commercialData .item,
.commercialData .initiative {
    display: flex;
    align-items: center;
    gap: 4px;
}

.commercialTotal .totalName,
.commercialItem .commercialDataName {
    flex: inherit;
    width: 270px;
}

.commercialDataName {
    flex: inherit;
    width: 260px;
}

.commercialDataName .itemName {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #E5E5E5
}

.commercialTotal .totalName {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #E5E5E5
}

.commercialTotalValue,
.commercialData .item .value {
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #E5E5E5
}

.commercialTotalValue {
    width: 25%;
    display: flex;
    justify-content: flex-end;
    margin-right: 25px;
}

.commercialInitiative {
    height: inherit;
    display: flex;
    position: relative;
}

.commercialDataName .initiativeName,
.commercialData .initiative .value {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #E5E5E5
}

.commercialData .item .unit,
.commercialData .initiative .unit {
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: #E5E5E5
}