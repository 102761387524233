.highlightComponent {
    flex: 1 1 auto;
    margin-top: 24px
}

.highlightComponent .highlightTitle span:first-child {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.222222px;
    color: #ffffff;
    mix-blend-mode: normal;
    opacity: 0.8;
}

.highlightDate{
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.138889px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: flex-end;
}

.highlightDesc {
    border-bottom: 1px solid rgba(217, 217, 217, 0.1);
    margin-bottom: 10px;
}

.highlightDesc:last-child {
    border-bottom: none;
}

.highlightDesc ul {
    color: rgba(255, 255, 255, 0.8);
    padding-left: 20px;
}


.highlightDesc .highlightItemTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.194444px;
    color: #ffffff;
}

.highlightDesc .highlightItemDescription {
    display: block;
    font-weight: 300;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.166667px;
    color: rgba(255, 255, 255, 0.8);
}

.highlightDot {
    width: 5px;
    height: 5px;
    display: block;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.8);
}

.mapRightPanelHighlights {
    background: rgba(29, 29, 27, 0.4);
    border-radius: 8px;
    padding: 12px;
    display: block;
    margin-top: 20px;
    height: calc(100% - 44px);
    overflow-y: auto;
}

.sustainabilitySummaryPanel {
    padding: 0 12px 0 12px;
    height: calc(100% - 44px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.sustainabilitySummaryHeader {
    display: flex;
    justify-content: space-between;
}

.sustainabilitySummaryDate {
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.138889px;
    color: rgba(255, 255, 255, 0.6);
    display: flex;
    justify-content: flex-start;
    width: 200px;
}

.summaryEdit{
    background-color: #0B3143F2;
    display: flex;
    justify-content: flex-end;
    border-radius: 8px;
}

.noResult {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
text-align: center;
width: 100%;
padding-top: 30%;
color: white;
}

.noResult h2 {
color: white;
font-family: 'Museo Sans';
margin-top: 10px;
font-size: 14px;
font-weight: 300;
line-height: 20px;
letter-spacing: 0.194px;
text-align: left;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    .mapRightPanelHighlights {
        height: 500px;
    }
}