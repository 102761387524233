.sustainabilityCalloutContainer {
    position: fixed;
    top: 120px;
    bottom: 0px;
    right: -100%;
    transition: all ease-in-out .5s;
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.4);
}

.container {
    position: fixed;
    top: 60px;
    bottom: 0px;
    right: -340px;
    transition: all ease-in-out .5s;
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(6px);
}

.container.compact {
    bottom: auto;
}

.container.compact .callOutBody div:first-child{
    margin-bottom: 0;
}

.container.show,
.sustainabilityCalloutContainer.show {
    right: 0px;
    z-index: 998;
}

.calloutContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Callout Header */

.headerTitle {
    display: flex;
    padding: 10px 0 10px 7px;
    border-bottom: 1px solid rgba(217, 217, 217, 0.1);
    margin: 0 -4px;
}

.pointTitle {
    font-weight: 700;
    padding: 10px;
}

.pointTitle span {
    font-weight: 550;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.15px;
    color: #ffffff;
}

.callOutCloseBtn {
    margin-top: 5px;
    height: 32px;
    width: 42px;
}

/* Callout Body */

.sustainabilityCallOutBody {
    padding: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: scroll;
}

.callOutBody {
    /* padding: 0px 14px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 72vh; */
    padding: 16px 14px 12px 14px;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
}

.callOutContainer.compact .callOutBody {
        height: auto;
}

.calloutLoadingIconContainer {
    min-height: 75vh;
}

.callOutCloseBtn {
    background-color: transparent !important;
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    border: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none !important;
    box-shadow: none !important;
    transition: all ease-in-out 0.2s;
    cursor: pointer;
}

.callOutCloseBtn i {
    font-size: 14px;
    line-height: 1;
}

.callOutCloseBtn:hover {
    box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
}