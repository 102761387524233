.uploadedFilesTableContainer {
    margin-top: 16px;
    box-shadow: inset 0px 1px 0 0 rgb(255 255 255/10%);
}

.uploadedFilesTableContainer h4 {
    color: rgba(255, 255, 255, 1);
    /* font-size: 12px; */
    /* font-weight: 300; */
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 600;
    padding-top: 16px;
}

.tableStripe {
    background: rgba(255, 255, 255, 0.03);
    border-style: solid;
    border-radius: 5px;
    border-radius: 10px;
    border-width: 3px;
}


.tableStripe td:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.tableStripe td:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.tableTextWrap {
    display: inline-flex;
    flex-direction: column;
}

.tableText {
    font-family: "Museo Sans", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.194px;
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actionButton {
    cursor: pointer;
    color: white;
    display: flex;
    justify-content: end;
    gap: 8px;
}

table {
    color: #FFFFFF;
}

.statusContainer {
    display: flex;
    align-items: center;
}

.statusStyle {
    font-family: 'Museo Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    /* 150% */
    letter-spacing: 0.167px;
    padding: 4px 8px;
    gap: 12px;
    border-radius: 5px;
    background: rgba(240, 249, 255, 0.10);
}

.successStatus {
    color: var(--Green, #00FF5D);
}

.errorStatus {
    color: var(--light-error-500, #DA2228);
}

.processingStatus {
    color: var(--light-info-400, #3685D3);
}

.modalDelIcon {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.modalDelHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.modalDelHeader span {
    font-family: "Museo Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    text-align: center;
    overflow-wrap: anywhere;
}

.modalDelSubtxt {
    font-family: 'Museo Sans', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.194444px;
    color: #ffffffcc;
    mix-blend-mode: normal;
    margin-top: 14px;
}

.modalDelButton {
    margin-top: 26px;
    display: flex;
    gap: 12px;
    justify-content: center;
}

.modalDelFooterCancelButton {
    cursor: pointer;
    height: 44px;
    width: 120px;
    border-radius: 8px;
    border: 1px solid rgba(0, 161, 156, 1);
    color: #ffffff;
    background: unset;
    font-size: 16px;
}

.modalDelFooterDelButton {
    font-size: 16px;
    cursor: pointer;
    height: 44px;
    width: 120px;
    border-radius: 8px;
    border: unset;
    color: #ffffff;
    background-color: #FF4F4F;
}