.mapRightPanelPrimaryInfo {
    background: rgba(29, 29, 27, 0.4);
    border-radius: 8px;
    padding: 12px;
    flex: 0 1 auto
}

/*To avoid the word break of the title*/
.detailInfoTitle {
    white-space: nowrap;
}

.detailInfo .detailInfoItem {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px;
}

.detailInfo .detailInfoItem:last-child {
    border-bottom: none;
}

.detailInfoItem .detailInfoText,
.detailInfoItem .detailInfoSubTitle {
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.138889px;
    color: #ffffff;
}

.detailInfoItem#pgu .detailInfoText,
.detailInfoItem#pgu .detailInfoSubTitle {
    width: max-content;
    /*Content determine the width of the tooltip*/
    word-wrap: break-word;
    /*Info goes to newline if exceed the tooltip width*/
    max-width: 416px;
    /*Limit the width of the content in the tooltip (tooltip max width minus padding left and right)*/
}

.primaryInfoImage {
    height: 153px;
}

.primaryInfoImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
}

.primaryInfoContent .contentDetail {
    color: #e5e5e5;
}

.contentDetail .infoItem {
    padding: 3px 0;
    /* height: 60px !important; */
}

.infoItem .infoTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.194444px;
    font-family: 'Museo Sans', sans-serif;
}

.infoItem p {
    margin-bottom: 0px;
}

.infoItem .colorDot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 4px;
    display: inline-block;
}

.infoItem .colorDot.grey {
    background-color: #c2c2c2;
}

.infoItem .colorDot.red {
    background-color: #da2228;
}

.infoItem .colorDot.yellow {
    background-color: #f1c31b;
}

.infoItem .colorDot.green {
    background-color: #0b882e;
}

.infoNumber {
    display: flex;
    align-items: center;
}

.infoNumber .primaryNumber {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    padding-top: 1px;
}

.infoNumber .numberUnit {
    font-weight: 200;
    font-size: 12px;
    line-height: 17px;
}

.infoSub {
    padding-top: 2px;
    font-family: 'Museo Sans', sans-serif;
}

.infoItem .infoSub {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    letter-spacing: 0.138889px;
    color: rgba(229, 229, 229, 0.8);
}

.infoSub b {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #ffffff;
}

.callOutTooltipOverlay {
    min-width: 452px;
}

.callOutTooltipOverlayPGU {
    max-width: 452px;
}

.callOutTooltipOverlay,
.callOutTooltipOverlayPGU {
    overflow-y: scroll;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.callOutTooltipOverlay>div,
.callOutTooltipOverlayPGU>div {
    border-radius: inherit;
    /* inherit from `callOutTooltipOverlay*/
    background-color: rgba(5, 12, 18, 0.9);
    /*Increased opacity of background*/
}

.callOutTooltipOverlay>div>div+div,
.callOutTooltipOverlayPGU>div>div+div {
    border-radius: inherit;
    /* inherit from `callOutTooltipOverlay*/
    backdrop-filter: blur(2px);
    /* blur backdrop for callOutTooltip */
}

.timeSinceLastColor {
    display: flex;
    justify-content: flex-end;
}

/* Sustainability styling */

.emissionValuePrimaryInfo {
    background: rgba(29, 29, 27, 0.4);
    border-radius: 8px;
    padding: 12px;
    flex: 0 1 auto;
    height: fit-content;
}

.emissionSourcesSummaryInfo {
    background: rgba(29, 29, 27, 0.4);
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.calloutGhgSecondaryInfo {
    height: 100%;
}

.emissionPanelInfo {
    border-radius: 8px;
    flex: 0 1 auto
}

.emissionDataContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.emissionPanelInfo .ant-row:last-child .emissionDataContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-right: none;
}

.emissionChartContainer {
    height: 100%;
    width: auto;
}

.woInherent {
    color: #FFFFFF;
    font-family: 'Museo Sans', sans-serif;
    font-size: 16px;
    display: flex;
    justify-content: center;
    line-height: 24px;
    letter-spacing: 0.2222222238779068px;
}

.emissionChart {
    height: 100%;
    width: 100%;
}

.emissionChartLegend {
    width: 50%;
    height: 220px;
    overflow-y: scroll;
}

.calloutTab { 
    padding-top: 7px;
}

.calloutTab ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    /* justify-content: space-between; */
    /* border-bottom: 1px rgba(255, 255, 255, 0.3) solid; */
    column-gap: 10px;
    margin-bottom: 13px;
}

.calloutTab ul li {
    padding: 8px 8px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    letter-spacing: 0.22px;
    line-height: 24px;
    transition: all ease-in-out 0.3s;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
}

/* .calloutTab ul li::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2px;
    content: '';
    width: 0;
    height: 4px;
    background-color: #00a19c;
    transition: all ease-in-out 0.3s;
} */

.calloutTab ul li:hover,
.calloutTab ul li.active {
    color: rgba(255, 255, 255, 1);
}

.calloutTab ul li:hover::after,
.calloutTab ul li.active::after {
    width: 100%;
}

.calloutTab .switchUnit {
    margin-left: 5px;
}

.co2Title {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #E5E5E5;
    display: flex;
}

.co2Value {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
}

.co2Unit {
    font-weight: 300;
    font-size: 12px;
    line-height: 17px;
    color: #FFFFFF;
    padding-left: 4px;
}

.co2Unit_flex{
    display: flex; 
    flex-direction: row; 
    justify-content: center;
    flex-wrap: wrap;
    color: rgba(255, 255, 255, 0.48) !important
}

.co2Unit_sub {
    font-size: 8px;
    padding-top: 2px;
}

.co2Title.disabled,
.co2Value.disabled,
.co2Unit.disabled {
    color: rgba(255, 255, 255, 0.2);
}

.co2DataConfident span {
    display: flex;
    height: 20px;
}

.co2DataConfident p {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    color: rgba(214, 214, 214, 0.2);
    letter-spacing: 1px;
    margin-top: 4px;
}

.co2DataConfident b {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: rgba(214, 214, 214, 0.2);
    letter-spacing: 1px;
    margin-right: 2px;
}

.co2DataConfident .confidentStatus {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: rgba(0, 255, 93, 0.5);
    letter-spacing: 1px;
}

.co2DataConfident .confidentStatus p {
    font-weight: 700;
    font-size: 10px;
    line-height: 15px;
    color: rgba(0, 255, 93, 0.2);
    letter-spacing: 1px;
    margin-left: 2px;
}

.comingSoon {
    border-radius: 8px;
    padding: 2px 4px 0 4px;
    margin-left: 4px;
    background-color: #004846;
    width: 70px;
    height: 15px;
}

.comingSoon p,
.comingSoon span {
    font-weight: 300;
    font-size: 8px;
    line-height: 10px;
    color: #F4FBFB;
    letter-spacing: 1px;
}

.sustainabilitySummaryDate {
    font-weight: 300;
    font-size: 10px;
    letter-spacing: 0.138889px;
    color: rgba(255, 255, 255, 0.60);
    width: inherit;
    line-height: 15px; /* 150% */
    /* letter-spacing: 0.139px; */
}

.sustainabilitySummaryDateContainer {
    border-top: 1px solid var(--neutral-neutral-black-50, #6F6F6F);
    width: inherit;
    margin-top: 12px;
    padding-top: 8px;
    display: flex;
    align-items: center;
    
}