.strategicCard {
  border: 1px solid #464646;
  /* opacity: 0.7; */
  border-radius: 8px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.55) 0%,
      rgba(0, 0, 0, 0.7));
  color: #ffffff;
  margin: 13px 18px 13px 0px;
  height: fit-content;
  min-height: 190px;
  /* position: relative; */
  display: flex;
  flex-direction: column;
  justify-content: end;
}

/* .strategicCard:hover {
  cursor: pointer;
  transform: translateY(-5px);
  background: linear-gradient(
    180deg,
    rgba(6, 25, 25, 0.55) 0%,
    rgba(0, 0, 0, 0.7)
  );
} */

/* .cardStatus {
  margin-top: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
} */

.cardHeader {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  margin-bottom: 13px;
  margin-left: 8px;
  margin-right: 8px;
  margin-top: 8px;
  align-items: center;
  font-size: 12px;
}

.cardStatusIcon {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 5px;
}

.checkIcon {
  background: #37c7a5;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

.cardContent {
  min-height: 80px;
  margin: 8px;
}

.cardCollapse .ant-collapse-borderless>.ant-collapse-item {
  border-bottom: none;
}

.cardStatusIconProcess {
  width: 22px;
  height: 22px;
}

.cardFooter {
  color: #ffffff;
  margin: 8px;
}

.cardFooter .iconArrowRotate {
  transform: rotate(180deg);
}

.cardFooter img {
  cursor: pointer;
}

.cardFooter .highlightLabel {
  opacity: 0.75;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}

.cardHeaderRight {
  display: flex;
  margin-top: 1px;
  flex-direction: row;
  align-items: center;
}

.cardHeaderLeft .lastUpdated {
  opacity: 0.75;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}

.cardHeaderLeft .lastUpdateValue {
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}

.cardFooter .highlightContent {
  border-radius: 8px;
  background-color: rgba(156, 164, 171,0.25);
  padding: 10px;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
}

.cardPanel {
  height: 0;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
  opacity: 0.3;
}

.cardPanelExpand {
  height: auto;
  opacity: 1;
}

.red {
  background: linear-gradient(180deg,
      rgba(247, 144, 144, 0.6) 0%,
      rgba(235, 87, 87, 0.6) 100%);
}

.green {
  background: linear-gradient(180deg,
      rgba(0, 207, 203, 0.6) 0%,
      rgba(0, 161, 156, 0.6) 100%);
}

.yellow {
  background: linear-gradient(180deg,
      rgba(255, 224, 135, 0.6) 0%,
      rgba(255, 190, 79, 0.6) 100%);
}

.amber {
  background: linear-gradient(180deg,
      rgba(255, 235, 59, 0.6) 0%,
      rgba(255, 203, 0, 0.6) 100%);
}

.default {
  background: linear-gradient(180deg, rgb(6 25 25 / 38%) 0%, #000000ad 100%);
}