.scorecardOrderContainer {
  box-sizing: border-box;
  height: 547px;
  border: 1px solid rgba(151, 151, 151, 0.2);
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.55) 0%, #000000 100%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 50%);
  padding-right: 30px;
  padding-top: 20px;
  padding-left: 20px;
}

.scorecardOrderList {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-left: 20px;
  padding-bottom: 65px;
  height: 100%;
  overflow: scroll;
}

.dropdownPersona {
  display: flex;
}

.dropdownPersona p {
  margin-top: 11px;
  margin-right: 10px;
  height: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 14px;
  font-weight: 100;
}
.savebutton {
  color: #ffffff;
  font-weight: 100;
  height: 38px;
  width: 120px;
  border-radius: 4px;
  background-color: #00a19c;
  border-color: #00a19c;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.cancelbutton {
  background: transparent;
  box-sizing: border-box;
  height: 40px;
  width: 121px;
  border: 1px solid #00a19c;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 100;
  margin-right: 9px;
}

.scorecardOrderHeader {
  height: 62px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid rgba(151, 151, 151, 0.2);
  display: flex;
  justify-content: space-between;
}

.scorecardOrderOPU {
  padding-top: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  height: 37px;
  width: 157px;
  border: 1px solid #464646;
  opacity: 0.7;
  border-radius: 4px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.55) 0%, #031719 100%);
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 14px;
}
