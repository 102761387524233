.backgroundMiniCardActiveGreen {
  background: linear-gradient(
    180deg,
    rgba(0, 207, 203, 0.2) 0%,
    rgba(0, 207, 203, 0.2) 50%,
    rgba(0, 207, 203, 0.3) 100%
  ) !important;
  border: 2px solid rgba(255, 255, 255, 0.5);
}

.backgroundMiniCardActiveRed {
  background: linear-gradient(
    180deg,
    rgba(247, 144, 144, 0.2) 0%,
    rgba(247, 144, 144, 0.2) 50%,
    rgba(247, 144, 144, 0.3) 100%
  ) !important;
  border: 2px solid rgba(255, 255, 255, 0.5);
}
.backgroundMiniCardActiveYellow {
  background: linear-gradient(
    180deg,
    rgba(255, 224, 135, 0.2) 0%,
    rgba(255, 224, 135, 0.2) 50%,
    rgba(255, 224, 135, 0.3) 100%
  ) !important;
  border: 2px solid rgba(255, 255, 255, 0.5);
}
