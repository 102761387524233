.bigCardsListContainer{
    width: 100vw;
    overflow: hidden;
    padding: 2px;
    margin-top: 6px;
}
.scoreCardsItem {
    display: grid;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    box-sizing: border-box;
    cursor: pointer;
    min-width: 196px;
    height: 120px;
    margin-top: 6px;
  }