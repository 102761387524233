.red {
  background: linear-gradient(
    180deg,
    rgba(247, 144, 144, 0.6) 0%,
    rgba(235, 87, 87, 0.6) 100%
  );
}

.green {
  background: linear-gradient(
    180deg,
    rgba(0, 207, 203, 0.6) 0%,
    rgba(0, 161, 156, 0.6) 100%
  );
}

.yellow,
.orange,
.amber{
  background: linear-gradient(
    180deg,
    rgba(255, 224, 135, 0.6) 0%,
    rgba(255, 190, 79, 0.6) 100%
  );
}

.scoreCardsItem {
  display: grid;
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  min-width: 196px;
  height: 120px;
  /* margin-right: 20px; */
  margin-top: 6px;
  width: fit-content;
}
.activeGreen {
  transform: translateY(-5px);
  box-sizing: border-box;
  height: 120px;
  min-width: 197px;
  border: 1px solid #bbb;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(0, 207, 203, 0.2) 0%,
    rgba(0, 207, 203, 0.2) 50%,
    rgba(0, 207, 203, 0.3) 100%
  );
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.activeRed {
  transform: translateY(-5px);
  box-sizing: border-box;
  height: 120px;
  min-width: 197px;
  border: 1px solid #bbb;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(247, 144, 144, 0.2) 0%,
    rgba(247, 144, 144, 0.2) 50%,
    rgba(247, 144, 144, 0.3) 100%
  );
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.activeYellow {
  transform: translateY(-5px);
  box-sizing: border-box;
  height: 120px;
  min-width: 197px;
  border: 1px solid #bbb;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(255, 224, 135, 0.2) 0%,
    rgba(255, 224, 135, 0.2) 50%,
    rgba(255, 224, 135, 0.3) 100%
  );
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.scoreCardsItemTitle {
  width: 100%;
  color: #ffffff;
  margin-top: -8px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  font-weight: 300;
}

.scoreCardsItemValue {
  color: #ffffff;
  font-size: 42px;
  letter-spacing: 0;
  line-height: 50px;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
}

.scoreCardsItemValue span {
  opacity: 0.8;
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: 8px;
  margin-left: 8px;
}

.scoreCardsItemPlanTarget {
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  font-weight: 100;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.scoreCardsItemPlanTarget .scoreCardItemValue{
  width: 80px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.scoreCardsItemPlanTarget .scoreCardItemDeltaValue {
  padding: 0 10px;
}

.scoreCardsItemPlanTarget b {
  font-weight: 500;
}

.detalValueUnit {
  margin-right: 5px;
}