.root {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  z-index: 2;
  position: relative;
}

.text {
  color: #dddddd;
  font-family: "Museo Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: 300;
}

.text b {
  font-weight: 500;
}

.link {
  display: inline-block;
  padding: 8px 20px;
  text-decoration: none;
  font-size: 1.125em;
  /* ~18px */
}

.link,
.link:active,
.link:visited {
  color: rgba(255, 255, 255, 0.6);
}

.link:hover {
  color: rgba(255, 255, 255, 1);
}

.avatar {
  margin-left: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px rgba(255, 255, 255, 0.6) solid;
  cursor: pointer;
}

.bell {
  width: 20px;
  height: 24px;
}

.userManagement {
  width: 20px;
  height: 24px;
  margin-left: 17px;
  cursor: pointer;
  color: rgb(255, 255, 255);
}

.highlight {
  margin-right: 8px;
  margin-left: 8px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.15);
  color: #fff;
}

.highlight:hover {
  background: rgba(0, 0, 0, 0.3);
}

.separator {
  box-sizing: border-box;
  /* height: 24.5px; */
  width: 1px;
  background: #FFFFFF1A;
  margin: 0 16px 0 6px;
  height: 65%;
}

.navItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
}

.navItem .icon {
  width: 24px;
  height: 24px;
  margin: 0 auto;
}

.navItem .adminIcon {
  width: 24px;
  height: 24px;
  margin: 0 auto;
  padding: 3px;
}

.navItem .subText {
  font-size: 12px;
  line-height: 15px;
  font-weight: 200;
}

.navItem .subText.selected {
  color: #00a19c
}

.navItem.active {
  border-bottom: 2px solid #00a19c;
  margin-bottom: -10px;
  padding-bottom: 9px;
}

.navItem span {
  cursor: pointer;
}

.navItem.active span,
.navItem.selected span {
  color: #00a19c
}

.root a {
  color: inherit;
  text-decoration: inherit;
}

@media (max-width: 767px) {

  .text,
  .spacer {
    display: none;
  }
}

.logoutButton {
  /* background-color: transparent !important; */
  background: linear-gradient(180deg, rgba(5, 18, 18, 0.55) 0%, #000000 100%);
  position: absolute;
  color: #fff;
  border: 0;
  border-radius: 6px;
  width: auto;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
  font-family: "Museo Sans", sans-serif;
  font-size: 11px;
}

.logoutButton i {
  font-size: 14px;
  line-height: 1;
}

.logoutButton:hover {
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
  background: linear-gradient(67.9deg, #303395 0%, #28efef 100%);
}

.avatarMoblie {
  margin-left: 10px;
  width: 111px;
  height: 111px;
  border-radius: 50%;
  background-color: #fff;
  border: 1px rgba(255, 255, 255, 0.6) solid;
  cursor: pointer;
}

.textNameMobile {
  height: 52px;
  width: 163px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
}

.textMoblie {
  margin-top: 8px;
  text-align: center;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  opacity: 0.8;
  line-height: 17px;
  font-weight: 200;
  margin-bottom: 6px;
}

.popoverContent div:not(:last-child) {
  display: flex;
  align-items: center;
  margin-bottom: 13px;
  cursor: pointer;
}

.popoverContent div:last-child {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.popoverContent span {
  color: #ffffff;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

.popoverContent img {
  height: 27px;
  width: 27px;
  margin-right: 10px;
  
}

.popoverPersona {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.popoverPersona span {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  text-transform: uppercase;
}

.popoverPersona>div>div:first-child p {
  color: #ffffffb8;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.popoverPersona>div>div:last-child {
  display: flex;
  margin-top: 12px;
  cursor: pointer;
}

.popoverPersona>div>div:last-child p {
  color: #ffffffb8;
  font-size: 12px;
  margin-bottom: unset;
  margin-left: 6px;
}

.personaSelection {
  border-radius: 5px;
  border: 0.15em solid #464646;
  margin-left: 10px;
  cursor: pointer;
}

.personaSelection p {
  color: #00a19c;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  margin-bottom: unset;
  padding-right: 5px;
  padding-left: 5px;
}

.divBorder {
  margin-top: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.forwardPriceIcon {
  cursor: pointer;
  display: inline-block;
  padding: 8px 13px;
  text-decoration: none;
  font-size: 1.125em;
}