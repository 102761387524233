.mftPanel {
  padding: 12px;
  box-sizing: border-box;
  min-height: 592px;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(6 25 25 / 7%) 0%,
    rgba(0 0 0/0.38) 100%
  );
}

.primaryCards {
  cursor: pointer;
  box-sizing: border-box;
  height: 111px;
  width: auto;
  border: 2px solid #464646;
  border-radius: 8px;
  background: linear-gradient(180deg, rgb(6 25 25 / 33%) 10%, #000000c9 100%);
  padding-left: 17px;
  padding-right: 10px;
  margin-right: 10px;
}

.primaryCardTitle {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  min-width: 200px;
}

.primaryCardTitle p {
  color: #ffffff;
  font-size: 100%;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 8px;
  margin-bottom: unset;
  white-space: nowrap;
}

.primaryCardActual {
  display: flex;
  height: 38px;
}

.primaryCardActual > p {
  color: #ffffff;
  font-size: 42px;
  letter-spacing: 0;
  line-height: 42px;
  margin-bottom: unset;
  margin-right: 6px;
}

.primaryCardActual > div {
  display: flex;
  align-items: flex-end;
}

.primaryCardActual > div > p:first-child {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: unset;
  margin-right: 3px;
}

.primaryCardActual > div > p:nth-child(2) {
  color: rgba(255 255 255/0.8);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  margin-bottom: unset;
}

.primaryCardsValues {
  display: flex;
  height: 15px;
}
.primaryCardsValues p {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 4px;
  margin-bottom: unset;
  white-space: nowrap;
  position: relative;
}
.primaryCardsValues i {
  font-size: 26px;
  color: #ffffff;
  position: absolute;
  right: -25px;
}

.cardsSection {
  display: flex;
}

.breakdownCardsContainer {
  box-sizing: border-box;
  height: 111px;
  width: 100%;
  border: 2px solid #464646;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(6, 25, 25, 0.209),
    rgba(0, 0, 0, 0.55)
  );
  margin-left: 15px;
}

.breakdownCards {
  cursor: pointer;
  padding-left: 16px;
  padding-bottom: 9px;
  padding-top: 13px;
  box-sizing: border-box;
  height: 91px;
  width: 200px;
  border: 2px solid #464646;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(6, 25, 25, 0.209),
    rgba(0, 0, 0, 0.55)
  );
  overflow-wrap: break-word;
}

.breakdownCardName p {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.35px;
  line-height: 14px;
  margin-bottom: unset;
  /* white-space: nowrap; */
}

.breakdownCardValue {
  display: flex;
}

.breakdownCardValue > p {
  color: #00a19c;
  font-size: 24px;
  letter-spacing: 0.7px;
  line-height: 29px;
  margin-bottom: unset;
}

.breakdownCardValue > div {
  display: flex;
  align-items: flex-end;
}
.breakdownCardValue > div > p:first-child {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 14px;
  margin-bottom: 2px;
  margin-left: 4px;
}

.breakdownCardValue > div > p:nth-child(2) {
  color: rgba(255, 255, 255, 0.6);
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 14px;
  margin-bottom: 2px;
  margin-left: 4px;
}

.breakdownCardPlan {
  display: flex;
}

.breakdownCardPlan p {
  color: #ffffff;
  font-size: 12px;
  letter-spacing: 0.35px;
  line-height: 14px;
}

.breakdownCardPlan i {
  font-size: 26px;
  margin-top: -14px;
  color: #ffffff;
}
.scoreCardButton {
  background: transparent;
  box-shadow: 0;
  z-index: 111;
  position: absolute;
  top: 24%;
}

.scoreCardButtonPrev {
  width: 66px;
  z-index: 1111;
  -webkit-clip-path: circle(50% at 0 50%);
  clip-path: circle(50% at 0 50%);
  background: linear-gradient(180deg, rgba(5, 18, 18, 0.55) 0%, #000000 100%);
  opacity: 0.7;
  border: 2px red;
}

.scoreCardButtonPrev img {
  margin-top: 1px;
  width: 18px;
  height: 18px;
  margin-left: -35px;
}
.scoreCardButtonNext {
  width: 66px;
  border: none;
  -webkit-clip-path: circle(50% at 100% 50%);
  clip-path: circle(50% at 100% 50%);
  background: linear-gradient(180deg, rgba(5, 18, 18, 0.55) 0%, #000000 100%);
  opacity: 0.7;
  justify-content: center;
  align-items: center;
}

.scoreCardButtonNext img {
  margin-right: -35px;
  margin-top: 1px;
  width: 18px;
  height: 18px;
  transform: scaleX(-1);
}
.chartContainer {
  margin-top: 22px;
  box-sizing: border-box;
  min-height: 323px;
  border: 2px solid #464646;
  border-radius: 8px;
  background: linear-gradient(180deg, rgb(6 25 25 / 23%) 0%, #00000078 100%);
}

.activeCardGreen {
  border: 1.5px solid #fff;
  background: #00a19ca8;
}

.activeCardRed {
  border: 1.5px solid #fff;
  background: #eb575778;
}

.activeCardYellow {
  border: 1.5px solid #fff;
  background: #ffbe4f99;
}

.legendSection {
  display: flex;
  justify-content: flex-end;
  margin-top: 21px;
}

.legendGroup {
  display: flex;
}

.legendGroup p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  letter-spacing: -0.17px;
  line-height: 14px;
  margin-right: 18px;
}

.legendGroup div {
  margin-right: 10px;
}

.legend {
  height: 13px;
  width: 13px;
  border-radius: 2px;
}
.chartUnit {
  display: flex;
  color: #ffffffb8;
  font-size: 10px;
  letter-spacing: -0.14px;
  line-height: 12px;
  margin-left: 19px;
}

.mdi.mdi-menu-up,
.mdi.mdi-menu-down {
  margin-bottom: 10px !important;
}
.arrowDown {
  transform: rotate(180deg);
}

.whiteDash > p {
  color: rgb(255, 255, 255) !important;
}

.noResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 10%;
  color: white;
}

.noResult h2 {
  color: white;
  font-family: 'Museo Sans';
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.194px;
  text-align: left;
}