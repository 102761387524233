.scoreCardButton {
  background: transparent;
  box-shadow: 0;
  z-index: 111;
  position: absolute;
  top: 24%;
}

.scoreCardButtonPrev {
  height: 66px;
  width: 66px;
  z-index: 1111;
  clip-path: circle(50% at 0 50%);
  background: linear-gradient(180deg, rgba(5,18,18,0.55) 0%, #000000 100%);
  opacity: 0.7;
  border: 2px red;
  position: absolute;
  top: 230px;
  margin-left: 8px;
}

.scoreCardButtonPrev img {
  margin-top: 1px;
  width: 18px;
  height: 18px;
  margin-left: -35px;
}
.scoreCardButtonNext {
  height: 66px;
  width: 66px;
  z-index: 1111;
  border: none;
  clip-path: circle(50% at 100% 50%);
  background: linear-gradient(180deg, rgba(5,18,18,0.55) 0%, #000000 100%);
  opacity: 0.7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 230px;
  margin-left: calc(87vw + 11px);
}
@media (max-width: 1700px) {
  .scoreCardButtonNext {
    margin-left: calc(85vw + 8px);
  }
}
@media (max-width: 1440px) {
  .scoreCardButtonNext {
    margin-left: calc(82vw + 9px);
  }
}
@media (max-width: 1200px) {
  .scoreCardButtonNext {
    margin-left: calc(75vw + 2px);
  }
}

.scoreCardButtonNext img {
  margin-right: -35px;
  margin-top: 1px;
  width: 18px;
  height: 18px;
  transform: scaleX(-1);
}
.listBigCardnotCarousel{
  display: flex;
  gap:20px ;
}
