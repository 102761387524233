:root {
    --brand-color: #fff;
}

.headerRoot {
    color: #fff;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.372389) 0%, rgba(0, 0, 0, 0.68) 100%);
    position: fixed;
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
}

.headerContainer {
    margin: 0 auto;
    padding: 0 10px 0 0;
    height: 60px;
}

.brand {
    position: relative;
    text-decoration: none;
}

.logo {
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    background-color: #01B0A9;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo img {
    height: 38px;
    width: 38px;
    margin-bottom: 3px;
}

.brandTxt {
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 22px;
    text-decoration: none;
    padding-left: 80px;
    display: flex;
    align-items: center;
    height: 100%;
    font-weight: bold;
}

@media(max-width:576px) {
    /*Text for header*/
    .brandTxt {
        position: absolute;
        left: 0;
        display:flex;
    }
}

.banner {
    text-align: center;
}

.bannerTitle {
    margin: 0;
    padding: 10px;
    font-weight: normal;
    font-size: 4em;
    line-height: 1em;
}

.bannerDesc {
    padding: 0;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.25em;
    margin: 0;
}

/* Resopnsive mobile */

.headerLayoutMobile {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    padding: 0 20px;
    align-items: center;
    height: 60px;
    width: 100vw;
}

/* .headerTxtMobile {
    height: 19px;
    width: 115px;
    color: #FFFFFF;
    font-family: "Museo Sans", sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 19px;
    text-align: center;
    white-space: nowrap;
} */

.headerTxtMobile{
    color: #ffffff;
    font-family: "Museo Sans", sans-serif;
    font-size: 15px;
    font-weight: 350;
    letter-spacing: 0.5px;
    line-height: 22px;
    text-decoration: none;
    display: flex;
    align-items: center;
    white-space: nowrap;
    margin-top: 5px;
}
.headerInfo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerInfo img {
    margin-right: 10px;
}


.closeHeader {
    transform: translate(100vw);
    color: #fff;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.1) 0%, rgba(0, 0, 0, .5) 100%);
    position: fixed;
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
    transition-duration: 1s;
}

.headerRootMobile {
    color: #fff;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.372389) 0%, rgba(0, 0, 0, 0.68) 100%);
    position: fixed;
    width: 100vw;
    z-index: 999;
    height: 60px;
    top: 0;
    left: 0;
    transition-duration: 1s;
}

.headerRootMobileScoreCard {
    color: #fff;
    /* background: linear-gradient(180deg, rgba(6, 25, 25, 1) 0%, rgba(0, 0, 0, 0.5) 100%); */
    background: linear-gradient(180deg, rgba(6, 25, 25, 1) 0%, #000000 100%);
    position: fixed;
    width: 100vw;
    z-index: 998;
    top: 0;
    left: 0;
    transition-duration: 1s;
}

.closeHeaderScoreCard {
    transform: translate(100vw);
    color: #fff;
    background: linear-gradient(180deg, rgba(6, 25, 25, 1) 0%, #000000 100%);
    position: fixed;
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
    transition-duration: 1s;
}

/* Specifically for the non-functional header with no transition */
.plainHeaderRootMobile {
    color: #fff;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.1) 0%, rgba(0, 0, 0, .5) 100%);
    position: fixed;
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
}