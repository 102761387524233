body {
    background-color: #005564;
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Museo Sans', sans-serif;
  }
  
  .mftRoot {
    /* min-width: 100vw; */
    padding-top: 60px;
    box-sizing: border-box;
    min-height: 100vh;
  }
  
  .mftContainer {
    margin-top: -60px;
    min-height: 100vh;
    position: relative;
  }
  
  .mftContent {
    /*width: 91%; */
    margin: 30px 30px 30px 20px;
    display: flex;
    column-gap: 40px;
  }
  
  ::-webkit-scrollbar {
    width: 6px;
  }
  
  .mftSectionTitleWithAction {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .filterBlocks {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
  }
  
  .filterBlock {
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      rgba(6, 25, 25, 0.374) 0%,
      rgba(0, 0, 0, 0.68) 100%
    );
    padding: 10px 5px 10px 15px;
    line-height: 1;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  
  .filterBlock span {
    color: #dddddd;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: 100;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 1px rgba(255, 255, 255, 0.3) solid;
  }
  
  .filterBlockDropdown button {
    background-color: transparent;
    color: #fff;
    border: 0;
    outline: none;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    font-weight: 100;
    display: flex;
    text-transform: uppercase;
    align-items: center;
    cursor: pointer;
  }
  
  .filterBlockDropdown button i {
    font-size: 24px;
    color: #00a19c;
    margin-left: 8px;
    margin-right: -10px;
  }
  
  .filterBlockDropdownMenu {
    position: absolute;
    border-radius: 0 0 3px 3px;
    top: 40px;
    width: 180px;
    right: 0;
    background: linear-gradient(
      180deg,
      rgb(34 40 42) 0%,
      rgba(0, 0, 0, 0.88) 100%
    );
    z-index: 9;
    transition: all ease-in-out 0.2s;
    height: 0;
    overflow: hidden;
  }
  
  .filterBlockDropdown.show .filterBlockDropdownMenu {
    height: auto;
    padding: 8px 0;
  }
  
  .filterBlockDropdownMenu ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
  }
  
  .filterBlockDropdownMenu ul li button {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    color: rgba(255, 255, 255, 0.3);
    transition: all ease-in-out 0.2s;
  }
  
  .filterBlockDropdownMenu ul li button:hover,
  .filterBlockDropdownMenu ul li button.selected {
    background-color: #004f4e;
    color: #fff;
  }
  
  
  .mftRight {
    flex: 1;
  }
  
  .disableTabLi {
    pointer-events: none;
    opacity: 0.6;
  }
  
  .mftRightTab ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    /* justify-content: space-between; */
    border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
    column-gap: 10px;
    margin-bottom: 13px;
  }
  
  .mftRightTab ul li {
    cursor: pointer;
    padding: 8px 20px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 16px;
    transition: all ease-in-out 0.3s;
    font-weight: 500;
    position: relative;
  }
  
  .mftRightTab ul li::after {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2px;
    content: '';
    width: 0;
    height: 4px;
    background-color: #00a19c;
    transition: all ease-in-out 0.3s;
  }
  
  .mftRightTab ul li:hover,
  .mftRightTab ul li.active {
    color: rgba(255, 255, 255, 1);
  }
  
  .mftRightTab ul li:hover::after,
  .mftRightTab ul li.active::after {
    width: 100%;
  }
  
  .mftRightTabContent p {
    margin-bottom: 16px;
    color: #d9d9d9;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 1;
    display: flex;
    align-items: flex-end;
  }
  
  .mftRightTabContent p img {
    margin-right: 6px;
    height: 16px;
  }
  
  .mftItems {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    padding-top: 10px;
    width: 100%;
  }
  
  .mftCol {
    display: block;
    flex: 1;
  }
  
  .mftItem {
    display: grid;
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;
    border-radius: 8px;
    position: relative;
    box-sizing: border-box;
  }
  
  .mftItemTitleIcon {
    display: inline-flex;
  }
  
  .iconBackWhite {
    height: 13px;
    width: 16px;
    margin-right: 5px;
  }
  
  .RED {
    background: linear-gradient(
      180deg,
      rgba(247, 144, 144, 0.6) 0%,
      rgba(235, 87, 87, 0.6) 100%
    );
  }
  
  .GREEN {
    background: linear-gradient(
      180deg,
      rgba(0, 207, 203, 0.6) 0%,
      rgba(0, 161, 156, 0.6) 100%
    );
  }
  
  .YELLOW {
    background: linear-gradient(
      180deg,
      rgba(255, 224, 135, 0.6) 0%,
      rgba(255, 190, 79, 0.6) 100%
    );
  }
  
  .mftItems .mftItem:nth-child(3n) {
    margin-right: 0;
  }
  
  .mftItemMore {
    height: 0;
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    opacity: 0.3;
  }
  
  .mftItemMoreSlide {
    display: flex;
  }
  
  .mftItemMoreLeft {
    text-align: right;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px rgba(255, 255, 255, 0.3) solid;
    padding-left: 5px;
  }
  
  .mftItemMoreLeft span,
  .mftItemMoreLeft b {
    display: block;
    transition: all ease-in-out 0.3s;
  }
  
  .mftItemMoreLeft span {
    color: #ffffff;
    font-weight: 100;
  }
  
  .mftItemMoreLeft b {
    color: #ffffff;
    font-weight: 500;
  }
  
  .mftItemMoreRight {
    color: #ffffff;
    font-weight: 100;
    transition: all ease-in-out 0.3s;
    text-align: left;
  }
  
  .mftItem.expanded .mftItemMore {
    height: auto;
    opacity: 1;
    padding-bottom: 10px;
  }
  
  .mftItemFooter span {
    display: block;
    transition: all ease-in-out 0.3s;
  }
  
  .mftItem.expanded .mftItemFooter span {
    transform: rotateX(180deg);
  }
  
  .mftItem .mftItemMore .mftItemMoreLeft span {
    line-height: 16px;
    font-size: 14px;
  }
  
  .mftItem .mftItemMore .mftItemMoreLeft b {
    line-height: 31px;
    font-size: 26px;
  }
  
  .mftItem .mftItemMore .mftItemMoreRight {
    line-height: 1.5;
    font-size: 13px;
    padding-bottom: 8px;
  }
  
  .mftItem.green {
    background: linear-gradient(
      180deg,
      rgba(0, 207, 203, 0.6) 0%,
      rgba(0, 161, 156, 0.6) 100%
    );
  }
  
  .mftItem.red {
    background: linear-gradient(
      180deg,
      rgba(247, 144, 144, 0.6) 0%,
      rgba(235, 87, 87, 0.6) 100%
    );
  }
  
  .mftItem.amber,
  .mftItem.yellow {
    background: linear-gradient(
      180deg,
      rgba(255, 224, 135, 0.6) 0%,
      rgba(255, 190, 79, 0.6) 100%
    );
  }
  
  .mftItemBody {
    display: flex;
    flex-wrap: wrap;
  }
  
  .mftItemIdentified {
    position: absolute;
    top: 5px;
    right: 10px;
  }
  
  .mftItemIcon {
    height: 74px;
    width: 74px;
    background-color: rgba(255, 255, 255, 0.13);
    border-radius: 50%;
    padding: 15px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .mftItemInfo {
    flex: 1;
    padding-top: 5px;
    padding-bottom: 10px;
  }
  
  .mftItemPlanTarget {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    display: flex;
    align-items: center;
    font-weight: 100;
    width: 100%;
    padding-left: 89px;
    margin-top: -10px;
    padding-bottom: 10px;
  }
  
  .mftItemPlanTarget b {
    font-weight: 500;
  }
  
  .mftItemTitle {
    width: 100%;
    color: #ffffff;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 300;
  }
  
  .mftItemValue {
    color: #ffffff;
    font-size: 48px;
    letter-spacing: 0;
    line-height: 57px;
    font-weight: 500;
    display: flex;
    align-items: flex-end;
  }
  
  .mftItemValue span {
    opacity: 0.8;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 8px;
    margin-left: 8px;
  }
  
  .mftItemFooter {
    border-top: 1px rgba(255, 255, 255, 0.2) solid;
    font-size: 28px;
    line-height: 1;
    text-align: center;
    color: #fff;
    padding: 1px 0;
    opacity: 0.5;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }
  
  .disabledExpand .mftItemFooter {
    cursor: not-allowed;
    opacity: 0.5 !important;
  }
  
  .canClickBigCard {
    cursor: pointer;
  }
  
  .mftItem:hover .mftItemFooter {
    opacity: 1;
  }
  
  .subValueNormal {
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    font-weight: 100;
    padding-left: 89px;
  }
  
  .subValueNormal b {
    font-weight: 500;
  }
  
  .mftPagination {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .mftPagination button {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    opacity: 0.68;
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.55) 0%, #000000 100%);
    color: #00a19c;
    font-size: 18px;
    cursor: pointer;
  }
  
  .mftPagination button:first-child {
    border-radius: 4px 0 0 4px;
    margin-right: 1px;
  }
  
  .mftPagination button:last-child {
    border-radius: 0 4px 4px 0;
  }
  
  .mftPagination button:disabled {
    opacity: 0.68;
    color: rgba(255, 255, 255, 0.3);
  }
  
  /* MINI CARD */
  
  .miniCardsList {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .miniCardItem {
    margin-bottom: 30px;
    flex: 1;
    position: relative;
  }
  
  .miniCardItemHeader {
    color: rgba(255, 255, 255, 0.8);
    font-size: 16px;
    letter-spacing: 0.3px;
    line-height: 19px;
    text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
    position: relative;
  }
  
  .miniCardItemBody {
    padding: 20px 17px 0 17px;
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.38) 100%
    );
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  
  .miniCardsWrap {
    display: flex;
    gap: 18px;
    /* overflow-y: scroll; */
  }
  /* .miniCardsWrapLevel2 {
    background: linear-gradient(180deg, rgba(6, 25, 25, 0.209) 0%, rgba(0, 0, 0, .38) 100%);
    min-width: fit-content;
    padding: ;
  } */
  
  .miniCardItemMore {
    height: 0;
    overflow: hidden;
    transition: all ease-in-out 0.3s;
    opacity: 0.3;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
  
  .miniCardItemMoreExpanded {
    height: auto;
    opacity: 1;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }
  
  .miniCardItemInfo.expandedMiniCard .miniCardItemMore {
    height: auto;
    opacity: 1;
    padding-bottom: 10px;
  }
  
  .miniCardsWrap.carousel.slide {
    text-align: left;
  }
  
  .miniCardItemInfo {
    text-align: left;
  }
  
  .miniCardItemTitle {
    white-space: nowrap;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 0.35px;
    line-height: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .miniCardItemUnit {
    white-space: nowrap;
    opacity: 0.6;
    color: #ffffff;
    font-size: 12px;
    font-weight: 100;
    letter-spacing: 0.33px;
    line-height: 14px;
    margin-bottom: 3px;
  }
  
  .miniCardItemValue {
    white-space: nowrap;
    color: #00a19c;
    font-size: 24px;
    letter-spacing: 0.7px;
    line-height: 29px;
    font-weight: 500;
  }
  
  .miniCardItemValueColorred {
    color: #e75552;
  }
  
  .miniCardItemValueColorgreen {
    color: #00a19c;
  }
  
  .miniCardItemFooter {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-top: -10px;
    font-size: 24px;
    line-height: 1;
    opacity: 0.3;
    color: #fff;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    text-align: left;
  }
  
  .miniCardItemFooter span {
    display: block;
    transition: all ease-in-out 0.3s;
  }
  
  .miniCardItemFooterArrowUp span {
    display: block;
    padding-left: 10px;
    font-size: 24px;
    line-height: 1;
    color: #ffffff;
    cursor: pointer;
    text-align: left;
    transition: all ease-in-out 0.3s;
    transform: rotateX(180deg);
  }
  
  .miniCardItemFooter:hover {
    opacity: 1;
  }
  
  .moreDetailText {
    padding-top: 10px;
    font-size: 16px;
  }
  
  .moreDetailText img {
    margin-right: 10px;
  }
  .moreDetailText span {
    color: #00a19c;
  }
  .miniCardItemLevel2 {
    width: 100%;
    margin-bottom: 30px;
    flex: 1;
    position: relative;
    /* max-width: fit-content; */
  }
  .miniCardItemInforLevel2 {
    padding: 20px 17px 20px 17px;
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.38) 100%
    );
    gap: 15px;
    text-align: left;
    min-width: 100px;
    width: 100%;
    cursor: pointer;
  }
  .miniCardsListLevel2 {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
  }
  .pagiMiniCardList {
    display: flex;
    gap: 8px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .pagiMiniCard {
    box-sizing: border-box;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
  .pagiMiniCardActive {
    box-sizing: border-box;
    height: 9px;
    width: 9px;
    border-radius: 50%;
    border: 1px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.6);
  }
  .hidePagiMiniCardList {
    display: none;
  }
  /* Chart */
  .backGroundChart {
    box-sizing: border-box;
    width: 100%;
    border-radius: 8px;
    background: linear-gradient(
      180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.38) 100%
    );
    padding: 10.5px;
    border: 2px solid rgb(39 37 37 / 40%);
  }
  .backGroundChart ::-webkit-scrollbar-thumb {
    width: 2px;
    opacity: 0.5;
    border-radius: 10px;
    background-color: rgba(0, 161, 156, 0.6);
    -webkit-box-shadow: unset;
  }
  
  .backGroundChart ::-webkit-scrollbar {
    width: 6px;
  }
  
  .sumaryChartTitle {
    color: #FFFFFF;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
  }
  
  @media (max-width: 1100px) { /* originally 1440px*/
    .mftContent {
      flex-wrap: wrap;
    }
    .mftLeft {
      width: 100%;
      margin-bottom: 20px;
    }
    .mftLeftList ul {
      margin-bottom: 0;
    }
    .mftLeftList ul li {
      padding-bottom: 5px;
      margin-bottom: 5px;
    }
  }
  
  @media (max-width: 1100px) {
    .mftLeftList {
      padding: 15px;
    }
    .mftItem {
      padding: 15px 15px 0 15px;
    }
    .mftItemIcon {
      width: 60px;
      height: 60px;
    }
    .mftItemPlanTarget,
    .subValueNormal {
      padding-left: 75px;
    }
    .mftItemValue {
      font-size: 32px;
      line-height: 36px;
    }
    .mftItemValue span {
      margin-bottom: 3px;
    }
    .mftItemTitle {
      font-size: 14px;
    }
    .mftRightTab ul li {
      font-size: 14px;
    }
    .mftLeftList ul li {
      font-size: 12px;
    }
    /* .mftLeft .mftSectionTitle,
    .mftSectionTitle {
      margin-bottom: 10px;
    } */
    .mftItem .mftItemMore .mftItemMoreLeft b {
      font-size: 22px;
    }
    .mftItem .mftItemMore .mftItemMoreRight {
      font-size: 12px;
      line-height: 1.3;
    }
  }