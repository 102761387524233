.errorModalHeader {
    display: flex;
    justify-content: space-between;
}

.errorModalHeader img {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
}

.errorModalHeader span {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-align: left;
    color: #ffffff;
}

.divider {
    border-bottom: solid 1px rgba(239, 239, 239, 0.12);
    width: auto;
    margin: 15px -24px;

}

.resolveSteps {
    background: #E1F2FE;
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #005CB9;
    padding: 8px 8px 1px 8px;
    border-radius: 8px;
    width: fit-content;
    margin-top: 20px;
}

.resolveSteps ol {
    font-family: Museo Sans;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    letter-spacing: 0.1666666716337204px;
    text-align: left;
    margin-left: 0;
    padding-left: 20px;
}

.resolveSteps span {
    font-family: Museo Sans;
    font-weight: 600;
    text-align: left;
}

.tableStripe {
    background: rgba(255, 255, 255, 0.03);
    border-style: solid;
    border-radius: 5px;
    border-radius: 10px;
    border-width: 3px;
}