.listDndCard {
  padding-left: 20px;
  display: flex;
  height: 80px;
  font-weight: 300;
  width: 241px;
  border-radius: 4px;
  background-color: rgba(216, 216, 216, 0.1);
  align-items: center;
  color: rgba(255, 255, 255, 0.8);
  cursor: grab;
}

.listDndBox {
  margin-bottom: 31px;
  margin-right: 30px;
  border-radius: 4px;
  border: 1px dashed gray;
}

.scoreCardsItemIcon {
  height: 48px;
  width: 48px;
  background-color: rgba(255, 255, 255, 0.13);
  border-radius: 50%;
  padding: 15px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoreCardsItemIcon > img {
  height: 24px;
  width: 26.24px;
}

.listDndOuterCard > div:first-child {
  margin-bottom: 11px;
  color: rgba(255, 255, 255, 0.8);
}
