.confirmPrompt {
  margin: 0;
  opacity: 0.8;
  color: #ffffff;
  font-size: 15px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  padding: 50px 20px;
  font-weight: 300;
}

.confirmContent button {
  width: 50%;
  height: 50px;
  background: linear-gradient(180deg, rgba(28, 34, 36, 0.3) -8.02%, rgba(31, 35, 37, 0.3) 23.73%, rgba(14, 16, 16, 0.3) 100%);
  border: none;
  cursor: pointer;
  font-family: 'Museo Sans', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
}

.cancelButton {
  border-radius: 0 0 0 8px;
  border-right: 0.1px solid rgba(255, 255, 255, 0.1) !important;
}

.cancelButton span {
  opacity: 0.3;
}

.okButton {
  border-radius: 0 0 8px 0;
}

.okButton span {
  opacity: 0.8;
}