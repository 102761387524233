.popoverContent {
  display: flex;
  flex-direction: column;
}
.popoverContent span {
  color: #ffffff82;
  cursor: pointer;
}
.popoverContent span:hover {
  background-color: #ffffff3d;
}

.tbodyText {
  align-items: center;
  display: flex;
  height: 36px;
  width: 200px;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  justify-content: space-between;
  cursor: pointer;
}

.tbodyText span {
  height: 14px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  letter-spacing: 0.33px;
  line-height: 14px;
  font-weight: 100;
  margin-left: 12px;
}

.tbodyText img {
  margin-right: 10px;
}
