@keyframes calloutSlideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes calloutSlideOut {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}


.assuranceCalloutContainer {
  position: fixed;
  top: 60px;
  bottom: 10px;
  right: 10px;
  width: 550px;
  border-radius: 8px;
  background: linear-gradient(180.52deg, rgba(11, 49, 67, 0.95) 0.84%, rgba(45, 55, 80, 0.95) 99.94%);
  box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(6px);
  overflow-y: scroll;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.assuranceCalloutContainer.slideIn {
  animation-name: calloutSlideIn; /* Enter animation */
}

.assuranceCalloutContainer.slideOut {
  animation-name: calloutSlideOut; /* Exit animation */
}


.assuranceCalloutContainer.show {
  right: 0px;
  z-index: 998;
  top: 60px;
}

/* Callout Header */

.headerTitle {
  display: flex;
  justify-content: end;
  padding: 10px 0 10px 7px;
  border-bottom: 1px solid rgba(217, 217, 217, 0.1);
}

.pointTitle {
  font-weight: 700;
  padding: 10px;
}

.pointTitle span {
  font-family: 'Museo Sans', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.15px;
  text-align: left;
  color: #ffffff;
}

/* Callout Body */

.callOutBody {
  padding: 16px 14px 12px 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.callOutContainer.compact .callOutBody {
  height: auto;
}

.calloutLoadingIconContainer {
  min-height: 75vh;
}

.callOutCloseBtn {
  background-color: transparent !important;
  color: #fff;
  border: 0;
  cursor: pointer;
  margin: 8px;
}

.callOutCloseBtn i {
  font-size: 14px;
  line-height: 1;
}

.callOutCloseBtn:hover {
  box-shadow: 1px 0 10px rgba(0, 0, 0, 0.1);
}

.sustainabilityCallOutBody {
  padding: 12px 14px 12px 14px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filterContainer {
  display: flex;
  gap: 10px;
  align-items: center;
}

.filterBlocks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.filterBlock {
  border-radius: 4px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.374) 0%,
      rgba(0, 0, 0, 0.68) 100%);
  padding: 10px 5px 10px 15px;
  line-height: 1;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  position: relative;
}

.filterBlock>span {
  color: #dddddd;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 14px;
  font-weight: 100;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px rgba(255, 255, 255, 0.3) solid;
}

.filterBlockDropdown {
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterBlockDropdown button {
  background-color: transparent;
  color: #fff;
  border: 0;
  outline: none;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
  font-weight: 100;
  display: flex;
  text-transform: uppercase;
  align-items: center;
  cursor: pointer;
  display: inline-block;
}

.filterBlockDropdown button i {
  font-size: 24px;
  color: #00a19c;
  margin-left: 8px;
  margin-right: -10px;
}

.filterBlockDropdown.show {
  height: auto;
  padding: 8px 0;
}

.mapRightPanelSelect {
  color: #00a19c;
}

/* Assurance Line tab */

.opuTab {
  padding: 0px 14px 0px 14px;
}

.opuTab ul {
  list-style: none;
  padding-left: 0;
  display: flex;
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  column-gap: 10px;
  margin-bottom: 5px;
}

.opuTab ul li {
  cursor: pointer;
  padding: 8px 10px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  transition: all ease-in-out 0.3s;
  font-weight: 500;
  position: relative;
}

.opuTab ul li::after {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -2px;
  content: '';
  width: 0;
  height: 4px;
  background-color: #00a19c;
  transition: all ease-in-out 0.3s;
}

.opuTab ul li:hover,
.opuTab ul li.active {
  color: rgba(255, 255, 255, 1);
}

.opuTab ul li:hover::after,
.opuTab ul li.active::after {
  width: 100%;
}

.compTab {
  padding: 0;
}

.compTab ul li {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.1666666716337204px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}


/* Callout Values */

.calloutValues {
  display: flex;
  gap: 14px;
}

.accordionContainer {
	background: rgba(255, 255, 255, 0.05);
	padding: 8px 8px 0px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	border-radius: 8px;
  overflow-y: auto;
}

.accordionContainer h2 {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.1944444477558136px;
	color: white;
	margin-bottom: 10px;
}

.assetBreakdownCalloutContainer h2 {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: 0.1944444477558136px;
	color: white;
	margin-bottom: 10px;
}

.assetSelect {
  position: absolute;
  z-index: 10;
  top: 5px;
  width: 100%;
  right: 0;
  opacity: 0;
}

.dropdownButton {
  width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Callout opened overlay */

@keyframes fadeIn {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@keyframes fadeOut {
  from {
    background-color: rgba(0, 0, 0, 0.5);
  }
  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 998;
  pointer-events: all;
  animation-duration: 0.3s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.overlay.fadeIn {
  animation-name: fadeIn;
}

.overlay.fadeOut {
  animation-name: fadeOut;
}

.tableStripe {
  background: #FFFFFF0D;
  border-style: solid;
  border-radius: 5px;
  border-radius: 10px;
  border-width: 3px;
}
