.mftLeft {
  width: 350px;
  min-width: 350px;
}

.mftLeft .mftSectionTitle {
  margin-bottom: 30px;
}

.mftLeft > p {
  color: #ffffff;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 26px;
  margin-bottom: 20px;
}

.mftLeftList {
  height: 400px;
  border-radius: 8px;
  padding: 20px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.209) 0%, #000000 100%);
  overflow: auto;
  border-bottom: solid 12px #020202;
  border-top: solid 12px #3a434b;
}

.mftLeft ::-webkit-scrollbar {
  width: 6px;
}

.mftLeftList ul li div {
  color: #fff;
  font-weight: 500;
}

.mftLeftList ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
.mftSectionTitle {
  color: #fff;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 42px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-weight: 500;
  margin-bottom: 20px;
}
.mftSectionTitle span {
  font-weight: 100;
}

.executiveSummaryList {
  list-style: none;
  padding-left: 0;
}

.executiveSummaryItemLevel2 {
  color: #b8b8b8;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 26px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.executiveSummaryItem {
  color: #b8b8b8;
  font-weight: 100;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 26px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.noResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding-top: 30%;
  color: white;
}

.noResult h2 {
  color: white;
  font-family: 'Museo Sans';
  margin-top: 10px;
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.194px;
  text-align: left;
}

@media (max-width: 1440px) {
  .mftContent {
    flex-wrap: wrap;
  }
  .mftLeft {
    width: 100%;
    margin-bottom: 20px;
  }
  .mftLeftList ul {
    margin-bottom: 0;
  }
  .mftLeftList ul li {
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
}

@media (max-width: 1100px) {
  .mftLeftList {
    padding: 15px;
  }
  .mftItem {
    padding: 15px 15px 0 15px;
  }
  .mftItemIcon {
    width: 60px;
    height: 60px;
  }
  .mftItemPlanTarget,
  .subValueNormal {
    padding-left: 75px;
  }
  .mftItemValue {
    font-size: 32px;
    line-height: 36px;
  }
  .mftItemValue span {
    margin-bottom: 3px;
  }
  .mftItemTitle {
    font-size: 14px;
  }
  .mftRightTab ul li {
    font-size: 14px;
  }
  .mftLeftList ul li {
    font-size: 12px;
  }
  /* .mftLeft .mftSectionTitle,
  .mftSectionTitle {
    margin-bottom: 10px;
  } */
  .mftItem .mftItemMore .mftItemMoreLeft b {
    font-size: 22px;
  }
  .mftItem .mftItemMore .mftItemMoreRight {
    font-size: 12px;
    line-height: 1.3;
  }
}
