/* CHART */

.chartTilte {
  opacity: 0.8;
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 16px;
  margin-bottom: 10.5px;
}

.summaryChartBackground {
  box-sizing: border-box;
  max-height: 111px;
  width: 100%;
  border-radius: 8px;
  border: 2px solid rgba(70, 70, 70, 0.4);
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.38) 100%);
  padding: 15.5px 9.5px 21.5px 21.5px;
  overflow: auto;
}

.summaryChartTitle,
.waterfallChartTitle {
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}

.summaryChartText,
.waterfallChartText {
  color: #b8b8b8;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
  padding-top: 7px;
}

.revenueChartDetail {
  margin-top: 10.5px;
  box-sizing: border-box;
  height: 305px;
  width: 100%;
  /* border: 2px solid #29292990; */
  border-radius: 8px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
}

.waterfallChart {
  box-sizing: border-box;
  height: 334px;
  width: 100%;
  min-width: fit-content;
  /* border: 2px solid #29292990; */
  border-radius: 8px;
  padding: 15.5px 9.5px 21.5px 21.5px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
}

.modalWaterfallChart {
  box-sizing: border-box;
  height: auto;
  width: 100%;
  min-width: fit-content;
  /* border: 2px solid #29292990; */
  border-radius: 8px;
  padding: 15.5px 9.5px 21.5px 21.5px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
}

.cardThirdValueItem {
  box-sizing: border-box;
  height: 111px;
  min-width: 111px;
  width: fit-content;
  border-radius: 8px;
  padding: 10.5px 19px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
  white-space: nowrap;
  cursor: pointer;
  border: 2px solid rgba(70, 70, 70, 0.4);
}

.cardThirdValueActiveItem {
  box-sizing: border-box;
  height: 81px;
  min-width: 111px;
  width: fit-content;
  border-radius: 4px;
  padding: 10.5px 19px;
  background: linear-gradient(180deg,
      rgba(0, 207, 203, 0.2) 0%,
      rgba(0, 207, 203, 0.2) 50%,
      rgba(0, 207, 203, 0.3) 100%);
  cursor: pointer;
  border: 1.5px solid rgba(255, 255, 255, 1);
}

.cardThirdValueLevel3Item {
  box-sizing: border-box;
  height: 81px;
  min-width: 111px;
  width: fit-content;
  border-radius: 4px;
  padding: 10.5px 19px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
  white-space: nowrap;
  cursor: pointer;
  border: 2px solid rgba(70, 70, 70, 0.4);
}

.cardThirdValueList {
  display: flex;
  gap: 20px;
  width: fit-content;
}

.cardThirdValueItemTitle {
  height: 14px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 0.35px;
  line-height: 14px;
  margin-bottom: 3px;
}

.cardThirdValueItemValue {
  height: 29px;
  color: #00a19c;
  font-family: "Museo Sans", sans-serif;
  font-size: 24px;
  line-height: 29px;
  margin-top: 3px;
}

.inforChart {
  display: flex;
  gap: 10.5px;
}

.chartLayout {
  display: flex;
  gap: 10.5px;
}

.secondaryValueList {
  box-sizing: border-box;
  height: 111px;
  width: -webkit-fill-available;
  border: 2px solid rgba(70, 70, 70, 0.4);
  border-radius: 8px;
  display: flex;
  gap: 20px;
  padding: 19px 18px 9px 26.5px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.38) 100%);
  justify-content: center;
  align-items: center;
}

.secondaryValueItemTitle {
  width: fit-content;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0.47px;
  line-height: 19px;
  margin-bottom: 4px;
  text-align: left;
}

.secondaryValueItemUnit,
.cardThirdValueItemUnit {
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 11px;
  letter-spacing: 0.33px;
  line-height: 14px;
  text-align: left;
  white-space: nowrap;
}

.secondaryValueItemValue {
  color: #847fe0;
  font-family: "Museo Sans", sans-serif;
  font-size: 28px;
  /* letter-spacing: 0.82px; */
  font-weight: 500;
  line-height: 33px;
  text-align: left;
}

.secondaryValueLayout {
  display: flex;
  gap: 2px;
}

.secondaryDeltaValue {
  color: #ffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 28px;
  letter-spacing: 0.82px;
  font-weight: 500;
  line-height: 33px;
  margin-left: 8px;
}

.secondaryDeltaValueUnit {
  height: 22px;
  width: 15px;
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 18px;
  letter-spacing: 0.53px;
  line-height: 22px;
}

.secondaryDeltaValueArrow {
  color: #ffff;
  position: relative;
}

.revenueChartOverview {
  width: 100%;
}

.waterfallChartOverview {
  width: 100%;
}

.trendChartContainer {
  margin-top: 10.5px;
  box-sizing: border-box;
  height: 331px;
  width: 100%;
  /* border: 2px solid #29292990; */
  border: 2px solid rgba(70, 70, 70, 0.4);
  border-radius: 8px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
}

.financialTrendChartContainer {
  box-sizing: border-box;
  height: 300px;
  width: 100%;
}

.trendChartContainerlevel3 {
  box-sizing: border-box;
  height: 305px;
  width: 100%;
  /* border: 2px solid #29292990; */
  border: 2px solid rgba(70, 70, 70, 0.4);
  border-radius: 8px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
}

.topLeftTrendDiv {
  display: flex;
  min-width: 50%;
}

.topLeftTrendDiv a {
  height: 15px;
  width: 84px;
  color: #00a19c;
  font-family: "Museo Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 15px;
  margin-left: 7px;
  margin-top: 22.5px;
}

.topLeftTrendDiv div:first-child {
  height: 26px;
  width: 65px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 20.5px;
  margin-top: 16.5px;
}

.topLeftTrendDiv img {
  margin-left: 11px;
  margin-top: 16.5px;
}

.topRightTrendDiv {
  display: flex;
}

.topRightTrendDiv span {
  margin-top: 21.5px;
  margin-left: 8px;
  margin-right: 19.5px;
  height: 14px;
  opacity: 0.8;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: -0.17px;
  line-height: 14px;
}

.topRightTrendDiv div {
  /* margin-top: 21.5px;
  height: 13px;
  width: 13px;
  border-radius: 2px; */
}

.waterFallChartContainer {
  margin-bottom: 10.5px;
  box-sizing: border-box;
  height: 334px;
  width: 100%;
  min-width: fit-content;
  border: 2px solid #29292990;
  border-radius: 8px;
  padding: 15.5px 9.5px 21.5px 21.5px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
}

.modalWaterFallChartContainer {
  margin-bottom: 10.5px;
  box-sizing: border-box;
  /* height: 500px; */
  width: 100%;
  min-width: fit-content;
  border: 2px solid #29292990;
  border-radius: 8px;
  padding: 15.5px 9.5px 21.5px 21.5px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
}

.topLeftWaterfallDiv span {
  height: 15px;
  width: 100px;
  color: #00a19c;
  font-family: "Museo Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 15px;
  margin-left: 7px;
  margin-top: 10px;
  cursor: pointer;
}

.topRightWaterfallDiv {
  display: flex;
}

.topRightWaterfallDiv span {
  margin-top: 10.5px;
  margin-right: 30.5px;
  height: 14px;
  width: auto;
  opacity: 0.8;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  letter-spacing: -0.17px;
  line-height: 14px;
}

/* modal breakdown */
.miniCardActiveModal {
  box-sizing: border-box;
  height: 100%;
  /* min-width: 140px; */
  width: fit-content;
  border-radius: 4px;
  background: linear-gradient(180deg,
      rgba(0, 207, 203, 0.2) 0%,
      rgba(0, 207, 203, 0.2) 50%,
      rgba(0, 207, 203, 0.3) 100%);
  border: 1.5px solid rgba(255, 255, 255, 1);
  padding: 19px 15px 15px 19px;
  cursor: pointer;
}

.miniCardModal {
  box-sizing: border-box;
  height: 100%;
  /* min-width: 140px; */
  width: fit-content;
  border: 2px solid rgba(70, 70, 70, 0.4);
  border-radius: 8px;
  background: linear-gradient(180deg,
      rgba(6, 25, 25, 0.209) 0%,
      rgba(0, 0, 0, 0.55) 100%);
  padding: 19px 15px 15px 19px;
}

.leftTrendChartDiv div:first-child {
  height: 26px;
  width: 65px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
  margin-left: 20.5px;
  margin-top: 16.5px;
}

.leftTrendChartDiv img {
  margin-left: 11px;
  margin-top: 16.5px;
}

.leftTrendChartDiv {
  display: flex;
}

.leftTrendChartDiv div:last-child {
  height: 15px;
  width: 84px;
  color: #00a19c;
  font-family: "Museo Sans", sans-serif;
  font-size: 13px;
  letter-spacing: 0.35px;
  line-height: 15px;
  margin-left: 7px;
  margin-top: 22.5px;
}

.trendChart {
  width: 100%;
  height: 100%;
  margin-left: -15px;
}

.topTrendChartDiv {
  display: flex;
  justify-content: space-between;
}

.topWaterfallChartDiv {
  display: flex;
  justify-content: space-between;
}

.topLeftWaterfallDiv {
  display: flex;
}

.topLeftWaterfallDiv div {
  margin-top: 5px;
  margin-left: 22.5px;
  height: 26px;
  width: 115px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}

.boxlegendRed {
  margin-top: 10.5px;
  margin-right: 8px;
  height: 13px;
  width: 13px;
  opacity: 0.7;
  border-radius: 2px;
  background-color: #e75552;
}

.boxlegendGreen {
  margin-top: 10.5px;
  margin-right: 8px;
  height: 13px;
  width: 13px;
  border-radius: 2px;
  background-color: #00a19c;
}

.boxlegendBlue {
  margin-top: 10.5px;
  margin-right: 8px;
  height: 13px;
  width: 13px;
  border-radius: 2px;
  background-color: #009ddc;
}

.subTopWaterfall {
  margin-top: 10px;
  margin-left: 22.5px;
  height: 53px;
  width: 307px;
  color: #b8b8b8;
  font-family: "Museo Sans", sans-serif;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

/* modal breakdown */

.headerModal {
  height: 42px;
  width: 100%;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 42px;
  text-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
  margin-bottom: 14px;
}

.listMiniCardModal {
  display: flex;
  gap: 14px;
  height: 105px;
}

.miniTitleCardActiveModal {
  height: 19px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0.47px;
  line-height: 19px;
  white-space: nowrap;
  margin-bottom: 4px;
  /* margin-right: 15px; */
}

.miniUnitCardActiveModal {
  height: 14px;
  width: 27px;
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
}

.miniValueCardActiveModal {
  height: 33px;
  width: 77px;
  color: #00a19c;
  font-family: "Museo Sans", sans-serif;
  font-size: 28px;
  letter-spacing: 0.82px;
  line-height: 33px;
  white-space: nowrap;
}

.listButtonBreakdownModal {
  display: flex;
  gap: 10px;
  box-sizing: border-box;
  height: 100%;
  padding: 10px 20px;
  width: 100%;
  border: 1px solid rgba(70, 70, 70, 0.5);
  opacity: 0.8;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
}

.buttonBreakdownCard {
  /* border: 1px solid rgba(70, 70, 70, 0.5); */
  border-radius: 4px;
}

.mainLayoutModal {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  width: 100%;
}

.mainLayoutModalProduc {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.backgroundModal ::-webkit-scrollbar-thumb {
  width: 2px;
  opacity: 0.5;
  border-radius: 10px;
  background-color: rgba(0, 161, 156, 0.6);
  -webkit-box-shadow: unset;
}

.backgroundModal ::-webkit-scrollbar {
  width: 6px;
}

.sumaryChartBackgroundModalWithPlant {
  box-sizing: border-box;
  min-width: 204px;
  width: none;
  height: 186px;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
  padding: 15.5px 9.5px 21.5px 21.5px;
  margin-bottom: 10px;
  overflow-y: scroll;
  border: 1px solid rgba(70, 70, 70, 0.5);
}

.sumaryChartBackgroundModalWithFinancial {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  /* display: inline-block; */
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
  padding: 15.5px 9.5px 21.5px 21.5px;
  overflow: auto;
  margin-bottom: 10px;
  border: 1px solid rgba(70, 70, 70, 0.5);
}

.sumaryChartBackgroundModalWithProduction {
  box-sizing: border-box;
  height: auto;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
  padding: 15.5px 9.5px 21.5px 21.5px;
  overflow: auto;
  border: 1px solid rgba(70, 70, 70, 0.5);
}

.sumaryChartTitle {
  height: 26px;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 26px;
}

.sumaryChartText {
  height: 137px;
  width: 172px;
  color: #b8b8b8;
  font-family: "Museo Sans", sans-serif;
  font-size: 14px;
  letter-spacing: -0.2px;
  line-height: 20px;
}

.primaryCardBackground {
  width: -webkit-fill-available;
  min-width: 204px;
  display: flex;
  gap: 14px;
  border-radius: 8px;
  border: 1px solid rgba(70, 70, 70, 0.5);
  justify-content: center;
  /* padding: 19.5px 24px; */
  padding: 19.5px 19.5px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
}

.chartModal {
  box-sizing: border-box;
  min-height: 305px;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
}

.waterfallChartModal {
  width: 100%;
  border: 1px solid rgba(70, 70, 70, 0.5);
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(6, 25, 25, 0.7) 0%, #000000 100%);
}

.backgroundModal>div {
  background-color: unset !important;
}

.backgroundModal button {
  color: #ffff !important;
}

.backgroundModal button+div {
  border-radius: 8px;
}

.subTrendChartDiv {
  margin-left: 10px;
  height: 12px;
  width: 31px;
  opacity: 0.6;
  color: #ffffff;
  font-family: "Museo Sans", sans-serif;
  font-size: 10px;
  letter-spacing: -0.14px;
  line-height: 12px;
  white-space: nowrap;
}

.chartNotAvailable {
  color: #ffffff;
  opacity: 0.4;
  background-image: linear-gradient(to bottom, #fff, transparent 1px),
    linear-gradient(#ccc 0px, transparent 0px);
  background-size: 20px 47px;
  height: 216px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.noWaterfallLayout {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.noWaterfallLayout>div {
  margin-bottom: 10px;
}

.mainLayoutModalPlant>div:first-child {
  height: 100%;
}

.legendarea {
  margin-top: 10px;
  height: 12px;
  width: 12px;
  border-radius: 2px;
  border: 6px solid;
}

.legenddash {
  margin-top: 25px;
  box-sizing: border-box;
  height: 1px;
  width: 21px;
  border-bottom: 3px dotted;
}

.legenddot {
  margin-top: 25px;
  box-sizing: border-box;
  height: 1px;
  width: 21px;
  border-bottom: 3px dotted;
}

.legendline {
  margin-top: 25px;
  box-sizing: border-box;
  height: 4px;
  width: 14px;
  border: 1px solid;
}

.legendbar {
  border-radius: 2px;
  margin-top: 22px;
  box-sizing: border-box;
  height: 10px;
  width: 10px;
}