.financialRatiosContainer {
    display: flex;
    width: 100%;
  }
  
  .sidebar {
    position: sticky;
    top: 10px;
    height: fit-content;
    width: 220px;
    background-color: #1e2d3d;
    padding: 16px;
    border-radius: 8px;
    gap: 10px;
  }
  
  .sidebarTitle {
    font-size: 18px;
    color: #ffffff;
    margin-bottom: 10px;
    font-weight: bold;
    font-family: 'Museo Sans';
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.25px;
    text-align: left;

  }
  
  .sidebarItem {
    padding: 8px;
    color: #b0b8c1;
    cursor: pointer;
    transition: color 0.3s ease;
    font-family: 'Museo Sans';
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.19px;
  }
  
  .sidebarItem.active,
  .sidebarItem:hover {
    color: #00D1CA;
    background: #FFFFFF1A;
    border-radius: 8px;
  }
  
  .contentArea {
    margin-left: 20px;
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
    flex-grow: 1;
  }
  
  .ratioBox {
    background: #FFFFFF0D;
    width: 100%;
    margin-bottom: 30px;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 16px;
  }

  @media (max-width: 756px) {
  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    gap: 16px;
  }
}
  
  .cardWrapper {
    margin: 5px;
  }

  .ratioTitle {
    font-family: 'Museo Sans';
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.25px;
    text-align: left;
    color: white;
    margin-bottom: 10px;
  }